import React, { useState, useMemo, useContext } from 'react';
import './AnimatedCaptions.less';
import { UserContext } from '../../../data/userContext';
import { useNavigate } from 'react-router';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import { getEnabledAnimatedCaptionsForUser } from '../../../utils';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { IClip, IClipEdit } from '../../../data/intefaces/stream.interface';

export interface IAnimatedCaptions {
  clip: IClip & Partial<IClipEdit>;
  updateFunction: (animationName: string) => void;
}

const handleHoverEnter = (animation: string) => {
  // Add the "slow" class when hovering over the element
  const element = document.getElementById(animation);
  if (element) {
    element.classList.add(animation);
  }
};
const handleHoverLeave = (animation: string) => {
  // Remove the "slow" class when leaving the element
  const element = document.getElementById(animation);
  if (element) {
    element.classList.remove(animation);
  }
};

const AnimatedCaptions = ({ updateFunction, clip }: IAnimatedCaptions) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);

  const boxData = [

    {
      animationName: 'wword',
      isActive: true,
    },
    {
      animationName: 'karaoke',
      isActive: true,
    },
    {
      animationName: 'bounce',
      isActive: true,
    },
    {
      animationName: 'blossom',
      isActive: true,
    },
    {
      animationName: 'sunshine',
      isActive: true,
    },
    {
      animationName: 'emerald',
      isActive: true,
    },
    {
      animationName: 'slide-left',
      isActive: true,
    },
    {
      animationName: 'bubble',
      isActive: false,
    },
    {
      animationName: 'color-wave',
      isActive: false,
    },

    {
      animationName: 'underline',
      isActive: false,
    },
    {
      animationName: 'shake',
      isActive: false,
    },
    {
      animationName: 'rotate',
      isActive: false,
    },



  ];

  const [activeBox, setActiveBox] = useState<string | null>(clip?.captions_animation_type || 'none');
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const getAnimatedCaptionsForUser = (user: IStreamer | null | undefined): string[] => {
    if (user === null || user === undefined) {
      return [];
    }

    if (!animatedCaptionsTabEnabledForUser) return [];

    if (user?.permissions) {
      return user?.permissions.animated_captions;
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return ['wword', 'karaoke', 'bounce'];
    }

    return [];
  };

  const getAllowedAnimatedCaptions = (user: IStreamer | null | undefined): boolean => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return user?.permissions.animated_captions[0] === 'ALL';
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return true;
    }

    return false;

  };

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const animatedCaptionsTabEnabledForUser = getEnabledAnimatedCaptionsForUser(userContext?.user);

  const allowedCaptionsList = getAnimatedCaptionsForUser(userContext?.user); // userContext?.user?.permissions.animated_captions;
  const allowAllAnimations = getAllowedAnimatedCaptions(userContext?.user);  //userContext?.user?.permissions.animated_captions[0] === 'ALL';

  const handleClick = (animationName: string) => {
    if (animationName === 'none') {
      updateFunction('none');
      setActiveBox('none');
      return;
    }
    if (!allowAllAnimations && allowedCaptionsList?.indexOf(animationName) === -1 && (boxData.find((item) => item.animationName === animationName)?.isActive)) {
      handleOpenPricingModal();
      return;
    }
    if (boxData.find((item) => item.animationName === animationName)?.isActive) {
      updateFunction(animationName);
      setActiveBox(animationName); // Set the clicked box as active
    }
  };

  const styles = useMemo(() => {
    return {
      boxContainer: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center',
        padding: '20px',
        height: '100%',
        background: '#272836',
        overflow: 'auto'
      },
      box: {
        background: '#2C2C2F',
        color: 'var(--White, #FFF)',
        textAlign: 'center' as const,
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        maxHeight: '98px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        border: '3px solid transparent',
        position: 'relative' as const,
      },
      focusedBox: {
        border: '3px solid rgba(255, 185, 248, 0.50)',
      },
      smallText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'var(--White, #67FFCC)',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
      inactiveBox: {
        opacity: 0.5, // Make inactive boxes grayed out
        cursor: 'not-allowed', // Make inactive boxes not clickable
      },
      comingSoonText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'orange',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
    };
  }, []);


  return (
    <div style={styles.boxContainer}>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

      <div className="editor-grid">
        <div
          className='editor-grid__item'
          style={{
            ...styles.box,
            ...(activeBox === 'none' ? styles.focusedBox : {}),
          }}
          onMouseEnter={() => handleHoverEnter('none')}
          onMouseLeave={() => handleHoverLeave('none')}
          onClick={() => handleClick('none')}
        >
          <div>
            <span style={{ height: 'auto' }}>None</span>
          </div>
        </div>
        {boxData.map((box, index) => (
          <div
            key={index}
            className='editor-grid__item'
            style={{
              ...styles.box,
              ...(activeBox === box.animationName ? styles.focusedBox : {}),
              ...(box.isActive === false ? styles.inactiveBox : {}), // Apply inactive style if isActive is false
            }}
            onMouseEnter={() => handleHoverEnter(box.animationName)}
            onMouseLeave={() => handleHoverLeave(box.animationName)}
            onClick={() => handleClick(box.animationName)}
          >
            <div
              id={box.animationName} // Unique id for each element
            >
              <span style={{ height: 'auto' }}>{box.animationName}</span>
            </div>
            {box.isActive === false && (
              <div style={styles.comingSoonText}>Coming Soon</div>
            )}
            {box.isActive === true && !allowAllAnimations && allowedCaptionsList?.indexOf(box.animationName) === -1 && (
              <div style={{ ...styles.comingSoonText, color: '#ababc0' }}>Pro feature</div>
            )}
            {activeBox === box.animationName && (
              <div style={styles.smallText}>Animated</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedCaptions;
