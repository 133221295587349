import { Modal, Button, Typography } from 'antd';
import { useMemo } from 'react';
import { COLORS } from '../../themes/colors';

export interface IBlockedEditorModalProps {
  open: boolean;
  onClose: () => void;
}

export const BlockedEditorModal = ({ open, onClose }: IBlockedEditorModalProps) => {
  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 10,
        overflow: 'hidden',
      },
      modalBody: {
        background: COLORS.MODAL_GRAY_BACKGROUND,
        padding: '40px',
      },
      modalTitle: {
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: 8,
        textAlign: 'center',
        color: COLORS.PRIMARY
      },
      contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 42
      },
      title: {
        color: COLORS.GRAY_DISABLED,
        fontWeight: 600,
        fontSize: 28,
        textAlign: 'center'
      },
      textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 45
      },
      text: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center'
      }
    } as const;
  }, []);

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={600}
      centered
    >
      <div style={styles.contentWrapper}>
        <Typography style={styles.modalTitle}>Important 💡</Typography>
        <div style={styles.textWrapper}>
          <Typography style={styles.text}>Sorry, but our editor is not compatible with mobile devices at the moment.</Typography>
          <Typography style={{ ...styles.text, color: COLORS.YELLOW }}>For the best editing experience, please use a desktop or tablet.</Typography>
        </div>
        <Button style={{ width: 160 }} onClick={onClose} type="primary" className='button'>Got it</Button>

      </div>
    </Modal >
  );
};

export default BlockedEditorModal;