import React, { useContext, useEffect, useState } from 'react';
import './BigSidebar.less';
// import { ReactComponent as IconAiClipGenerator } from '../../assets/ai_clip_generator.svg';
import { ReactComponent as IconPlay } from '../../assets/play_simple.svg';
import { ReactComponent as IconMagicWand } from '../../assets/magic_wand_icon.svg';
import { ReactComponent as IconAddCaptions } from '../../assets/add_captions.svg';
import { ReactComponent as IconSettings } from '../../assets/settings.svg';
import { ReactComponent as IconDashboard } from '../../assets/dashboard_new.svg';
import { ReactComponent as IconLoyalty } from '../../assets/loyalty.svg';
import { ReactComponent as IconAddPeople } from '../../assets/add_people.svg';
import { ReactComponent as IconLogo } from '../../assets/logo_sidebar.svg';
import { ReactComponent as IconCrown } from '../../assets/crown_new.svg';
import { ReactComponent as IconTextToVideo } from '../../assets/text_to_video.svg';
import { ReactComponent as IconShare } from '../../assets/ios_share.svg';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/avatar_circle.svg';
import { ReactComponent as IconLogout } from '../../assets/logout.svg';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';
import { useNavigate } from 'react-router';
import { showGoProButton } from '../../utils';
import { UserContext } from '../../data/userContext';
import { SettingsModal } from '../settings-modal';
import { GOOGLE_USER } from '../../constants/user-constants';
import { Tooltip } from 'antd';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import useAxios from 'axios-hooks';
import { IClip } from '../../data/intefaces/stream.interface';

const navigationItems = [
  {
    label: 'AI Clip Generator',
    icon: <IconPlay style={{ width: 15, height: 15, paddingLeft: 3 }} />,
    href: '/spikes/videos',
    social: false,
    disabled: false
  },
  {
    label: 'Live Streams AI',
    icon: <IconMagicWand />,
    href: '/spikes/streams',
    onlyTwitchUser: true
  },
  {
    label: 'Add Captions',
    icon: <IconAddCaptions />,
    href: '/spikes/transcribe'
  },
  // {
  //   label: 'Social Analytics',
  //   icon: <IconShare style={{ height: 17, width: 17 }} />,
  //   href: '/spikes/social',
  //   social: true
  // },
  // {
  //   label: 'Text to Video',
  //   icon: <IconTextToVideo />,
  //   href: '/spikes/text-to-video',
  //   disabled: true
  // },
];

const POLLING_INTERVAL = 5000;

const BigSidebar = ({ isMobile = false }: { isMobile?: boolean }) => {
  const [postsResponse, postsRefetch] = useAxios('/streamer/social/clips');
  const { height } = useWindowDimensions();
  const isSmallHeightScreen = height < 940;

  const postsList: IClip[] = postsResponse?.data;

  const [isOpen, setIsOpen] = useState(isMobile ? true : true);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const subscriptions = userContext?.user?.subscriptions;
  const isGoogleUser = !userContext?.user?.twitch_id;
  const navigate = useNavigate();

  useEffect(() => {
    postsRefetch();
  }, []);

  useEffect(() => {
    const timer = setInterval(postsRefetch, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (postsList && postsList.length > 0) {
      const alreadyInNav = Boolean(navigationItems.find(item => item.social === true));

      if (!alreadyInNav) {
        navigationItems.push({
          label: 'Social Analytics',
          icon: <IconShare style={{ height: 17, width: 17 }} />,
          href: '/spikes/social',
          social: true,
          disabled: false
        });
      }
    }
  }, [postsList])



  const handleOpenSidebar = () => {
    setIsOpen(true);
  };

  const handleCloseSidebar = () => {
    !isMobile && setIsOpen(false);
  };

  const handleClickOnItem = (href?: string) => {
    if (href) {
      navigate(href);
    }
  };

  const checkCurrentItem = (href?: string) => {
    if (href) {
      return location.pathname.includes(href);
    }
    return false;
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
    handleCloseSidebar();
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
    handleCloseSidebar();
  };

  const handleInviteClick = () => {
    navigate('/affiliate-program');
    handleCloseSidebar();
  };

  const handleTutorialsClick = () => {
    navigate('/tutorial');
    handleCloseSidebar();
  };

  const handleLoyaltyClick = () => {
    navigate('/spikes/loyalty-program');
    handleCloseSidebar();
  };

  const handleApiClick = () => {
    navigate('/api');
    handleCloseSidebar();
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = '/';
    handleCloseSidebar();
  };

  const handleGoPro = () => {
    navigate('/subscriptions');
  };

  const actionItems = [
    {
      label: 'Tutorials',
      icon: <IconInfoCircle />,
      onClick: handleTutorialsClick
    },
    {
      label: 'Dashboard',
      icon: null,
      onClick: handleDashboardClick
    },
    {
      label: 'API',
      icon: <span style={{ position: 'unset' }} className='beta-tag'>New</span>,
      onClick: handleApiClick
    },
    {
      label: 'Loyalty Program',
      icon: null,
      onClick: handleLoyaltyClick
    },
    {
      label: 'Invite a Friend',
      icon: null,
      onClick: handleInviteClick
    },
    {
      label: 'Settings',
      icon: null,
      onClick: handleSettingsClick
    },
  ];

  if (isMobile) actionItems.unshift(
    {
      label: 'Settings',
      icon: null,
      onClick: handleSettingsClick
    },
  )

  return (
    <div className={`big-sidebar ${isSmallHeightScreen && 'small-height'}`}>
      <div className="big-sidebar__logo-container">
        <IconLogo className="big-sidebar__logo" onClick={() => navigate('/')} />
        {subscriptions?.length != 0 && subscriptions != null ? <span className='pro-label'>PRO</span> : <></>}
      </div>
      <div className='big-sidebar__top-and-bottom'>
        <div className="big-sidebar__top-buttons">
          {navigationItems.map(item => (item?.onlyTwitchUser ? !isGoogleUser : true) && (
            <>
              {item.disabled ?
                <Tooltip placement='right' title='Coming soon...'>
                  <div key={item.label} className={`big-sidebar__item ${item.disabled && 'disabled'} ${isOpen ? 'opened' : 'closed'} ${checkCurrentItem(item?.href) ? 'active' : ''}`}>
                    {item?.icon && item?.icon}
                    <span>{item.label}</span>
                  </div>
                </Tooltip>
                :
                <div onClick={() => handleClickOnItem(item?.href)} key={item.label} className={`big-sidebar__item ${isOpen ? 'opened' : 'closed'} ${checkCurrentItem(item?.href) ? 'active' : ''}`}>
                  {item?.icon && item?.icon}
                  <span>{item.label}</span>
                </div>
              }
            </>
          ))}
          {showGoProButton(userContext?.user) && (
            <div onClick={handleGoPro} className='big-sidebar__go-pro yellow-gradient-radial'>
              <IconCrown />
              <span>Go Pro</span>
            </div>
          )}
        </div>

        <div className="big-sidebar__bottom-buttons">
          {actionItems.map(item => (
            <div onClick={item?.onClick} key={item.label} className="big-sidebar__bottom-btn">
              <span>{item.label}</span>
              {item.icon}
            </div>
          ))}
        </div>
      </div>

      {showSettingsModal && (
        <SettingsModal onClose={() => setShowSettingsModal(false)} />
      )}
    </div>
  )
}

export default BigSidebar;