import { useContext, useMemo } from 'react';
import { Layout, Typography, Row, Col, Image, Space, Button } from 'antd';
('@ant-design/icons');
import { COLORS } from '../../themes/colors';
import Logo from '../../assets/spikes-logo.svg';
import IconTwitter from '../../assets/icon-footer-twitter.svg';
import IconInstagram from '../../assets/icon-footer-instagram.svg';
import IconYouTube from '../../assets/icon-footer-youtube.svg';
import IconDiscord from '../../assets/icon-footer-discord.svg';
import IconTikTok from '../../assets/icon-footer-tiktok.svg';
import FooterAsset from '../../assets/footer-asset.svg';

import './FooterResponsive.less';
import { UserContext } from '../../data/userContext';
import handleTwitchLogin from '../../utils/twitchAuth';
import { isMobile } from 'react-device-detect';
import ProducthuntButton from '../product-hunt-button/ProducthuntButton';
import { DISCORD_LINK } from '../../constants/content-constants';
const { Footer } = Layout;
const { Text } = Typography;

const FooterResponsive = () => {
  const userContext = useContext(UserContext);
  const showFooter = location.pathname === '/' || location.pathname === '/subscriptions';

  const styles = useMemo(() => {
    return {
      container: {
        textAlign: 'center',
        background: COLORS.FOOTER_BACKGROUND,
        position: 'relative'
      } as React.CSSProperties,
      communitySection: {
        background: '#3A3A3A',
        borderRadius: '8px',
        // height: 350,
        width: 350,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      } as React.CSSProperties,
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      } as React.CSSProperties,
      title: {
        fontSize: 24,
        fontWeight: 300,
        marginBottom: 20,
        lineHeight: '42px',
        color: COLORS.PRIMARY
      } as React.CSSProperties,
      link: {
        color: '#fff',
        marginBottom: '8px',
      } as React.CSSProperties,
      socialIcons: {
        fontSize: '20px',
        margin: '0 8px',
        color: '#fff',
      } as React.CSSProperties,
      copyright: {
        color: '#666',
        fontSize: 16,
        fontWeight: 400
      } as React.CSSProperties,
      spikesLogoCotainer: {
        textAlign: 'left',
        maxHeight: '35px',
        display: 'block',
        width: 200
      } as React.CSSProperties,
      linksList: {
        listStyle: 'none',
        padding: '0 !important',
        margin: '0 !important',
        display: 'flex',
        flexDirection: 'column',
      } as React.CSSProperties,
      communityIconsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 30,
        justifyContent: 'center'
      } as React.CSSProperties,
      footerAsset: {
        background: '#1B1B1B'
      } as React.CSSProperties
    };
  }, []);

  if (!showFooter) return <></>;

  return (
    <>
      <div style={{ display: 'flex' }}>
        {!isMobile && <Image
          src={FooterAsset}
          preview={false}
          wrapperStyle={styles.footerAsset}
        />}

        <Image
          src={FooterAsset}
          preview={false}
          wrapperStyle={styles.footerAsset}
        />
      </div>
      <Footer style={styles.container} className="footer-container">
        {isMobile
          ?
          <div className="footer__content mobile">
            <div style={styles.spikesLogoCotainer}>
              <a href="/home">
                <img src={Logo} alt="" />
              </a>
            </div>

            <Typography className="footer__mobile-label">Generative Ai video tool that repurposes long videos shorts in just one click</Typography>
            <div style={styles.column}>
              <Typography className="community-section__title" style={styles.title}>
                Join Our Community
              </Typography>
              <div className="footer__icons-container">
                <SocialIconItem icon={IconDiscord} link={DISCORD_LINK} />
                <SocialIconItem icon={IconYouTube} link='https://www.youtube.com/@SpikesStudio' />
                <SocialIconItem icon={IconInstagram} link='https://www.instagram.com/spikes_studio' />
                <SocialIconItem icon={IconTikTok} link='https://www.tiktok.com/@spikesstudio' />
                <SocialIconItem icon={IconTwitter} link='https://twitter.com/Spikes_St' />
              </div>
            </div>
            <ProducthuntButton postId={411007} />
          </div>
          :

          <div className="footer__content">
            <div className="footer__logo" style={styles.column}>
              <div style={styles.spikesLogoCotainer}>
                <a href="/home">
                  <img src={Logo} alt="" />
                </a>
                <div style={{ marginTop: 20 }}><ProducthuntButton postId={411007} /></div>
              </div>
            </div>
            <div style={styles.column}>
              <div>
                <ul className="footer__links-list" style={styles.linksList}>
                  <li>
                    <a href="/about" style={styles.link}>
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://blog.spikes.studio/"
                      style={styles.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.notion.so/Earn-money-as-a-Spikes-Studio-Affiliate-Partner-d252a0795f1f484d8338eabe387953f9"
                      style={styles.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Become an Affiliate
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div style={styles.column}>
              <div>
                <ul className="footer__links-list" style={styles.linksList}>
                  <li>
                    <a href="/terms" style={styles.link}>
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="/privacy" style={styles.link}>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intercom.help/spikes-studio/en/"
                      style={styles.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Help Center
                    </a>
                  </li>
                  {!userContext?.user &&
                    <li>
                      <Button onClick={handleTwitchLogin} style={{ height: 76, width: 192, fontSize: 28, margin: '0 auto' }} type="primary" className="button">START NOW</Button>
                    </li>
                  }

                </ul>
              </div>
            </div>
            <div style={styles.column}>
              <div className="community-section" style={styles.communitySection}>
                <Typography className="community-section__title" style={styles.title}>
                  Join Our Community
                </Typography>
                <div style={styles.communityIconsContainer}>
                  <SocialIconItem icon={IconDiscord} link={DISCORD_LINK} />
                  <SocialIconItem icon={IconYouTube} link='https://www.youtube.com/@SpikesStudio' />
                  <SocialIconItem icon={IconInstagram} link='https://www.instagram.com/spikes_studio' />
                  <SocialIconItem icon={IconTikTok} link='https://www.tiktok.com/@spikesstudio' />
                  <SocialIconItem icon={IconTwitter} link='https://twitter.com/Spikes_St' />
                </div>
              </div>
            </div>
          </div>
        }

        <div style={styles.copyright}>
          &copy; {new Date().getFullYear()} Spikes.Studio All rights reserved.
        </div>
      </Footer>
    </>
  );
};

const SocialIconItem = ({ icon, link }: { icon: string, link: string }) => {
  const styles = useMemo(() => {
    return {
      iconContainer: {
        backgroundColor: COLORS.MODAL_GRAY_BACKGROUND,
        padding: 10,
        height: 60,
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
      }
    } as const;
  }, []);
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="icon-container" style={styles.iconContainer}>
        <Image
          src={icon}
          height={28}
          preview={false}
          className="cursor-pointer"
        />
      </div>
    </a>
  );
};

export default FooterResponsive;
