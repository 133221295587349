import React from 'react';

interface ProducthuntButtonProps {
  postId: number;
  theme?: string;
  period?: string;
}

const ProducthuntButton: React.FC<ProducthuntButtonProps> = ({
  postId,
  theme = 'light',
  period = 'weekly',
}) => {
  return (
    <a
      href="https://www.producthunt.com/posts/spikes-studio?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-spikes-studio"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=411007&theme=neutral&period=weekly"
        alt="Spikes Studio - Instantly turn any long video into viral shorts! | Product Hunt"
        style={{ width: 250, height: 54 }}
        width={250}
        height={54}
      />
    </a>
  );
};

export default ProducthuntButton;
