import { Button, Layout, message } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { useContext, useEffect, useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as IconTwitch } from '../../assets/twitch.svg';
import { ReactComponent as IconGoogle } from '../../assets/google.svg';
import handleTwitchLogin from '../../utils/twitchAuth';
import { GenericModal } from '../../components/generic-modal';
import { postAudit } from '../../services/audit.service';
import LoginImage from '../../assets/login-hero.png';

import './LoginPage.less';
import { getDifferenceInHours } from '../../utils';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
const LoginPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const hideLoginModals = localStorage.getItem('hideLoginModals');
  const [searchParams, setSearchParams] = useSearchParams();
  const googleSearchParam = searchParams.get('google');
  const refCode = searchParams.get('ref');
  const fromSearchParam = searchParams.get('from');
  const campaignId = searchParams.get('utm_id');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_source = searchParams.get('utm_source');
  const campaignUrl = localStorage.getItem('campaign_url');

  useEffect(() => {
    if (refCode) {
      localStorage.setItem('ref', refCode);
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (userContext?.user) {
      navigate('/spikes/videos');
    }
  }, [userContext]);

  useEffect(() => {
    if (fromSearchParam && fromSearchParam === 'api-home') {
      localStorage.setItem('login-from', fromSearchParam);
      searchParams.delete('from');
      setSearchParams(searchParams);
      if (userContext?.user) {
        navigate('/spikes/videos');
      }
    }
  }, [fromSearchParam])


  useEffect(() => {
    if (googleSearchParam === 'true') {
      searchParams.delete('google');
      setSearchParams(searchParams);
      setTimeout(() => {
        handleGoogleLogin && handleGoogleLogin();
      }, 100);
    }
  }, [])

  const handleGoogleError = (error: any) => {
    messageApi.error('Google login error', 3);
    console.error('Google login error:', error);
    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `login error - google - ${JSON.stringify(error)}`,
      user_id: userContext?.user?.id
    });
  };

  const handleSuccessGoogleLogin = async (tokenResponse: any) => {
    if (tokenResponse) {
      if (tokenResponse.code) {
        const payload: any = {
          code: tokenResponse.code,
          referral_key: localStorage.getItem('ref') || '',
          is_mobile_login: isMobile,
          type: 'google',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        if (campaignUrl) payload.register_referral = campaignUrl;

        const response = await axios
          .post('/auth/social/twitch/login-or-register', payload)
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
            }
          });

        if (response?.status === 200) {
          localStorage.removeItem('ref');
          localStorage.removeItem('campaign_url');
          userContext?.setUser(response.data.user);
          const user = response.data.user;

          if (!hideLoginModals) navigate('/spikes/welcome');
          else navigate('/spikes/videos');

          // Data Layer tracking when Google login is successful
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'sign_up',
            method: 'Google',
            event_name: 'fireEvent',
          });
        } else {
          console.error('No code provided by google login', tokenResponse);
        }
      }


    }
  };

  const handleGoogleLoginButton = () => {
    handleLoginWithoutTokenRequest();
  };

  const handleGoogleLogin = userContext?.user ? null : useGoogleLogin({
    onSuccess: handleSuccessGoogleLogin,
    flow: 'auth-code',
    scope: 'openid email profile',
    redirect_uri: process.env.REACT_APP_GOOGLE_RETURN_URL,
    ux_mode: 'redirect',
    onError: handleGoogleError,
    onNonOAuthError: handleGoogleError,
  });

  const handleTwitchButton = async () => {
    // Push the dataLayer event first
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      method: 'Twitch',
      event_name: 'fireEvent',
    });

    handleLoginWithoutTokenRequest(true);
  };

  const handleLoginWithoutTokenRequest = async (isTwitch?: boolean) => {
    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        try {
          if (response.data && response.data.username) {
            userContext?.setUser(response.data);
          } else {
            handleTokenLogin(isTwitch);
          }
        } catch (error) {
          window.location.reload();
        }
      } else {
        handleTokenLogin(isTwitch);
      }
    } catch (error: any) {
      handleTokenLogin(isTwitch);

      if (error.response) {
        console.log('streamer/profile error:', error.response);
      }
    }
  };

  /* 
    This is being triggered only when the Token is expired meaning
    that they won't see the google/twitch login pages if token
    is still valid (e.g. if 'streamer/profile' doesn't throw an error)
  */
  const handleTokenLogin = async (isTwitch?: boolean) => {
    if (campaignId || utm_campaign || utm_source) localStorage.setItem('campaign_url', window.location.href);
    if (isTwitch) {
      await handleTwitchLogin();
    } else {
      handleGoogleLogin && handleGoogleLogin();
    }
  }

  const styles = () => {
    return {
      layout: {
        background: COLORS.MAIN_LAYOUT_BACKGROUND,
        height: '100vh',
        width: '100vw',
        paddingTop: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 10,
        paddingRight: 10
      },
      card: {
        padding: isMobile ? 20 : 50,
        display: 'flex',
        flexDirection: 'column'
      },
      title: {
        fontSize: isMobile ? 30 : 42,
        lineHeight: isMobile ? '34px' : '50px',
        marginBottom: '12px',
        fontWeight: 500,
      },
      description: {
        color: '#B7B7B7',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px'
      },
      primary: {
        color: COLORS.PRIMARY
      },
      buttons: {
        marginTop: 44,
        display: 'flex',
        flexDirection: 'column',
        gap: 23,
        // marginBottom: 12
      }
    } as const;
  };

  return (
    <div className='login-page'>
      {contextHolder}
      <div className="login-page__image">
        <img src={LoginImage} alt='Login Image' />
      </div>
      <div className="login-page__content">
        <p className="login-page__subtitle">#AI Video Repurpose Tool</p>
        <p className="login-page__title">Get Your Clips Now</p>
        <p className="login-page__description">Try it for FREE. No credit card required </p>

        <div className="login-page__buttons">
          {handleGoogleLogin &&
            <Button id='login-with-google-button' onClick={handleGoogleLoginButton} style={{ background: '#fff', color: '#000' }} className="button">
              <IconGoogle width={28} height={28} style={{ marginRight: 27, display: 'flex' }} />
              Login with Google
            </Button>
          }
          <Button id='login-with-twitch-button' style={{ background: '#BA8BFF', color: '#000' }} onClick={handleTwitchButton} className="button">
            <IconTwitch width={28} height={28} style={{ color: 'black', marginRight: 27 }} />
            Login with Twitch
          </Button>
        </div>
      </div>
    </div>
  )
};

export default LoginPage;
