import React, { useEffect } from 'react';

export const SearchHotkeyListener = ({ onHotkey }: { onHotkey: () => void }) => {

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

      if ((isMac && event.metaKey && event.key === 'k') ||
        (!isMac && event.ctrlKey && event.key === 'k')) {
        event.preventDefault();
        onHotkey();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onHotkey]);

  return null;
};

