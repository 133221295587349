import React, { useMemo } from 'react';

import { ReactComponent as IconSearch } from '../../../assets/input-search.svg';
import { ReactComponent as IconMagicWand } from '../../../assets/magic_wand.svg';
import { AIGeneratorTextArea, ImageSearchInput } from './BRollTabInput';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


interface SearchResult {
  url: string;
  height: number;
  width: number;
  thumbnail: string;
}

interface IBRollImageTabProps {
  searchText: string;
  activeTab: string;
  loadingImage: boolean;
  loadingVideo: boolean;
  noImageResponse: boolean;
  noVideoResponse: boolean;
  searchResults: SearchResult[];
  setSearchText: (text: string) => void;
  handleClick: (result: any) => void;
}


const styles = {
  tabsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 20px',
    textAlign: 'center',
  },
  searchContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    padding: '15px',
    overflowY: 'auto',
  },
  AiContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    maxHeight: '115px',
    minHeight: '115px',
    overflowY: 'auto',
  },
  imageBox: {
    width: '95px',
    height: '95px',
    margin: '10px',
    background: '#404040',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
    cursor: 'pointer',
    animationName: 'fadeIn',
    animationDuration: '3.5s',
    animationTimingFunction: 'ease',
  },
  aiTabContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '372px',
  },
  aiSearchContainer: {
    width: '375px',
    height: '197px',
    background: '#404040',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 15px 25px 15px',
  },
  aiTitle: {
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: '10px',
    resize: 'none',
  },
  aiInput: {
    width: '300px',
    height: '115px',
    borderRadius: '4px',
    background: 'rgba(121, 121, 121, 0.50) !important',
    color: '#D0C9C9',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '21.411px',
    border: 'none',
    outline: 'none',
    textAlign: 'center',
  },
  aiGenerateButton: {
    width: '375px',
    height: '44px',
    flexShrink: 0,
    borderRadius: '6px',
    background: '#2C2C2F',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginTop: '10px',
    borderColor: '#2C2C2F',
    cursor: 'pointer',
    transition: 'background-color 0.3s', // Add a transition for smooth hover effect
  },
  aiGenerateButtonText: {
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    display: 'block',
  },
  noResponseMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    marginTop: '20px',
  },
} as const;

export const BRollImageTab = ({ searchText, activeTab, loadingImage, loadingVideo, noImageResponse, noVideoResponse, searchResults, setSearchText, handleClick }: IBRollImageTabProps) => {
  return (
    <div key='b-roll-image-tab-id' style={styles.searchContainer}>
      <div className='search-box'>
        <IconSearch />
        <ImageSearchInput value={searchText} onChange={setSearchText} />
      </div>
      {(activeTab === 'Image' && loadingImage) || (activeTab === 'Video' && loadingVideo) ? (
        <div style={styles.imagesContainer}>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 64, color: '#FFF' }}
                  spin
                />
              }
            />
          </div>
        </div>
      ) : activeTab === 'Image' && noImageResponse ? ( // Display "No Response" message for Image tab
        <div style={styles.imagesContainer}>
          <div style={styles.noResponseMessage}>
            <span>No videos found for your search.</span>
            <span>Please try a different search text.</span>
          </div>
        </div>
      ) : activeTab === 'Video' && noVideoResponse ? ( // Display "No Response" message for Video tab
        <div style={styles.imagesContainer}>
          <div style={styles.noResponseMessage}>
            <span>No videos found for your search.</span>
            <span>Please try a different search text.</span>
          </div>
        </div>
      ) : (
        <div className='editor-grid'>
          {searchResults.map((result, index) => (
            <div
              className='editor-grid__item transparent-background'
              key={index}
              onClick={() => handleClick(result)}
            >
              <img
                src={activeTab === 'Image' ? result.url : result.thumbnail}
                alt={`Image ${index}`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}