import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Image,
  Layout,
  Tooltip,
  Typography,
  message,
} from 'antd';

import axios from 'axios';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { FooterResponsive } from '../../components/footer';
import { GetMinutesCard } from '../Subscriptions/get-minutes-card';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import GreenDot from '../../assets/green-dot.svg';
import { ReactComponent as IconCopy } from '../../assets/icon-copy.svg';
import { ReactComponent as IconInfo } from '../../assets/info-circle.svg';

import '../Dashboard/Dashboard.less';
import { roundWithTwoDecimals, showGoProButton } from '../../utils';
import { isNumber } from 'lodash';
import { COLORS } from '../../themes/colors';
import { GetApiCreditsCard } from '../Subscriptions/get-api-credits-card';

import { MainLayout } from '../../layouts';
import { isBrowser } from 'react-device-detect';
import { Header } from '../../components/header';
import { Footer as AppFooter } from '../../components/footer';

const { Content } = Layout;
const { Title } = Typography;

const APIKeyTooltip = 'This is a unique access key for automating tasks through our API. This feature is particularly relevant for Marketing teams and developers seeking to integrate our services seamlessly into their workflows.';

export const ApiDashboard = () => {
  const userContext = useContext(UserContext);
  const subscriptions = userContext?.user?.subscriptions;
  const [responseData, setResponseData] = useState<any>(null);
  const [loadingApiKey, setLoadingApiKey] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalMinutes = userContext?.user?.total_upload_minutes
  const apiToken = userContext?.user?.api_token;

  const isAPIUser = userContext?.user?.role === 'API';

  const navigate = useNavigate();

  const styles = useMemo(() => {
    return {
      title: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '34px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '36.1px',
        marginBottom: '40px',
      },
      parentContainer: {
        display: 'flex',
      },
      container: {
        gap: '30px',
        flexShrink: 0,
        borderRadius: '8px',
        border: '0px solid #6D6D6D',
        background: '#28292A',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 0 20px 0',
      },
      topSection: {
        display: 'flex',
        height: '68px',
        padding: '16px 116px 15px 116px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        background: '#808180',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      topText: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '36.1px', // 150.417%
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between', // Create two columns
        padding: '0 20px', // Adjust as needed
        alignItems: 'center',
        height: '36px',
      },
      rowText: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '26.1px', // 225.625%
        display: 'flex',
        alignItems: 'center',
        width: '65%', // First span takes 70% width
        position: 'relative'
      },
      secondRowText: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '26.1px', // 225.625%
        display: 'flex',
        alignItems: 'center',
        width: '35%', // Second span takes 30% width
      },
      buttonContainer: {
        textAlign: 'center',
        marginTop: '20px',
      },
      button: {
        width: '152px',
        height: '46px',
        backgroundColor: 'rgba(250, 249, 105, 1)',
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28.6px',
        borderRadiuss: '6px',
        border: 'none'
      },
      separator: {
        height: 1,
        background: 'rgb(89, 89, 89)',
        width: 'calc(100% - 40px)',
        margin: '0 auto'
      }
    } as const;
  }, []);

  const handleItemClick = () => {
    // Redirect to '/subscriptions' when the button is clicked
    navigate('/subscriptions');
  };

  useEffect(() => {
    const getAccountDetailed = async () => {
      try {
        const response = await axios.get('/streamer/dashboard-subscription');
        if (response?.status === 200) {
          const responseData = response?.data;
          setResponseData(responseData);
        }
      } catch (error: any) {
        if (error.response) {

          console.log(error.response, 'getAccountDetailed error');
        }
      }
    };
    getAccountDetailed();
  }, []);

  // Update user object
  useEffect(() => {
    const updateUser = async () => {
      try {
        const response = await axios.get('streamer/profile');
        if (response?.status === 200) {
          userContext?.setUser(response.data);
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };

    updateUser();
  }, []);

  const handleGenerateAPIKey = async () => {
    if (loadingApiKey) return null;

    setLoadingApiKey(true);
    const response = await axios.post('/streamer/generate-api-token');
    setLoadingApiKey(false);

    if (response?.data) {
      if (response?.data?.id) {
        userContext?.setUser(response?.data);
      }
      else {
        messageApi.error('Failed to generate API token');
      }
    } else {
      messageApi.error('Failed to generate API token');
    }

    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        userContext?.setUser(response.data);
      }
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
      }
    }
  }

  const copyApiToken = () => {
    if (apiToken) {
      navigator.clipboard.writeText(apiToken);
      message.success({
        content: 'API Token copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  }

  const minutesLeft = isNumber(totalMinutes) && isNumber(usedMinutes) && roundWithTwoDecimals(totalMinutes! - usedMinutes!);


  return (
    <>
      <MainLayout
        header={isBrowser ? <Header title={''} /> : null}
        footer={<AppFooter shadow={false} />}
        marginTop='26px'
      >
        {contextHolder}
        <Content className='dashboard-content'>
          {/* Title */}
          <Title
            level={1}
            style={styles.title}
          >
            API Dashboard
          </Title>
          {/* Parent Container */}
          <div className='dashboard__parent-container' style={styles.parentContainer}>
            {/* Container */}
            <div className='dashboard__card' style={styles.container}>
              {/* Top Section */}
              <div style={styles.topSection}>
                <span style={styles.topText}>API</span>
              </div>
              {/* Rows of Content */}
              {/* ... (Your existing rows) */}
              <div style={styles.row}>
                <span style={styles.rowText}>{isAPIUser ? 'API Account' : responseData?.plan_name}</span>
                <span style={styles.secondRowText}>
                  Active
                  <img
                    src={GreenDot}
                    alt="Green Dot"
                    style={{ marginLeft: '8px' }} // Add margin to position the GreenDot
                  />
                </span>
              </div>
              <div style={styles.row}>
                <span style={styles.rowText}>Downloads</span>
                <span style={styles.secondRowText}>{responseData?.total_downloads}</span>
              </div>

              {isAPIUser &&
                <div style={styles.row}>
                  <span style={styles.rowText}>API credits</span>
                  <span style={styles.secondRowText}>{userContext?.user?.api_credits}</span>
                </div>
              }
              {apiToken &&
                <div style={styles.row}>
                  <span style={styles.rowText}>
                    <span style={{ position: 'relative' }}>API Token <Tooltip overlayClassName='tooltip' title={APIKeyTooltip}><IconInfo className='dashboard__info-circle' /></Tooltip></span>

                  </span>
                  <span className='api-token-string' style={styles.secondRowText}>
                    <span>{apiToken.substring(0, 10)}...</span>
                    <IconCopy onClick={copyApiToken} />
                  </span>
                </div>
              }
              {/* <div style={styles.row}>
                <span style={styles.rowText}>Minutes added</span>
                <span style={styles.secondRowText}>0 mins</span>
              </div> */}

              {!apiToken &&
                <div style={{ display: 'flex', gap: '16px' }}>
                  <div onClick={handleGenerateAPIKey} className={`dashboard__generate-api-key ${loadingApiKey && 'loading'}`}>
                    {loadingApiKey ? 'Loading...' : 'Generate API Key'}
                  </div>
                  <Tooltip overlayClassName='tooltip' title={APIKeyTooltip}><IconInfo className='button__info-circle' /></Tooltip>
                </div>
              }

              {showGoProButton(userContext?.user) && (
                <div style={styles.buttonContainer}>
                  <Button
                    className='go-pro-button'
                    type="primary"
                    style={styles.button}
                    onClick={handleItemClick}
                  >
                    Go Pro
                  </Button>
                </div>
              )}

            </div>
            <div className='pricing-cards'>
              {apiToken &&
                <GetApiCreditsCard />
              }
            </div>
          </div>
        </Content>
        <FooterResponsive />
      </MainLayout>
    </>
  );
};


