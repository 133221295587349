import { Image, message, Steps } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import './OnboardingPage.less';
import OnboardingFirstImage from '../../assets/onboarding-first.png';
import OnboardingSecondImage from '../../assets/onboarding-second.png';
import { ReactComponent as IconPlay } from '../../assets/onboarding_play.svg';
import { ReactComponent as IconMic } from '../../assets/onboarding_mic.svg';
import { ReactComponent as IconStreamer } from '../../assets/onboarding_streamer.svg';
import { ReactComponent as IconText } from '../../assets/onboarding_text.svg';
import { ReactComponent as IconManager } from '../../assets/onboarding_manager.svg';
import { ReactComponent as IconAI } from '../../assets/onboarding_ai.svg';
import { ReactComponent as IconGoogle } from '../../assets/onboarding_google.svg';
import { ReactComponent as IconTikTok } from '../../assets/onboarding_tiktok.svg';
import { ReactComponent as IconInstagram } from '../../assets/onboarding_instagram.svg';
import { ReactComponent as IconTwitter } from '../../assets/onboarding_twitter.svg';
import { ReactComponent as IconYoutube } from '../../assets/onboarding_youtube.svg';
import { ReactComponent as IconFacebook } from '../../assets/onboarding_facebook.svg';
import { ReactComponent as IconRightArrow } from '../../assets/onboarding_right_arrow.svg';
import { ReactComponent as IconLogoGray } from '../../assets/spikes_logo_gray.svg';
import { ReactComponent as IconLogoText } from '../../assets/spikes_logo_text.svg';
import { ReactComponent as IconFace } from '../../assets/face_person_new.svg';
import FacePerson from '../../assets/face_person.png';
import { ReactComponent as IconLogo } from '../../assets/logo_sidebar.svg';
import { ReactComponent as IconBg } from '../../assets/onboarding_bg.svg';

import { GiftOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { shuffleArrayWithOtherLast } from '../../utils';

const firstStepItems = [
  {
    value: 'business',
    label: 'Business',
    icon: <ShopOutlined />,
  },
  {
    value: 'content_creator',
    label: 'Content Creator',
    icon: <IconPlay />,
  },
  {
    value: 'podcast_producer',
    label: 'Podcast Producer',
    icon: <IconMic />,
  },
  {
    value: 'twitch_streamer',
    label: 'Twitch Streamer',
    icon: <IconStreamer />,
  },
  {
    value: 'video_editor',
    label: 'Video Editor',
    icon: <IconText />,
  },
  {
    value: 'ai_enthusiast',
    label: 'AI Enthusiast',
    icon: <IconAI />,
  },
  {
    value: 'social_media_manager',
    label: 'Social Media Manager',
    icon: <IconManager />,
  },
  {
    value: 'other',
    label: 'Other',
    icon: <UserOutlined />,
  },
];

const secondStepItems = [
  {
    value: 'referred_by_friend',
    label: 'Referred by a Friend',
    icon: <GiftOutlined />,
  },
  {
    value: 'google',
    label: 'Google',
    icon: <IconGoogle />,
  },
  {
    value: 'tiktok',
    label: 'TikTok',
    icon: <IconTikTok />,
  },
  {
    value: 'instagram',
    label: 'Instagram',
    icon: <IconInstagram />,
  },
  {
    value: 'twitter',
    label: 'Twitter',
    icon: <IconTwitter />,
  },
  {
    value: 'youTube',
    label: 'YouTube',
    icon: <IconYoutube />,
  },
  {
    value: 'facebook',
    label: 'Facebook',
    icon: <IconFacebook />,
  },
];

const onboardingList = [
  {
    id: 0,
    name: 'profession',
    random_order: true,
    title: 'What best describes your occupation?',
    options: [
      {
        value: 'Video Editor',
        label: 'Video Editor',
      },
      {
        value: 'Content Creator',
        label: 'Content Creator',
      },
      {
        value: 'Marketing Professional',
        label: 'Marketing Professional',
      },
      {
        value: 'Social Media Manager',
        label: 'Social Media Manager',
      },
      {
        value: 'Business Owner',
        label: 'Business Owner',
      },
      {
        value: 'Other',
        label: 'Other',
      },
    ],
  },
  {
    id: 10,
    name: 'organization_size',
    title: 'How big is your organization?',
    options: [
      {
        value: 'Solo entrepreneur',
        label: 'Solo entrepreneur',
      },
      {
        value: '2-10 employees',
        label: '2-10 employees',
      },
      {
        value: '11-50 employees',
        label: '11-50 employees',
      },
      {
        value: '51-200 employees',
        label: '51-200 employees',
      },
      {
        value: '201+ employees',
        label: '201+ employees',
      },
    ],
  },
  {
    id: 20,
    name: 'prev_editing_method',
    random_order: true,
    title: 'How do you currently edit short videos?',
    options: [
      {
        value: 'Manually edit for each platform',
        label: 'Manually edit for each platform',
      },
      {
        value: 'Outsource to a team or agency',
        label: 'Outsource to a team or agency',
      },
      {
        value: 'Use AI tools',
        label: 'Use AI tools',
      },
      {
        value: "I don't edit short content",
        label: "I don't edit short content",
      },
    ],
  },
  {
    id: 30,
    name: 'prev_editing_challenge',
    random_order: true,
    title: 'What’s the biggest challenge when creating short videos?',
    options: [
      {
        value: 'Takes too long to edit',
        label: 'Takes too long to edit',
      },
      {
        value: 'I run out of ideas',
        label: 'I run out of ideas',
      },
      {
        value: 'Posting to all SM platforms simultaneously',
        label: 'Posting to all SM platforms simultaneously',
      },
      {
        value: 'Budget constraints',
        label: 'Budget constraints',
      },
      {
        value: 'Lack of editing skills',
        label: 'Lack of editing skills',
      },
      {
        value: 'Measuring performance',
        label: 'Measuring performance',
      },
    ],
  },
  {
    id: 40,
    name: 'videos_type',
    random_order: true,
    title: 'What’s the main focus of your videos?',
    options: [
      {
        value: 'Tutorials/Educational videos',
        label: 'Tutorials/Educational videos',
      },
      {
        value: 'Product demos',
        label: 'Product demos',
      },
      {
        value: 'Marketing/Promotional videos',
        label: 'Marketing/Promotional videos',
      },
      {
        value: 'Gaming/Sports',
        label: 'Gaming/Sports',
      },
      {
        value: 'Webinars/Live streams',
        label: 'Webinars/Live streams',
      },
      {
        value: 'Interviews/Podcasts',
        label: 'Interviews/Podcasts',
      },
    ],
  },
  {
    id: 50,
    name: 'usage_goal',
    random_order: true,
    title: 'What are your main goals for creating short videos?',
    options: [
      {
        value: 'Increase brand awareness',
        label: 'Increase brand awareness',
      },
      {
        value: 'Generate leads or sales',
        label: 'Generate leads or sales',
      },
      {
        value: 'Educational',
        label: 'Educational',
      },
      {
        value: 'Boost social media engagement',
        label: 'Boost social media engagement',
      },
    ],
  },
];

const initialAnswers = {
  profession: '',
  organization_size: '',
  prev_editing_method: '',
  prev_editing_challenge: '',
  videos_type: '',
  usage_goal: '',
};

const OnboardingPage = () => {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [answers, setAnswers] = useState<any>(initialAnswers);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const currentList = onboardingList[onboardingStep];
  const currentAnswer = answers[currentList?.name];
  const currentOptions = useMemo(
    () =>
      currentList?.random_order
        ? shuffleArrayWithOtherLast(currentList?.options)
        : currentList?.options,
    [onboardingStep],
  );

  useEffect(() => {
    if (
      userContext?.user?.audience ||
      userContext?.user?.referrer_source ||
      userContext?.user?.category ||
      userContext?.user?.profession ||
      userContext?.user?.organization_size ||
      userContext?.user?.prev_editing_method
    ) {
      navigate('/spikes/videos');
    }
  }, [userContext]);

  useEffect(() => {
    const items = document.querySelectorAll('.onboarding__item');
    items.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('fade-in');
      }, index * 100); // Adjust the delay to control the stagger effect
    });
  }, [onboardingStep]);

  // const handleClickNext = () => {
  //   if (!currentAnswer) {
  //     messageApi.info('Please select one option.');
  //     return;
  //   }
  //   if (onboardingStep < onboardingList.length - 1) {
  //     setOnboardingStep((prev) => prev + 1);
  //   } else {
  //     handleFinishOnboarding();
  //   }
  // };

  const handleClickBack = () => {
    if (onboardingStep > 0) {
      setOnboardingStep((prev) => prev - 1);
    }
  };

  const handleFinishOnboarding = async (data: any) => {
    const response = axios
      .put('/streamer/questioneer', data)
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });

    navigate('/spikes/videos');
    localStorage.setItem('onboarding_finished', 'true');
  };

  const handleItemClick = (value: string) => {
    const copy: any = { ...answers };
    copy[currentList.name] = value;

    setAnswers(copy);

    // Add a delay before moving to the next step
    setTimeout(() => {
      if (onboardingStep < onboardingList.length - 1) {
        setOnboardingStep((prev) => prev + 1);
      } else {
        handleFinishOnboarding(copy);
      }
    }, 300);
  };

  const isSelectedItem = (value: string) => {
    return currentAnswer === value;
  };

  return (
    <div className="onboarding">
      <IconLogo className="onboarding__main-logo" />
      {contextHolder}
      <div className="onboarding__wrapper">
        <IconBg className="onboarding__wrapper-bg" />
        <div className="onboarding__hero">
          <Image src={FacePerson} width={100} preview={false} />

          <div className="onboarding__hero-text">
            <div className="onboarding__hero-title">Welcome to Spikes!</div>
            <div className="onboarding__hero-subtitle">
              Help us get to know you better.
            </div>
          </div>
        </div>
        <div className="onboarding__content">
          <div className="onboarding__form-container">
            <div className="onboarding__form">
              <div className="onboarding__progress-bar">
                {onboardingList.map((section, index) => (
                  <div
                    key={section.name}
                    className={`onboarding__progress-item ${onboardingStep >= index && 'completed'
                      }`}
                  />
                ))}
              </div>
              <div className="onboarding__subtitle">
                #AI Video Repurpose Tool
              </div>
              <p className="onboarding__title">{currentList?.title}</p>
              <div className="onboarding__questions">
                {currentOptions.map((item) => (
                  <div
                    key={item.value + currentList?.name}
                    className={`${isSelectedItem(item.value) && 'onboarding-item-active'
                      }`}
                  >
                    <div
                      onClick={() => handleItemClick(item.value)}
                      className={`onboarding__item`}
                    >
                      {/* {item?.icon && item?.icon} */}
                      <p>{item.label}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="onboarding__buttons">
                {onboardingStep > 0 && (
                  <div
                    onClick={handleClickBack}
                    className="onboarding__back-button onboarding__btn"
                  >
                    <p>Back</p>
                  </div>
                )}
                {/* <div
                  onClick={handleClickNext}
                  className={`onboarding__next-button onboarding__btn ${!currentAnswer && 'disabled'
                    }`}
                >
                  <p>Next</p>
                  <IconRightArrow />
                </div> */}
              </div>
            </div>
          </div>
          {/* <div onClick={handleSkipNow} className="onboarding__skip">
          <div className="onboarding__skip-button">Skip Now</div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
