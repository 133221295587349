import React, { useEffect, useState, useMemo } from 'react';
import { ReactComponent as BinIcon } from '../../../../assets/bin_gray.svg';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export interface IEditorFeatureSlider {
  id: any;
  lockedSlider?: boolean;
  duration: number;
  featureName: string;
  color: string;
  value: number[];
  setFeatureSliders: any;
  handleDeleteFeatureSlider?: (id: any) => void;
  handleSliderTimeChange?: (id: any, time: number | number[]) => void;
}

export const EditorFeatureSlider = ({ lockedSlider, duration, featureName, color, value, setFeatureSliders, id, handleDeleteFeatureSlider, handleSliderTimeChange }: IEditorFeatureSlider) => {
  const [rerender, setRerender] = useState(false);
  useEffect(() => {
    setRerender(prev => !prev);
  }, [duration]);

  const styles = useMemo(() => {
    return {
      main: {
        position: 'relative'
      },
      deleteIcon: {
        position: 'absolute',
        left: '-16px',
        top: '10px',
        cursor: 'pointer'
      },
      bin: {
        position: 'absolute',
        left: '-27px',
        top: '9px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3px 5px',
        borderRadius: '4px',
        backgroundColor: '#1E1E29'
      }
    } as const;
  }, []);

  const handleDeleteSlider = () => {
    handleDeleteFeatureSlider && handleDeleteFeatureSlider(id);
  };

  return (
    <div style={styles.main}>
      <div className="slider-delete-icon"><BinIcon onClick={handleDeleteSlider} /></div>
      <Slider
        className={`editor-feature-slider ${lockedSlider && 'editor-feature-slider__locked'}`}
        range
        step={0.25}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackStyle={{ '--content-text': `"${featureName}"` }}
        min={0}
        max={duration}
        onChange={(time) => handleSliderTimeChange && handleSliderTimeChange(id, time)}
        draggableTrack
        disabled={lockedSlider}
        value={value}
      />
    </div>
  );
};

export default EditorFeatureSlider;
