import { useMemo } from 'react';
import { Typography, Image } from 'antd';
import { Element } from 'react-scroll';

import './HomepageFeatures.less';
import { COLORS } from '../../../../themes/colors';
import hd from '../../../../assets/hd.svg';
import editor from '../../../../assets/editor.svg';
import captions from '../../../../assets/captions.svg';
import discord_support from '../../../../assets/discord_support.svg';
import manual_clipping from '../../../../assets/manual_clipping.svg';
import brodcasting from '../../../../assets/brodcasting.svg';
import mobileHd from '../../../../assets/mobile-hd.svg';
import mobileCaptions from '../../../../assets/mobile-captions.svg';
import mobile_discord_support from '../../../../assets/mobile-discord_support.svg';
import mobile_manual_clipping from '../../../../assets/mobile-manual_clipping.svg';
import mobile_broadcasting from '../../../../assets/mobile-broadcasting.svg';
import hashtags_and_description from '../../../../assets/hashtags-and-description.svg';
import { isMobile } from 'react-device-detect';

const featuresList = [
  [
    { title: 'Source Quality', description: <>We ensures that your highlights maintain their <span className="homepage-features__bold-text">original quality</span> for the best viewing experience.</>, icon: hd },
    { title: '24 / 7 Support', description: <>At your service <span className="homepage-features__bold-text">round-the-clock</span> on Discord!</>, icon: discord_support },
  ],
  [
    {
      title: 'Broadcast Processing', description: <>In addition to being able to upload ANY video, your <span className="homepage-features__bold-text">broadcasts are automatically processed,</span> ready for review at the end of your stream..</>, icon: brodcasting
    },
    { title: 'Auto Captions +', description: <>flexible and automatic up to <span className="homepage-features__bold-text">word-level precision.</span></>, icon: captions },
  ],
  [
    {
      title: 'Video Editor', description: <><span className="homepage-features__bold-text">Advanced Swift editing</span> bringing your vision to life.</>, icon: manual_clipping
    },
    { title: 'Automatic Hashtags & Descriptions', description: <>Relevant hashtags and descriptions, <span className="homepage-features__bold-text">enhancing discoverability & engagement.</span></>, icon: editor, comingSoon: false },
  ],
];

const featuresListMobile = [
  [
    { title: 'Auto captions', description: <>Caption with 99% + accuracy to increase watch time by 45%</>, icon: mobileCaptions },

    { title: 'Source Quality', description: <>Highlights maintain their original quality for the best viewing experience</>, icon: mobileHd },
  ],
  [
    {
      title: 'Video editor', description: <>Advanced Swift editing bringing your vision to life</>, icon: mobile_manual_clipping
    },
    { title: 'Automatic Hashtags & descriptions', description: <>Relevant hashtags and descriptions, enhancing discoverability & engagement.</>, icon: hashtags_and_description, comingSoon: false },
  ],
  [
    {
      title: 'Broadcast Processing', description: <>Your Live-Streams are automatically processed, ready for review at the end of your stream.</>, icon: mobile_broadcasting
    },
    { title: '24/7 Support', description: <>At your service <span className="homepage-features__bold-text">round-the-clock</span> on Discord!</>, icon: mobile_discord_support },
  ],
];

const HomepageFeatures = () => {
  const styles = useMemo(() => {
    return {
      container: {
        backgroundColor: COLORS.PRIMARY
      },
      sectionHeading: {
        color: '#2B6B56',
        fontSize: 52,
        lineHeight: '112px',
        fontWeight: 600,
        marginBottom: -8,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      },
      sectionTitle: {
        color: '#666',
        fontSize: 24,
        fontWeight: 300,
        marginBottom: 40,
        display: 'flex',
        justifyContent: 'center'
      },
      itemsContainer: {
        display: 'flex',
        gap: 24,
        flexWrap: 'wrap',
        justifyContent: 'center'
      },
      itemsColumn: {
        width: 480,
        display: 'flex',
        flexDirection: 'column',
        gap: 20
      },
      card: {
        backgroundColor: '#5DE8BA',
        borderRadius: 10,
        padding: '60px 40px'
      },
      cardTitle: {
        marginBottom: 32,
        fontSize: 42,
        fontWeight: 600,
        lineHeight: '50px',
        color: '#2B6B56'
      },
      cardDescription: {
        fontSize: 28,
        fontWeight: 300,
        lineHeight: '38px',
        color: '#2B6B56'
      }
    } as const;
  }, []);

  return (
    <Element name="features-section">
      <div className="homepage-container homepage-features" style={styles.container}>
        <div className="homepage-wrapper">
          <Typography className="section-heading" style={styles.sectionHeading}>You got it all.&nbsp;<span style={{ color: '#48B591' }}>and Fast!</span></Typography>
          <Typography className="section-title" style={styles.sectionTitle}>Features</Typography>

          {isMobile ?
            <div style={styles.itemsContainer}>
              <div className="items-column" style={styles.itemsColumn}>
                {featuresListMobile[0].map(item => <Card mobile key={item.title} title={item.title} description={item.description} icon={item.icon} comingSoon={item?.comingSoon} />)}
              </div>
              <div className="items-column" style={styles.itemsColumn}>
                {featuresListMobile[1].map(item => <Card mobile key={item.title} title={item.title} description={item.description} icon={item.icon} comingSoon={item?.comingSoon} />)}
              </div>
              <div className="items-column" style={styles.itemsColumn}>
                {featuresListMobile[2].map(item => <Card mobile key={item.title} title={item.title} description={item.description} icon={item.icon} comingSoon={item?.comingSoon} />)}
              </div>
            </div>
            :
            <div style={styles.itemsContainer}>
              <div className="items-column" style={styles.itemsColumn}>
                {featuresList[0].map(item => <Card key={item.title} title={item.title} description={item.description} icon={item.icon} comingSoon={item?.comingSoon} />)}
              </div>
              <div className="items-column" style={styles.itemsColumn}>
                {featuresList[1].map(item => <Card key={item.title} title={item.title} description={item.description} icon={item.icon} comingSoon={item?.comingSoon} />)}
              </div>
              <div className="items-column" style={styles.itemsColumn}>
                {featuresList[2].map(item => <Card key={item.title} title={item.title} description={item.description} icon={item.icon} comingSoon={item?.comingSoon} />)}
              </div>
            </div>
          }


        </div>
      </div>
    </Element>
  );
};

const Card = ({ title, description, icon, comingSoon, mobile = false }: { title: string, description: React.ReactNode, icon: string, comingSoon?: boolean, mobile?: boolean }) => {
  const styles = useMemo(() => {
    return {
      card: {
        backgroundColor: '#5DE8BA',
        borderRadius: 10,
        padding: '45px 40px',
        position: 'relative'
      },
      cardTitle: {
        marginBottom: 15,
        fontSize: 28,
        fontWeight: 600,
        lineHeight: '50px',
        color: '#2B6B56'
      },
      cardDescription: {
        fontSize: 22,
        fontWeight: 300,
        lineHeight: '38px',
        color: '#2B6B56'
      },
      icon: {
        borderRadius: 5,
        height: 56,
        width: 56,
        padding: 12,
        background: '#3A3A3A',
        position: 'absolute',
        top: -15,
        left: 40
      },
      comingSoon: {
        padding: '5px 10px',
        borderRadius: 5,
        backgroundColor: '#3A3A3A',
        marginBottom: 18,
        width: 'fit-content',
        marginTop: 10
      },
      comingSoonText: {
        fontSize: 16,
        lineHeight: '20px',
        color: '#fff'
      }
    } as const;
  }, []);

  return (
    <div className={mobile ? 'card-mobile' : 'card'} style={styles.card}>

      {mobile ? <Image src={icon} preview={false} /> : <div className="icon" style={styles.icon}><Image src={icon} preview={false} width={32} style={{ color: 'white' }} /></div>}
      {comingSoon && <div style={styles.comingSoon}><Typography style={styles.comingSoonText}>Coming soon...</Typography></div>}
      <Typography className="card-title" style={styles.cardTitle}>{title}</Typography>
      <Typography className="card-description" style={styles.cardDescription}>{description}</Typography>
    </div>
  );
};

export default HomepageFeatures;
