import { Image, Slider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { COLORS } from '../../themes/colors';
import { useNavigate } from 'react-router';
import { ReactComponent as PricingCard } from '../../assets/pricing-card.svg';
import PricingModalImage from '../../assets/pricing-modal-image.png';
import IconCloseCircle from '../../assets/close-circle.svg';
import { roundWithOneDecimal } from '../../utils';
import { StripeGetMinutesButton } from '../stripe-get-minutes-button';

const minutesHardcodedValues = [50, 100, 150, 200, 250, 300];

interface IPricingModalProps {
  isOpen: boolean;
  handleClosePricingModal: () => void;
};

export const PricingModal = ({ isOpen, handleClosePricingModal }: IPricingModalProps) => {
  const navigate = useNavigate();
  const [minutesValue, setMinutesValue] = useState<number>(4);
  const [minutesNumber, setMinutesNumber] = useState<number>(25);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState<boolean>(true);

  const pricePerQuantity = 2.5;

  useEffect(() => {
    const minutesValueFromIndex = minutesHardcodedValues[minutesValue - 1];
    const quantityNumber = minutesValueFromIndex / 25;
    setQuantity(quantityNumber);
    setMinutesNumber(minutesValueFromIndex);
    const roundedPrice = roundWithOneDecimal(quantityNumber * pricePerQuantity);
    if (roundedPrice) {
      setTotalPrice(roundedPrice);
    };
  }, [minutesValue]);


  const styles = useMemo(() => {
    return {
      checkboxStyle: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 0,
      },
      modalStyle: {
        borderRadius: 20,
        overflow: 'hidden',
        border: '2px solid grey',
        minWidth: 690
      },
      modalTitle: {
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: 8,
        textAlign: 'center',
        color: COLORS.PRIMARY
      },
      modalBody: {
        background: COLORS.MODAL_NAVY_BACKGROUND,
        padding: '20px 40px 40px 40px',
        position: 'relative',
      },
      description: {
        color: '#9E9E9E',
        fontSize: 18,
        lineHeight: '26px',
        fontWeight: 300,
      },
      item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      itemText: {
        fontSize: 20,
        fontWeight: 400
      },
      recommendedText: {
        fontSize: 16,
        color: COLORS.PRIMARY
      },
      timerContainer: {
        background: COLORS.SYSTEM_RED,
        borderRadius: 4,
        padding: '3px 12px',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)'
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
      },
      descriptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 70
      },
      sliderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      },
      buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: 25
      },
      trackStyle: {
        height: 8,
        marginTop: -2,
        marginLeft: -4,
        borderRadius: '4px 0 0 4px',
        background: COLORS.PRIMARY
      },
      sliderValues: {
        width: '318px',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
        marginBottom: 10
      },
      juice: {
        position: 'absolute'
      },
      title: {
        color: COLORS.PRIMARY,
        fontSize: 24,
        lineHeight: '36px',
        marginBottom: 10
      },
      doubleMinutes: {
        fontSize: 42,
        lineHeight: '44px',
        fontWeight: 500,
        marginTop: 30,
        marginBottom: 20
      },
      finalPrice: {
        color: COLORS.YELLOW,
        fontSize: 58,
        fontWeight: 600
      },
      pricesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
      },
      listPrice: {
        color: '#B7B7B7',
        fontSize: 18,
        fontWeight: 400
      },
      priceStroke: {
        color: COLORS.WHITE,
        textDecoration: 'line-through'
      },
      minutes: {
        fontSize: 28,
        color: COLORS.PRIMARY
      },
      hdClips: {
        fontSize: 18
      }
    } as const;
  }, []);


  const calculatedTotalPrice = totalPrice / 2;
  const listPrice = totalPrice;

  const handlePricingCardClick = () => {
    window.open('/subscriptions', '_blank');
    handleClosePricingModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-pricing">
        <Image onClick={handleClosePricingModal} wrapperStyle={{ position: 'absolute', right: '20px', top: '15px', cursor: 'pointer', zIndex: 4 }} src={IconCloseCircle} preview={false} width={32} />
        <div className="modal-pricing__left">
          <div className="left-title">Try now the best <span style={{ color: COLORS.YELLOW }}>PRO features</span></div>
          <PricingCard style={{ maxWidth: '100%' }} className="cursor-pointer" onClick={handlePricingCardClick} />
          {/* <div style={styles.descriptionContainer}>
            <div style={{ maxWidth: 300 }}>
              <div style={styles.doubleMinutes}>Double your minutes</div>
              <div style={styles.description}><span style={{ color: COLORS.WHITE }}>Subscribe</span> to any Spikes PRO Plan, and we&apos;ll <span style={{ color: COLORS.PRIMARY }}>DOUBLE IT FOR FREE!</span></div>
            </div>
            <div style={styles.pricesContainer}>
              <div style={styles.finalPrice}>${calculatedTotalPrice}</div>
              <div style={styles.listPrice}><span>List Price:</span> <span style={styles.priceStroke}>${totalPrice}</span></div>
              <div style={styles.listPrice}><span>Save:</span> <span style={{ color: '#cfcfcf' }}>${roundWithOneDecimal(listPrice - calculatedTotalPrice)}</span> <span>(50%)</span></div>
            </div>
          </div>
          <div style={styles.sliderContainer}>
            <div style={{ marginBottom: 5 }}><span style={styles.minutes}>{minutesNumber}min</span> <span style={styles.hdClips}>(~ {Math.round(minutesNumber * 1.25)} HD clips)</span></div>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <div className="stroke-slider-background" />
              <Slider
                value={minutesValue}
                onChange={setMinutesValue}
                dots
                min={1}
                max={6}
                step={1}
                tooltip={{ open: false }} trackStyle={styles.trackStyle} />
            </div>
            <div style={styles.sliderValues}>
              <span>50m</span>
              <span>100m</span>
              <span>150m</span>
              <span>200m</span>
              <span>250m</span>
              <span>300m</span>
            </div>

          </div>
          <div id='get-it-now-button' style={styles.buttonContainer}>
            <StripeGetMinutesButton
              stripe_plan_id={'stripe_plan_id'}
              amount={totalPrice}
              quantity={1}
              minutes={minutesHardcodedValues[minutesValue - 1]}
              mainColor={COLORS.PRIMARY}
              textColor={COLORS.BLACK}
              discount={50}
            />
          </div> */}
          <div className="comparisons-and-faq" onClick={() => navigate('/subscriptions')}>Comparisions & FAQ</div>
        </div>
        <div className="modal-pricing__right">
          <Image src={PricingModalImage} preview={false} />
          <div className="right-text-container">
            <div className="right-text-container__title">AI-Generated B-Roll</div>
            <div className="right-text-container__description">Enhance your videos with AI-powered B-roll for better storytelling and visual appeal, featuring customizable background footage.</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
