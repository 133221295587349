import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './BasicEditor.less';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { formatTime, getEnabledGifsForUser } from '../../../utils';
import { ReactComponent as IconSearch } from '../../../assets/input-search.svg';
import { UserContext } from '../../../data/userContext';
import { useFocus } from '../Editor.page';
import { ReactComponent as SilenceIcon } from '../../../assets/silence.svg';
import { formatSeconds } from '../../../utils/generic';
import { SubtitleSentence, SubtitleWord } from '../../../data/intefaces/stream.interface';
import SubtitlesWord from '../subtitles/subtitles-word/SubtitlesWord';
import { TimeRangeSpan } from '../subtitles/subtitles-panel/SubtitlesPanel';
import SubtitlesEmoji from '../subtitles/subtitles-emoji/SubtitlesEmoji';

export interface IGifsAnimationTab {
  sentences: SubtitleSentence[];
  subtitlesPanelScrollRef: any;
  loadingSubtitles?: boolean;
  trimValues: [number, number];
  rightToLeft?: boolean;
  activeSentenceIndex: number;
  optimizeEditor?: boolean;
  activeSentenceRef: any;
  emojiEnabled?: boolean;
  animation?: string;
  selectedWordsStartTimes: number[];
  currentTime: number;
  setTrimValues: (trimValues: [number, number]) => void;
  handleClickSilentMomentsButton: () => void;
  doesSilentMomentsExist: () => boolean;
  handleWordClick: (wordStart: number) => void;
  checkHiddenWord: (word: SubtitleWord) => boolean | undefined;
  handleTimeRangeChange: (value: number, sentenceIndex: number, isStart: boolean, spanRef: any) => void;
  handleWordChange: (value: string | number, param: keyof SubtitleWord, sentenceIndex: number, wordIndex: number) => void;
}

const BasicEditor = ({ sentences, loadingSubtitles, subtitlesPanelScrollRef, trimValues, rightToLeft, activeSentenceIndex, optimizeEditor, activeSentenceRef, emojiEnabled, animation, selectedWordsStartTimes, currentTime, setTrimValues, handleClickSilentMomentsButton, doesSilentMomentsExist, handleWordClick, checkHiddenWord, handleTimeRangeChange, handleWordChange }: IGifsAnimationTab) => {
  const userContext = useContext(UserContext);


  return (
    <div className='subtitles-wrapper scrollbar'>
      {loadingSubtitles ? <Spin style={{ marginTop: 100 }} indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} /> :
        <>
          <div className='subtitles-top-buttons'>
            <div onClick={handleClickSilentMomentsButton} className={`remove-silent-moments-button ${!doesSilentMomentsExist() && 'disabled'}`}>
              <span className='beta-tag__silent-moments'>Beta Feature</span>
              <div className='wrapper'>
                <SilenceIcon />
                <span>Remove Silent Moments</span>
              </div>
            </div>
          </div>
          {/* <div className='subtitles-editable-switch'>
                <span className={!contentEditableMode ? 'active' : ''}>Selectable words</span>
                <Switch
                  className="ios-switch blue"
                  checked={contentEditableMode}
                  onChange={(value: boolean) => setContentEditableMode(value)}
                />
                <span className={contentEditableMode ? 'active' : ''}>Editable words</span>
              </div> */}
          <div className='subtitles-trimming-time'>
            <span>{formatSeconds(trimValues[0])} - {formatSeconds(trimValues[1])}</span>
          </div>
          <div ref={subtitlesPanelScrollRef} style={{
            // height: 275
            flex: 1
          }} className={`subtitles-container-panel scrollbar ${rightToLeft ? 'right-to-left' : ''}`} id='subtitles-container'>

            {/* {sentences.slice(cutSubtitles[0], cutSubtitles[1]).map((sentence, sentenceIndex) => ( */}
            {sentences.map((sentence, sentenceIndex) => (
              <div
                className={`subtitles ${sentenceIndex === activeSentenceIndex ? 'active' : ''}`}
                key={`${sentence.start}`}
                ref={
                  sentenceIndex === activeSentenceIndex ? activeSentenceRef : null
                }
                id={`sentence-${sentenceIndex}`}
              >
                {/* Time range */}
                {/* <div className='time-range'>
                <TimeRangeSpan
                  time={formatTime(sentence.start)}
                  onChange={
                    (value, spanRef) =>
                      handleTimeRangeChange(
                        value,
                        sentenceIndex,
                        true,
                        spanRef
                      )
                  }
                />
                &nbsp;-&nbsp;
                <TimeRangeSpan
                  time={formatTime(sentence.end)}
                  onChange={
                    (value, spanRef) =>
                      handleTimeRangeChange(
                        value,
                        sentenceIndex,
                        false,
                        spanRef
                      )
                  }
                />
              </div> */}

                {/* Words */}
                <div>
                  {sentence.words &&
                    sentence.words.map((word, wordIndex) => {
                      if (word.word.trim() === '') {
                        return null;
                      }

                      const inTrimmingRange = trimValues && word.start / 1000 >= trimValues[0] && word.end / 1000 <= trimValues[1]; // Word outside of trimming bar will be greyed out

                      const firstWordInTrimmingRange = trimValues[0] === Number((word.start / 1000).toFixed(2));
                      const lastWordInTrimmingRange = trimValues[1] === Number((word.end / 1000).toFixed(2));
                      return (
                        // For displaying emoji below the word, just change flexDirection: 'row' to flexDirection: 'column'
                        <div onClick={() => handleWordClick(word.start)} key={word.start} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <SubtitlesWord
                            start={word.start}
                            end={word.end}
                            key={word.start}
                            isHiddenWord={checkHiddenWord(word)}
                            fontSize={'18'}
                            background={'none'}
                            fontFamily={'Poppins'}
                            textShadow={'none'}
                            fontWeight={'400'}
                            textTransform={'none'}
                            word={word.word}
                            color={word.color}
                            isInTimeRange={inTrimmingRange}
                            setTrimValues={setTrimValues}
                            trimValues={trimValues}
                            emojiEnabled={emojiEnabled}
                            emoji={word.emoji}
                            sentenceIndex={sentenceIndex}
                            wordIndex={wordIndex}
                            // contentEditableMode={contentEditableMode}
                            isSelectedWord={selectedWordsStartTimes.includes(word.start)}
                            isHighlighted={
                              currentTime > word.start - 50 && currentTime < word.end
                            }
                            alignItems={''}
                            animation={animation}
                            // addMarginComponent={() =>
                            //   <AddMarginComponent
                            //     time={firstWordInTrimmingRange ? formatTime(sentence.start) : formatTime(sentence.end)}
                            //     isStart={firstWordInTrimmingRange}
                            //     trimValues={trimValues}
                            //     setTrimValues={setTrimValues}
                            //     onChange={
                            //       (value, spanRef) =>
                            //         handleTimeRangeChange(
                            //           value,
                            //           sentenceIndex,
                            //           firstWordInTrimmingRange,
                            //           spanRef
                            //         )
                            //     }
                            //   />
                            // }
                            timeRangeComponent={optimizeEditor ? null : <>
                              <TimeRangeSpan
                                trimValues={trimValues}
                                setTrimValues={setTrimValues}
                                isFirstWord={firstWordInTrimmingRange}
                                isLastWord={lastWordInTrimmingRange}
                                time={formatTime(sentence.start)}
                                onChange={
                                  (value, spanRef) =>
                                    handleTimeRangeChange(
                                      value,
                                      sentenceIndex,
                                      true,
                                      spanRef
                                    )
                                }
                              />
                              &nbsp;-&nbsp;
                              <TimeRangeSpan
                                trimValues={trimValues}
                                setTrimValues={setTrimValues}
                                isFirstWord={firstWordInTrimmingRange}
                                isLastWord={lastWordInTrimmingRange}
                                time={formatTime(sentence.end)}
                                onChange={
                                  (value, spanRef) =>
                                    handleTimeRangeChange(
                                      value,
                                      sentenceIndex,
                                      false,
                                      spanRef
                                    )
                                }
                              /></>}
                            onChange={handleWordChange}
                          />
                          {word?.emoji && emojiEnabled &&
                            <SubtitlesEmoji
                              emoji={word?.emoji}
                              fontSize={'18'}
                              alignItems={''}
                              gap={0}
                              word={word}
                              position={word?.emoji_captions_pos}
                              isHighlighted={
                                currentTime > word.start - 50 && currentTime < word.end
                              }
                              onChange={(value, param) =>
                                handleWordChange(
                                  value,
                                  param as keyof SubtitleWord,
                                  sentenceIndex,
                                  wordIndex,
                                )
                              }
                            />
                          }
                        </div>
                      );
                    })}
                </div>
              </div>
            ))}
          </div>
        </>
      }

    </div>
  );
};

export default BasicEditor;
