import './CancelSubscriptionModal.less';
import { ReactComponent as IconMoney } from '../../assets/reason_money.svg';
import { ReactComponent as IconFix } from '../../assets/reason_fix.svg';
import { ReactComponent as IconTransfer } from '../../assets/reason_transfer.svg';
import { ReactComponent as IconSort } from '../../assets/reason_sort.svg';
import { ReactComponent as IconCalendar } from '../../assets/reason_calendar.svg';
import { ReactComponent as IconOther } from '../../assets/reason_other.svg';

import { ReactComponent as IconRightArrow } from '../../assets/onboarding_right_arrow.svg';
import { DISCORD_LINK } from '../../constants/content-constants';

export const leaveReasonList = [
  {
    value: 'too_expensive',
    label: 'Too expensive',
    icon: <IconMoney />,
    description: <div>Take advantage of all the powerful features Spikes Studio has to offer at a reduced price.</div>
  },
  {
    value: 'technical_issues',
    label: 'Technical Issues',
    icon: <IconFix />,
    description: <div>If you have any issues, please contact us on <a href="https://intercom.help/spikes-studio/en/" target='_blank' rel="noreferrer">Intercom</a>. We&apos;re available 24/7 to help!</div>
  },
  {
    value: 'different_tool',
    label: 'We intend to use a different tool',
    icon: <IconTransfer />,
    description: <div>Experience the unique features and benefits that Spikes Studio provides.</div>
  },
  {
    value: 'not_using_enough',
    label: 'Not using enough',
    icon: <IconSort />,
    description: <div>Discover how our features can enhance your experience and make the most out of your subscription.</div>
  },
  {
    value: 'features_spikes_not_have',
    label: 'We need features that Spikes doesn’t have ',
    icon: <IconCalendar />,
    description: <div>Share your feedback with us, and we’ll work on adding the features you need.</div>
  },
  {
    value: 'other',
    label: 'Other',
    icon: <IconOther />,
    description: <div>Help us understand your needs better to improve our service.</div>
  },
];

interface ILeaveReasonProps {
  chosenReason: string | null;
  handleItemClick: (value: string) => void;
  handleSubmit: () => void;
}

export const LeaveReason = ({ chosenReason, handleItemClick, handleSubmit }: ILeaveReasonProps) => {
  return (
    <div className='leave-reason'>
      <div className='title'>We&apos;re sorry to see you go</div>
      <div className="subtitle">Would you tell us why?</div>
      <div className="leave-reason__questions">
        {leaveReasonList.map(item => (
          <div onClick={() => handleItemClick(item.value)} key={item.value} className={`leave-reason__item ${chosenReason === item.value && 'active'}`}>
            {item?.icon && item?.icon}
            <p>{item.label}</p>
          </div>
        ))}
      </div>

      <div onClick={handleSubmit} className="leave-reason__next-button">
        <p>Next</p>
        <IconRightArrow />
      </div>
    </div>
  )
}