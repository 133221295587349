import { Space, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';

const MenuItems = [
  {
    label: 'About Us',
    route: '',
  },
  {
    label: 'FAQ',
    route: '',
  },
  {
    label: 'Contact Us',
    route: '',
  },
];

export const LandingMenu = () => {
  const [route, setRoute] = useState<number>(-1);

  const styles = useMemo(() => {
    return {
      container: {
        height: 85,
        position: 'relative',
      },
      item: {
        fontSize: 15,
        fontFamily: 'Poppins',
        marginLeft: 10,
      },
      itemUnderline: {
        width: 'calc(100% - 10px)',
        height: 4,
        background: COLORS.PRIMARY,
        position: 'absolute',
        bottom: -10,
        left: 0,
        marginLeft: 10,
        transition: '0.3s',
      },
    } as const;
  }, [route]);

  return (
    <Space size='large' style={styles.container}>
      {MenuItems.map((item, index) => (
        <div className='position-relative' key={item.label} onClick={() => setRoute(index)}>
          <Typography className='uppercase cursor-pointer' style={styles.item} key={item.label}>{item.label}</Typography>
          <div style={{...styles.itemUnderline, opacity: route === index ? 1 : 0}}></div>
        </div>
      ))}
    </Space>
  );
};

export default LandingMenu;