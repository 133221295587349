import {
  Dropdown,
  Image,
  MenuProps,
  Spin,
  Tooltip,
  Typography,
  message,
  Progress,
  Space,
  Switch,
} from 'antd';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { COLORS } from '../../../themes/colors';
import { debounce } from 'lodash';

import axios from 'axios';
import {
  ITwitchVideo,
  IYoutubeVideo,
  IVimeoVideo,
  IGoogleDriveVideo,
} from '../../../data/intefaces/video.interface';
import { UserContext } from '../../../data/userContext';
import {
  isTwitchUrl,
  isYoutubeUrl,
  isVimeoUrl,
  isGoogleDriveUrl,
  roundWithTwoDecimals,
  useTraceUpdate,
  isNumber,
  renderScheduleDate,
  convertToISO,
  isISOAfterCurrentDate,
  formatISODateToShortReadable,
  completeOnboardingStep,
} from '../../../utils';
import { ReactComponent as IconStar } from '../../../assets/star.svg';
import { ReactComponent as IconBin } from '../../../assets/bin.svg';
import { ReactComponent as IconCopy } from '../../../assets/copy.svg';
import { ReactComponent as IconDropdown } from '../../../assets/caret-down.svg';
import { ReactComponent as IconCopySVG } from '../../../assets/icon-copy.svg';
import { ReactComponent as IconDownload } from '../../../assets/download-clip-icon.svg';
import { ReactComponent as IconEdit } from '../../../assets/edit-clip-icon.svg';
import { ReactComponent as IconResize } from '../../../assets/resize.svg';
import { ReactComponent as IconMobile } from '../../../assets/Smartphone-no-bg.svg';
import { ReactComponent as IconDesktop } from '../../../assets/Monitor-no-bg.svg';
import { ReactComponent as IconReddit } from '../../../assets/reddit.svg';

import { ReactComponent as IconWarning } from '../../../assets/warning.svg';
import { ReactComponent as IconLinkedin } from '../../../assets/linkedin.svg';
import { ReactComponent as IconFacebook } from '../../../assets/social_facebook.svg';
import { ReactComponent as IconInstagram } from '../../../assets/instagram.svg';
import { ReactComponent as IconPinterest } from '../../../assets/pinterest.svg';
import { ReactComponent as IconTiktok } from '../../../assets/tiktok.svg';
import { ReactComponent as IconYoutubeShorts } from '../../../assets/youtube-shorts.svg';
import { ReactComponent as IconCancel } from '../../../assets/cancel.svg';
import { ReactComponent as IconMagicWand } from '../../../assets/magic-wand.svg';

// import { ReactComponent as IconMobile } from '../../../assets/mobile-small.svg';
// import { ReactComponent as IconDesktop } from '../../../assets/desktop-small.svg';
import { default as _ReactPlayerClass } from 'react-player/lazy';
import Loader from '../../../assets/loader.gif';
import IconClose from '../../assets/modal-close.svg';
import {
  IClip,
  IClipEdit,
  ISocialPost,
  IStream,
  IVideo,
  IVideoClip,
} from '../../../data/intefaces/stream.interface';
import './ClipCard.less';
import { Player } from '../../player';
import { Collapse } from 'react-collapse';
import { SpikeScore } from '../../spike-score';
import { formatSeconds, isInt, secondsToTime } from '../../../utils/generic';
import { postAudit } from '../../../services/audit.service';
import { EAuditAction } from '../../../data/intefaces/audit.interface';
import { EGeneratedClipType } from '../../../data/enums/clip-type.enum';
import { BlockedEditorModal } from '../../blocked-editor-modal';
import { DeleteClipModal } from '../../delete-clip-modal';
import { useWindowDimensions } from '../../../pages/Editor/Editor.page';
import { ShareModal } from '../../share-modal';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import {
  LoadingOutlined,
  EditOutlined,
  ShareAltOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { DtPicker } from 'react-calendar-datetime-picker';
import Modal from 'react-modal';
import lottie from 'lottie-web';
import loader_lottie from '../../../assets/Lottie/loader_lottie.json';
import { OnboardingContext } from '../../../data/onboardingContext';
import AnalyticsDrawer from '../../analytics-drawer/AnalyticsDrawer';

const THUMBNAIL_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACoCAMAAABt9SM9AAAAA1BMVEUAAACnej3aAAAAR0lEQVR4nO3BAQEAAACCIP+vbkhAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAO8GxYgAAb0jQ/cAAAAASUVORK5CYII=';

export const renderSocialTag = (type: any) => {
  if (type === 'instagram') {
    return <IconInstagram />;
  } else if (type === 'tiktok') {
    return <IconTiktok />;
  } else if (type === 'youtube') {
    return <IconYoutubeShorts />;
  } else if (type === 'linkedin') {
    return <IconLinkedin />;
  } else if (type === 'facebook') {
    return <IconFacebook />;
  } else if (type === 'pinterest') {
    return <IconPinterest />;
  } else if (type === 'reddit') {
    return <IconReddit />;
  } else {
    return null;
  }
};

export interface IClipCardProps {
  clip: IClip & Partial<IClipEdit> & IVideoClip;
  streamTitle: string;
  clipNumber: number;
  defaultOrientation: 'desktop' | 'mobile';
  platformStreamId: string;
  // handleDeleteClip?: (clipId: string) => void;
  isTranscribePage?: boolean;
  isTwitchPage?: boolean;
  handleDirectShareClick: (clip: any, videoUrl: any) => void;
  analyticsMode?: boolean;
  handleSuccessReschedule?: (isCancelPost?: boolean) => void;
  socialPost?: ISocialPost;
  postsRefetch?: () => void;
  isApiCreatedStream?: boolean;
  isProjectExpired?: boolean;
}

export const ClipCard = memo(
  ({
    clip,
    handleDirectShareClick,
    clipNumber,
    streamTitle,
    platformStreamId,
    isTranscribePage = false,
    isTwitchPage,
    isApiCreatedStream,
    analyticsMode = false,
    handleSuccessReschedule,
    socialPost,
    postsRefetch,
    defaultOrientation,
    isProjectExpired
  }: IClipCardProps) => {
    const [isOpenDescription, setIsOpenDescription] = useState(false);
    const { width } = useWindowDimensions();
    const [showBlockedEditorModal, setShowBlockedEditorModal] =
      useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [showDirectShareModal, setShowDirectShareModal] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [isLoadingShareKey, setIsLoadingShareKey] = useState(false);
    const [newScheduleDate, setNewScheduleDate] = useState<any>(null);
    const [showScheduleCancelModal, setShowScheduleCancelModal] =
      useState(false);
    const [loadingReschedule, setLoadingReschedule] = useState(false);
    const [loadingCancelPost, setLoadingCancelPost] = useState(false);
    const userContext = useContext(UserContext);
    const hasSubscription =
      userContext?.user?.subscriptions && userContext?.user?.subscriptions[0];
    const userHasShareKey = userContext?.user?.social_profile_key;
    const playerRefDesktop = useRef(null);
    const playerRefMobile = useRef(null);
    const isYoutubeClip = true; // TODO: remove
    const isTwitchClip = false; // TODO: remove
    const navigate = useNavigate();
    const location = useLocation();
    const [loaderTextId, setLoaderTextId] = useState(0);
    const [orientation, setOrientation] = useState(
      isTwitchPage ? 'desktop' : 'mobile',
    );
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const isMobile = width < 768;
    const twoColors = { '0%': '#16E88C', '100%': '#16E88C' };
    const lastEditedClipId = localStorage.getItem('lastEditedClipId');
    const choiceReason = clip?.clip_choice_reasoning;
    const lottieRef = useRef(null);
    const onboardingContext = useContext(OnboardingContext);
    const socialType =
      socialPost?.post?.platforms &&
      socialPost?.post?.platforms.length > 0 &&
      socialPost?.post?.platforms[0];

    const allowDirectShare = userContext?.user?.permissions?.direct_share;
    const isPostedClip = !isISOAfterCurrentDate(
      socialPost?.post?.scheduleDate || '',
    ); // Check if scheduled time is later than current time

    const LOADER_TEXT = [
      'We׳re editing your video',
      'This may take up to 3 minutes',
      'Server 2 is on their phone',
      'Server 1 is killing it on "Only Up"!',
      'Server 1 is enjoying your clip, wonderful! <3',
    ];

    useEffect(() => {
      if (lottieRef.current) {
        lottie.loadAnimation({
          container: lottieRef.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: loader_lottie,
        });
      }
    }, [clip]);

    useEffect(() => {
      if (clip.status === 'ready') return;

      const timeout = setTimeout(
        () => setLoaderTextId((loaderTextId + 1) % LOADER_TEXT.length),
        4000,
      );
      return () => {
        clearTimeout(timeout);
      };
    }, [loaderTextId, clip.status]);

    useEffect(() => {
      if (analyticsMode) {
        setOrientation(defaultOrientation);
      }
    }, []);

    const styles = useMemo(() => {
      return {
        smallDivider: {
          height: 15,
          width: 1,
          backgroundColor: '#989898',
        },
        hashtagContainer: {
          marginTop: 10,
          gap: 10,
          maxWidth: '624px',
        },
        hashtagsText: {
          color: '#D3D3D3',
          display: 'inline',
          background: '#2F2F2F',
          padding: '8px',
          borderRadius: '8px',
          fontSize: 12,
        },
        hashtagsHeading: {
          color: '#B2B2B2',
          marginBottom: 5,
          fontSize: 12,
          display: 'flex',
          alignItems: 'center',
        },
        loaderContainer: {
          background: COLORS.BLACK,
          overflow: 'hidden',
          border: '1px solid rgba(0, 239, 248, 0.1)',
          position: 'relative',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        },
      } as const;
    }, []);

    let duration =
      clip.start_time_in_event != null &&
        clip.end_time_in_event != null &&
        clip.start_time_in_event >= 0 &&
        clip.end_time_in_event >= 0
        ? clip.end_time_in_event - clip.start_time_in_event
        : clip.end_time - clip.start_time;

    if (
      isTwitchClip ||
      clip.clip_type === EGeneratedClipType.TWITCH ||
      clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD ||
      isYoutubeClip
    ) {
      duration = Math.round(clip.duration || 0);
    }

    const handleCopyTimestamp = () => {
      if (isYoutubeClip) {
        if (clip?.platform_url?.includes('watch?v=')) {
          // Parse the URL to get the query string
          const queryString = new URL(clip.platform_url).search;

          // Create a URLSearchParams object to extract query parameters
          const params = new URLSearchParams(queryString);

          // Get the value of the "v" parameter
          const videoId = params.get('v');

          navigator.clipboard.writeText(
            `https://youtu.be/${videoId}?t=${clip.start_time?.toFixed()}`,
          );
        } else {
          navigator.clipboard.writeText(
            `${clip.platform_url}?t=${clip.start_time?.toFixed()}`,
          );
        }
      } else {
        navigator.clipboard.writeText(
          `https://www.twitch.tv/videos/${platformStreamId}?t=${secondsToTime(
            clip.start_time,
          )
            .replace(':', 'h')
            .replace(':', 'm')}s`,
        );
      }

      message.success({
        content: 'Timestamp copied!',
        style: {
          color: COLORS.BLACK,
        },
      });

      postAudit({
        user_action: EAuditAction.AuditActionCopySpikeTimestamps,
        clip: clip.id,
      });
    };

    const getHashtags = () => {
      if (clip.tags) {
        try {
          const validJsonString = clip.tags.replace(/'/g, '"');
          const array = JSON.parse(validJsonString);
          return array;
        } catch (error) {
          console.error('Error parsing hashtags:', error);
          return null;
        }
      } else {
        return null;
      }
    };

    const copyHashtags = () => {
      if (getHashtags()) {
        navigator.clipboard.writeText(getHashtags().join(' '));
        message.success({
          content: 'Hashtags copied!',
          style: {
            color: COLORS.BLACK,
          },
        });
      }
    };

    const copyDescription = () => {
      if (clip?.description) {
        navigator.clipboard.writeText(clip?.description);
        message.success({
          content: 'Description copied!',
          style: {
            color: COLORS.BLACK,
          },
        });
      }
    };

    const copyTitle = () => {
      if (clip?.headline || clip?.name || streamTitle) {
        navigator.clipboard.writeText(
          clip?.headline || clip?.name || streamTitle,
        );
        message.success({
          content: 'Title copied!',
          style: {
            color: COLORS.BLACK,
          },
        });
      }
    };

    const copyHeadline = () => {
      if (clip?.headline) {
        navigator.clipboard.writeText(clip?.headline);
        message.success({
          content: 'Headline copied!',
          style: {
            color: COLORS.BLACK,
          },
        });
      }
    };

    const handleToggleMobileDescription = () => {
      setIsOpenDescription(!isOpenDescription);
    };

    const renderDuration = () => {
      return formatSeconds(duration, !isInt(duration));
    };

    const renderScore = (): number => {
      if (isYoutubeClip) return Number(clip?.clip_ranking?.toFixed());
      else return Number(clip?.clip_ranking?.toFixed(1));
    };

    const renderScoreTooltip = () => {
      if (isYoutubeClip) return 'Virality Score';
      else return 'Score based on quality, hype or performance.';
    };

    const checkMobileClipRenderAvailability = () => {
      if (!clip.mobile_download_url && orientation === 'mobile') return false;
      return true;
    };

    const checkDesktopClipRenderAvailability = () => {
      return true;
    };

    const handleEdit = () => {
      if (isMobile) {
        setShowBlockedEditorModal(true);
        return;
      }

      if (isProjectExpired) {
        messageApi.error('Editing is not available. Project is expired.', 5);
        return;
      }

      completeOnboardingStep('check_editor', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);

      navigate('/edit', {
        state: {
          clip,
          platformStreamId,
          preview: false,
          fromSpikesPage: location.pathname.includes('/spikes/streams'),
          fromVideos: location.pathname.includes('/spikes/videos'),
          fromTranscribe: location.pathname.includes('/spikes/transcribe'),
        },
      });
    };

    const handleDirectShare = () => {
      // messageApi.info('Coming soon...', 5);
      if (clip.duration > 180) {
        messageApi.error('Maximum duration should be less than 3 minutes');
        return;
      }
      if (!downloadUrl) {
        messageApi.error(
          'Video has no download URL. Please try to re-upload or reach our support',
          5,
        );
      } else {
        setShowDirectShareModal(true);
      }
    };

    const handleCloseDirectShareModal = () => {
      setShowDirectShareModal(false);
      localStorage.removeItem('tiktok-share-video-url');
      localStorage.removeItem('direct-share-clip');
    };

    const handleRemoveWatermark = () => {
      navigate('/subscriptions');
    };

    const handleDownload = async (isMobile?: boolean, isHD?: boolean) => {
      if (isProjectExpired) {
        messageApi.error('This project is expired.', 5);
        return;
      }

      completeOnboardingStep('share_download', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);

      // if (isHD) { console.log('HD quality download'); };
      message.loading({
        content: 'Downloading...',
        style: {
          color: COLORS.BLACK,
        },
        key: 'downloadingMessage',
        duration: 10,
      });

      const downloadUrl = !isMobile
        ? clip.desktop_download_url || clip.download_url
        : clip.mobile_download_url;

      if (!downloadUrl) {
        postAudit({
          user_action: EAuditAction.AuditActionDownload,
          clip: clip.id,
          details: 'No download URL populated',
        });
      }

      setDownloading(true);

      const response = await axios.get(
        `/streamer/get-clip-download-url?video_url=${downloadUrl}`,
      );
      if (response?.status === 200 && response?.data) {
        fetch(response?.data, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/mp4',
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            setDownloading(false);
            message.destroy('downloadingMessage');
            message.success({
              content: 'Downloaded!',
              style: {
                color: COLORS.BLACK,
              },
            });
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `spike_${clip.clip_order.toString()}_${clip.clip_ranking.toString()}_${isMobile ? 'Mobile' : 'Desktop'
              }.mp4`,
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });

        postAudit({
          user_action: EAuditAction.AuditActionDownload,
          clip: clip.id,
        });
      } else {
        message.error({
          content:
            'There was an error downloading the video. Please try again later',
        });
        postAudit({
          user_action: EAuditAction.AuditActionDownload,
          clip: clip.id,
          details: `GET streamer/get-clip-download-url API failed. Details: ${JSON.stringify(
            response,
          )}`,
        });
        console.log('response', response);
      }
    };

    const handleDownloadHorizontal = () => {
      handleDownload();
    };
    const handleDownloadVertical = () => {
      handleDownload(true);
    };

    const handleConfirmDeletion = () => {
      setShowDeleteModal(true);
    };

    // const handleDelete = () => {
    //   handleDeleteClip && handleDeleteClip(clip.id);
    //   setShowDeleteModal(false);
    // };

    const getWatermarkPermission = (
      user: IStreamer | null | undefined,
    ): boolean => {
      if (user == null || user == undefined) {
        return false;
      }

      if (user?.permissions) {
        return user?.permissions.watermark_enabled === false;
      }

      if (user?.subscriptions && user?.subscriptions[0]) {
        return true;
      }

      return false;
    };

    const handleSuccessDirectShare = () => {
      handleCloseDirectShareModal();
      messageApi.success('You video is successfuly uploaded!', 5);
    };

    const handleChangeOrientation = () => {
      if (orientation === 'mobile') {
        setOrientation('desktop');
      } else {
        setOrientation('mobile');
      }
    };

    const showWatermarkButton = getWatermarkPermission(userContext?.user);

    const isCurrentClipLastEdited = clip?.id === lastEditedClipId;

    const allowHdDownload = () => {
      if (userContext?.user?.permissions) {
        return userContext?.user?.permissions.hd_enabled;
      } else {
        return hasSubscription;
      }
    };

    const handleCopyDownloadLink = () => {
      if (isProjectExpired) {
        messageApi.error('This project is expired.', 5);
        return;
      }
      const downloadLink =
        clip.mobile_download_url ||
        clip.desktop_download_url ||
        clip.download_url;

      if (downloadLink) {
        navigator.clipboard.writeText(downloadLink);

        message.success({
          content: 'Download link copied!',
          style: {
            color: COLORS.BLACK,
          },
        });
      } else {
        messageApi.error('No download link found', 5);
      }
    };

    const downloadUrl =
      orientation === 'desktop'
        ? clip.desktop_download_url || clip.download_url
        : clip.mobile_download_url;

    const handleClickShareClip = async () => {
      if (isProjectExpired) {
        messageApi.error('This project is expired.', 5);
        return;
      }
      completeOnboardingStep('share_download', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);

      if (!allowDirectShare) {
        navigate('/subscriptions');
        return null;
      }
      // Prevent clicking while loading share key
      if (isLoadingShareKey) return null;

      // Fetch social_profile_key if it doesn't exist
      if (!userHasShareKey) {
        setIsLoadingShareKey(true);
        try {
          const response = await axios.post('/streamer/social/create');
          setIsLoadingShareKey(false);

          if (response?.data && response?.data?.social_profile_key) {
            userContext?.setUser(response.data);
            handleDirectShareClick(clip, downloadUrl);
          } else {
            messageApi.error('Failed to get share key for your account', 5);
          }
        } catch (error) {
          setIsLoadingShareKey(false);
          messageApi.error('Failed to get share key for your account', 5);
          console.log('Share key fetch error:', error);
        }
      } else {
        handleDirectShareClick(clip, downloadUrl);
      }
    };

    const dropdownItems: MenuProps['items'] = [
      {
        key: '3',
        label: (
          <div style={{ cursor: 'default' }} className="dropdown-button">
            <span>Download {allowHdDownload() ? 'HD' : '720'}</span>
            <span>{'>'}</span>
          </div>
        ),
        children: [
          {
            key: '3-1',
            label: (
              <div
                onClick={handleDownloadHorizontal}
                className="dropdown-submenu-button"
              >
                <span>Horizontal 16:9</span> <IconResize />
              </div>
            ),
          },
          {
            key: '3-2',
            label: (
              <div
                style={{ display: !clip.mobile_download_url ? 'none' : '' }}
                onClick={handleDownloadVertical}
                className="dropdown-submenu-button"
              >
                <span>Vertical 9:16</span>{' '}
                <IconResize style={{ transform: 'rotate(90deg)' }} />
              </div>
            ),
          },
          {
            key: '3-3',
            label: (
              <div
                onClick={handleCopyDownloadLink}
                style={{ cursor: 'pointer' }}
                className="dropdown-submenu-button"
              >
                <span>Copy Download Link</span>
              </div>
            ),
          },
        ],
      },
    ];

    if (showWatermarkButton) {
      dropdownItems.unshift({
        key: '1',
        label: (
          <div
            onClick={handleRemoveWatermark}
            className="dropdown-button remove-watermark"
          >
            Remove Watermark
          </div>
        ),
      });
    }

    const handleClickAnalytics = () => {
      if (socialType === 'youtube') {
        messageApi.info('Youtube analytics are coming soon', 5);
        return;
      } else if (socialType === 'instagram') {
        messageApi.info('Instagram analytics are coming soon', 5);
        return;
      } else if (socialType === 'pinterest') {
        messageApi.info('Pinterest analytics are coming soon', 5);
        return;
      } else if (socialType === 'facebook') {
        messageApi.info('Facebook analytics are coming soon', 5);
        return;
      } else {
        setDrawerOpen((prev) => !prev);
      }
    };

    const handleChangeNewScheduleDate = (date: any) => {
      if (date) {
        setNewScheduleDate(date);
      }
    };

    const handleConfirmReschedule = async () => {
      if (newScheduleDate) {
        const newFormattedDate = convertToISO(newScheduleDate);
        const payload = {
          clip_id: clip.id,
          post_id: socialPost?.id,
          scheduleDate: newFormattedDate,
        };
        setLoadingReschedule(true);
        try {
          const response = await axios.put('/streamer/social/post', payload);

          if (response?.data) {
            handleSuccessReschedule && handleSuccessReschedule();
          } else {
            messageApi.error('Failed to re-schedule a cilp', 5);
          }
          setLoadingReschedule(false);
        } catch (error) {
          messageApi.error('Failed to re-schedule a cilp', 5);
          setLoadingReschedule(false);
        }

        setNewScheduleDate(null);
      }
    };

    const handleDismissReschedule = () => {
      setNewScheduleDate(null);
    };

    const handleOpenCancelScheduleModal = () => {
      setShowScheduleCancelModal(true);
    };

    const handleCloseCancelScheduleModal = () => {
      setShowScheduleCancelModal(false);
    };

    const handleConfirmCancelSchedule = async () => {
      setLoadingCancelPost(true);
      const payload = {
        clip_id: clip.id,
        post_id: socialPost?.id,
      };
      try {
        const response = await axios.delete('/streamer/social/post', {
          data: payload,
        });

        if (response?.data) {
          handleSuccessReschedule && handleSuccessReschedule(true);
        } else {
          messageApi.error('Failed to cancel scheduled clip', 5);
        }
        setLoadingCancelPost(false);
      } catch (error) {
        messageApi.error('Failed to cancel scheduled clip', 5);
        setLoadingCancelPost(false);
      }

      handleCloseCancelScheduleModal();
      postsRefetch && postsRefetch();
    };

    const HashtagsBlock = () => (
      <>
        {isYoutubeClip && (getHashtags() || clip?.description) && (
          <div className="hashtags-container">
            {/* {clip?.headline && <div style={{ display: 'flex', alignItems: 'center', marginTop: 7 }}>
            <Typography style={styles.hashtagsHeading}>Headline:&nbsp;<Typography style={styles.hashtagsText}>{clip?.headline}</Typography><IconCopySVG onClick={copyHeadline} className="cursor-pointer" width={16} height={16} style={{ marginLeft: 8 }} /></Typography>
          </div>
          } */}
            {clip?.description && (
              <div style={{ display: 'flex' }}>
                <div>
                  <Typography style={styles.hashtagsHeading}>
                    Description
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={styles.hashtagsText}>
                      {clip?.description} &nbsp;{' '}
                      <IconCopySVG
                        onClick={copyDescription}
                        className="cursor-pointer"
                        width={12}
                        height={12}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            )}
            {newScheduleDate ? (
              <div className="confirm-schedule-box">
                <span>Confirm new schedule?</span>
                <div className="confirm-buttons-group">
                  <div
                    className="confirm-schedule-btn"
                    onClick={
                      loadingReschedule ? () => null : handleConfirmReschedule
                    }
                  >
                    {loadingReschedule ? 'Loading...' : 'Yes, re-schedule'}
                  </div>
                  <div
                    className="confirm-schedule-btn red"
                    onClick={handleDismissReschedule}
                  >
                    Dismiss
                  </div>
                </div>
              </div>
            ) : (
              <>
                {getHashtags() && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={styles.hashtagsHeading}>
                      Tags{' '}
                      <IconCopySVG
                        style={{ marginLeft: 10 }}
                        onClick={copyHashtags}
                        className="cursor-pointer"
                        width={12}
                        height={12}
                      />
                    </Typography>
                    <div className="hashtags-block">
                      {getHashtags()?.map(
                        (tag: any) =>
                          tag && <div className="hashtag-pill">{tag}</div>,
                      )}
                    </div>
                    {/* <div style={{ display: 'flex', alignItems: 'center', }}>
                <Typography style={styles.hashtagsText}>{getHashtags().join(' ')} &nbsp;  <IconCopySVG onClick={copyDescription} className="cursor-pointer" width={12} height={12} /></Typography>
              </div> */}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );

    if (
      !isNumber(clip.webcam_x_coordinate) &&
      (location.pathname.includes('/spikes/streams') ||
        location.pathname.includes('/spikes/transcribe')) &&
      orientation === 'mobile'
    ) {
      return null;
    }

    const renderTagsFromSocialPosts = (posts: ISocialPost[]) => {
      const postInstatlyPosted = posts.find(
        (post) => !isISOAfterCurrentDate(post.post?.scheduleDate),
      );
      const scheduledPost = posts.find((post) =>
        isISOAfterCurrentDate(post.post?.scheduleDate),
      );

      return (
        <>
          {postInstatlyPosted && (
            <div className="new-video-pill clip-card-edited-pill">Posted</div>
          )}
          {scheduledPost && (
            <div className="clip-card-edited-pill new-video-pill schedule">
              <span>Scheduled</span>
            </div>
          )}
        </>
      );
    };

    return (
      <div
        className={`clip-card-wrapper ${isCurrentClipLastEdited && !analyticsMode && 'gradient-border'
          }`}
      >
        {contextHolder}

        <Modal
          isOpen={showScheduleCancelModal}
          className="Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleCloseCancelScheduleModal}
        >
          <div style={{ width: 430 }} className="glass-modal">
            <span
              onClick={handleCloseCancelScheduleModal}
              className="icon-close"
            >
              X
            </span>
            <h1>Cancel Scheduled Post?</h1>
            <p>Are you sure you would like to cancel your scheduled post?</p>

            <div className="buttons">
              <div
                onClick={handleCloseCancelScheduleModal}
                style={{ minWidth: 120 }}
                className="cancel-btn"
              >
                Leave it
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                onClick={
                  loadingCancelPost ? () => null : handleConfirmCancelSchedule
                }
                className="red-btn"
              >
                {loadingCancelPost && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 22, color: '#fff' }}
                        spin
                      />
                    }
                  />
                )}
                <span>Yes, I&apos;m sure</span>
              </div>
            </div>
          </div>
        </Modal>
        {analyticsMode && (
          <div className="clip-card__social-tag">
            {renderSocialTag(socialType)}
          </div>
        )}

        {choiceReason && (
          <Tooltip overlayClassName="tooltip" title={choiceReason}>
            <div className="clip__choice-reason">
              <IconMagicWand />
            </div>
          </Tooltip>
        )}
        <div
          className={`clip-card ${orientation === 'mobile' ? 'mobile' : 'desktop'
            }`}
        >
          <div className="first-column" style={{ position: 'relative' }}>
            {clip.status === 'in progress' ||
              !checkMobileClipRenderAvailability() ? (
              <div
                style={{
                  ...styles.loaderContainer,
                  height: orientation === 'mobile' ? 401 : 206,
                  width: '100%',
                }}
              >
                {/* <Image src={Loader} preview={false} width={70} /> */}
                <div
                  className="lottie-loader-container"
                  ref={lottieRef}
                  style={{ width: 120, height: 120 }}
                ></div>
                {/* <Typography style={{ marginTop: 10, fontWeight: 600, fontSize: 12, textAlign: 'center' }}>{LOADER_TEXT[loaderTextId]}</Typography> */}
              </div>
            ) : (
              <>
                <div className="clip-tags">
                  {!analyticsMode && (
                    <>
                      {clip?.is_manual_edit ? (
                        <div className="edited-video-pill clip-card-edited-pill">
                          Edited
                        </div>
                      ) : (
                        <div className="clip-card-edited-pill new-video-pill">
                          New
                        </div>
                      )}
                    </>
                  )}

                  {analyticsMode ? (
                    <>
                      {isPostedClip ? (
                        <div className=" new-video-pill clip-card-edited-pill">
                          Posted
                        </div>
                      ) : (
                        <div className="clip-card-edited-pill new-video-pill schedule">
                          <span>Scheduled</span>
                          <span>
                            {formatISODateToShortReadable(
                              socialPost?.post?.scheduleDate || '',
                            )}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {clip.social_posts &&
                        clip.social_posts.length > 0 &&
                        renderTagsFromSocialPosts(clip.social_posts)}
                    </>
                  )}
                </div>

                {/* <div className="clip-card__player-overlay" /> */}
                {orientation === 'mobile' ? (
                  <Player
                    isProjectExpired={isProjectExpired}
                    ref={playerRefMobile}
                    isVisible
                    url={clip.mobile_download_url!}
                    thumbnailUrl={
                      clip.mobile_thumbnail_url ||
                      clip.thumbnail_url ||
                      THUMBNAIL_PLACEHOLDER
                    }
                    height={'100%'}
                    width={'100%'}
                  />
                ) : (
                  <PlayerDesktop clip={clip} isMobile={isMobile} isProjectExpired={isProjectExpired} />
                )}

                {clip.mobile_download_url && !analyticsMode && (
                  <div
                    className={`clip-orientation-switch ${analyticsMode && 'bottom'
                      }`}
                  >
                    <Switch
                      className="orientation-switch-button"
                      checkedChildren={<IconDesktop />}
                      unCheckedChildren={<IconMobile />}
                      checked={orientation === 'mobile'}
                      onChange={handleChangeOrientation}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="second-column">
            <div className="clip-top-info-desktop">
              <div className="clip-top-info-desktop__left">
                {clip.status === 'failed' &&
                  <div style={{ marginBottom: '-7px' }} className="clip__error-label">
                    <IconWarning />
                    <span>Your last edit didn&apos;t go through. Please try again</span>
                  </div>
                }
                <div className={`rate ${choiceReason && 'space-right'}`}>
                  <Progress
                    type="circle"
                    percent={renderScore()}
                    trailColor={'#1D1D1D'}
                    strokeColor={twoColors}
                    format={(percent) => `${percent}`}
                  />
                  {/* <Tooltip  title={renderScoreTooltip()}></Tooltip> */}
                  {(clip?.headline || clip?.name) &&
                    !clip?.headline?.includes('default_clip') &&
                    !clip?.name?.includes('default_clip') ? (
                    <Tooltip
                      overlayClassName="tooltip"
                      title={clip?.headline || clip?.name}
                    >
                      <span onClick={copyTitle} className="clip-title">
                        {clip?.headline || clip?.name}
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      overlayClassName="tooltip"
                      title={
                        clip?.headline?.includes('default_clip') ||
                          clip?.name?.includes('default_clip')
                          ? 'This video may include profanity, there'
                          : streamTitle
                      }
                    >
                      <span onClick={copyTitle} className="clip-title">
                        #{clipNumber} {streamTitle}
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div>
                  <div onClick={handleCopyTimestamp} className="timestamp">
                    <span>
                      {secondsToTime(clip.start_time)} -{' '}
                      {secondsToTime(clip.end_time)}
                    </span>
                    <IconCopy />
                    <div style={styles.smallDivider} />
                    <span>{renderDuration()}</span>
                  </div>
                </div>
              </div>

              {/* <div onClick={handleConfirmDeletion} className="clip-top-info-desktop__right">
                <IconBin className="bin-icon" />
              </div> */}
            </div>
            <HashtagsBlock />
            {clip.status !== 'in progress' && (
              <div className="clip-card-controls">
                {/* <DeleteClipModal open={showDeleteModal} setOpen={setShowDeleteModal} onDeleteClip={handleDelete} /> */}
                <BlockedEditorModal
                  open={showBlockedEditorModal}
                  onClose={() => setShowBlockedEditorModal(false)}
                />
                {analyticsMode ? (
                  <>
                    {isPostedClip ? (
                      <div
                        onClick={handleClickAnalytics}
                        style={{ flex: 1 }}
                        className="control-button schedule-button"
                      >
                        Analytics
                      </div>
                    ) : (
                      <>
                        <div
                          style={{ flex: 1, position: 'relative' }}
                          className="control-button schedule-button"
                        >
                          {!loadingReschedule && (
                            <DtPicker
                              autoClose={false}
                              inputClass="tiktok-date__input"
                              onChange={handleChangeNewScheduleDate}
                              withTime
                            />
                          )}
                          {newScheduleDate ? (
                            renderScheduleDate(newScheduleDate)
                          ) : (
                            <>
                              <ShareAltOutlined
                                style={{ color: '#000', marginRight: '5px' }}
                              />{' '}
                              Change Scheduling
                            </>
                          )}
                        </div>
                        <div
                          onClick={handleOpenCancelScheduleModal}
                          className="control-button cancel-button"
                        >
                          <IconCancel />
                        </div>
                      </>
                    )}
                    <Dropdown
                      overlayClassName="download-dropdown"
                      menu={{ items: dropdownItems }}
                      placement="bottom"
                      trigger={['click']}
                    >
                      <div
                        className={`control-button download-button ${downloading && 'loading'
                          }`}
                      >
                        {downloading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 14, color: '#fff' }}
                                spin
                              />
                            }
                          />
                        ) : (
                          <IconDownload />
                        )}
                      </div>
                    </Dropdown>
                  </>
                ) : (
                  <>
                    <div
                      onClick={handleClickShareClip}
                      className="control-button schedule-button"
                    >
                      {isLoadingShareKey ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 14,
                                color: '#000',
                                marginRight: '5px',
                              }}
                              spin
                            />
                          }
                        />
                      ) : (
                        <ShareAltOutlined
                          style={{ color: '#000', marginRight: '5px' }}
                        />
                      )}{' '}
                      Share Clip
                    </div>
                    {/* <ShareModal isOpen={showDirectShareModal} handleCloseModal={handleCloseDirectShareModal} videoUrl={downloadUrl} handleSuccess={handleSuccessDirectShare} clip={clip} /> */}
                    {isMobile ? (
                      <div className="mobile-row">
                        <Dropdown
                          overlayClassName="download-dropdown"
                          menu={{ items: dropdownItems }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <div
                            className={`control-button download-button ${downloading && 'loading'
                              }`}
                          >
                            {downloading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 14, color: '#fff' }}
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <>
                                {' '}
                                <IconDownload
                                  style={{ marginRight: '5px' }}
                                />{' '}
                                Download
                              </>
                            )}
                          </div>
                        </Dropdown>
                        {!isApiCreatedStream && (
                          <div
                            onClick={handleEdit}
                            className="control-button edit-button"
                          >
                            <IconEdit style={{ marginRight: '5px' }} /> Edit
                            Video
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {!isApiCreatedStream && (
                          <div
                            onClick={handleEdit}
                            className="control-button edit-button"
                          >
                            <IconEdit style={{ marginRight: '5px' }} /> Edit
                            Video
                          </div>
                        )}
                        <Dropdown
                          overlayClassName="download-dropdown"
                          menu={{ items: dropdownItems }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <div
                            className={`control-button download-button ${downloading && 'loading'
                              }`}
                          >
                            {downloading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 14, color: '#fff' }}
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <IconDownload />
                            )}
                          </div>
                        </Dropdown>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <AnalyticsDrawer
          isOpen={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
          width="306px"
          socialPost={socialPost}
          duration={renderDuration()}
        />
      </div>
    );
  },
);

const PlayerDesktop = ({
  clip,
  isMobile,
  ...other
}: any) => {
  const playerRefDesktop = useRef(null);

  return (
    <Player
      ref={playerRefDesktop}
      url={clip.desktop_download_url || clip.url}
      thumbnailUrl={clip.thumbnail_url || THUMBNAIL_PLACEHOLDER}
      playingVideoScope={[
        clip.start_time_in_event == null ? null : clip.start_time_in_event,
        clip.end_time_in_event == null ? null : clip.end_time_in_event,
      ]}
      isVisible
      height={isMobile ? '100%' : 'auto'}
      width={isMobile ? 'auto' : '100%'}
      {...other}
    />
  );
};

ClipCard.displayName = 'ClipCard';

export default ClipCard;
