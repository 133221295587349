import { Col, Modal, Row, Space, Button, Typography } from 'antd';
import { useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';

export const DeleteClipModal = ({ open, setOpen, onDeleteClip }: { open: boolean; setOpen: (isOpen: boolean) => void; onDeleteClip: () => void; }) => {

  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 24,
        overflow: 'hidden',
        border: '1px solid #FFF',
        borderColor: COLORS.PRIMARY,
      },
      modalBody: {
        background: '#001922',
        padding: 40,
      },
      checkboxStyle: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 0
      }
    } as const;
  }, []);


  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      closable={false}
      maskClosable={false}
      width={600}
      centered
    >
      <Row gutter={24}>
        <Col span={24} className='flex-row space-between w-100' >
          <div>
            <Title level={3} style={{ marginTop: 20, fontFamily: 'Poppins', textAlign: 'center' }}>Remove the Clip</Title>
            <Typography style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 300, textAlign: 'center', marginTop: 10 }} className='uppercase'>Are you sure you&apos;d like to remove the clip?</Typography>
          </div>
          <Space align="center">
            <Button type="primary" size='middle' onClick={onClose} style={{ marginTop: 30 }}>Keep Clip</Button>
            <Button type="primary" size='middle' onClick={onDeleteClip} style={{ marginTop: 30 }}>Remove Clip</Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteClipModal;