import React from 'react';
import { Typography, Row, Col, Layout } from 'antd';
import './GameDevelopers.less';
import TemplateBanner from './../../assets/template-banner1.png';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';

const GameDevelopersPage: React.FC = () => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: '100vh',
        background: '#080808',
      }}
    >
      <HeaderResponsive />
      <Content className="game-developers-page">
        <div className="game-developers-header">
          <Title level={2}>
            Amplify Your Games Social Media Presence with SBP
          </Title>
        </div>

        <div className="game-developers-section">
          <Title level={3}>Introduction:</Title>
          <Paragraph>
            Attention, game developers! Are you ready to take your social media
            presence to the next level and attract a wider audience to your
            game? Look no further than SBP (Social Brand Promotion) by Spikes.
            Our personalized gameplay videos and promotional services drive
            engagement and elevate your brands visibility.
          </Paragraph>
        </div>

        <div className="game-developers-section">
          <Title level={3}>Case Story:</Title>
          <Paragraph>
            Introducing SBP, the game developers secret weapon for social media
            success. Our customized gameplay videos captivate your audience and
            leave a lasting impression, while our promotional services ensure
            your brand stands out from the crowd.
          </Paragraph>
        </div>
        <div className="game-developers-section">
          <Paragraph>
            SBP empowers you to connect with your audience on a deeper level
            through compelling content. With full control over what you share,
            our AI-powered software selects the most engaging moments from your
            gameplay, highlighting what resonates best with your target
            audience.
          </Paragraph>
        </div>
        <div className="game-developers-section">
          <Paragraph>
            Let SBP be your partner in increasing your user base and raising
            awareness of your game through social media. Our personalized
            approach fosters engagement and attracts a wider audience,
            propelling your game to new heights of popularity.
          </Paragraph>
        </div>
        <div className="game-developers-section">
          <Paragraph>
            Ready to amplify your games social media presence with SBP? Contact
            us today and unlock the potential of personalized gameplay videos
            that drive engagement and expand your user base.
          </Paragraph>
        </div>
        <div className="game-developers-section">
          <Row justify="center">
            <Col xs={24} sm={24} md={24}>
              <div className="image-container">
                <img
                  src={TemplateBanner}
                  alt="game-developers us"
                  className="game-developers-image"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="game-developers-section">
          <Title level={3}>Thank You</Title>
          <Paragraph>
            Big thanks to all the creators and customers who have ve joined us
            on this journey. We appreciate your investment in Spikes. Your
            support fuels our commitment to keep serving you better. Here is to
            creating more, achieving more, and growing together!
          </Paragraph>
        </div>
      </Content>
      <FooterResponsive />
    </Layout>
  );
};

export default GameDevelopersPage;
