import React, { useEffect, useRef, useState } from 'react';
import './AnalyticsDrawer.less';
import {
  IFacebookAnalytics,
  IInstagramAnalytics,
  ILinkedInAnalytics,
  IPinterestAnalytics,
  IRedditAnalytics,
  ISocialPost,
  ITiktokAnalytics,
  IYoutubeAnalytics,
} from '../../data/intefaces/stream.interface';
import { ReactComponent as IconArrowRight } from '../../assets/double_right.svg';
import { ReactComponent as IconRefresh } from '../../assets/refresh.svg';
import { ReactComponent as IconLink } from '../../assets/link.svg';
import { ReactComponent as IconWarning } from '../../assets/warning.svg';
import axios from 'axios';
import { renderSocialTag } from '../upload-card/ClipCard/ClipCard';

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  width?: string;
  socialPost?: ISocialPost;
  duration: string;
};

function formatDuration(seconds: number): string {
  const totalSeconds = Math.floor(seconds); // Rounding down to the nearest second

  const hrs = Math.floor(totalSeconds / 3600);
  const mins = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;

  const parts: string[] = [];

  if (hrs > 0) {
    parts.push(`${hrs} hour${hrs !== 1 ? 's' : ''}`);
  }
  if (mins > 0) {
    parts.push(`${mins} minute${mins !== 1 ? 's' : ''}`);
  }
  if (secs > 0) {
    parts.push(`${secs} second${secs !== 1 ? 's' : ''}`);
  }

  return parts.join(', ');
}

function formatReadableDate(dateString: string): string {
  const date = new Date(dateString);

  // Format options for displaying the date without the timezone name
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long', // e.g., August
    day: 'numeric', // e.g., 14
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return date.toLocaleString(undefined, options);
}

const AnalyticsDrawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  width = '250px',
  socialPost,
  duration,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [requestTime, setRequestTime] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const socialType =
    socialPost?.post?.platforms &&
    socialPost?.post?.platforms.length > 0 &&
    socialPost?.post?.platforms[0];

  useEffect(() => {
    if (socialPost) {
      fetchDetails(socialPost?.post_id);
    }
  }, []);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  const refetchDetails = () => {
    if (loading) return;
    if (socialPost) {
      fetchDetails(socialPost?.post_id);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const fetchDetails = async (postId: string) => {
    setLoading(true);
    const response = await axios
      .put('/streamer/social-analytics', {
        post: postId,
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });

    if (response?.status === 200) {
      const currentTime = new Date().toLocaleString();
      setRequestTime(currentTime);

      if (response.data && response.data.length > 0) {
        setAnalyticsData(response.data[0]);
      }
    }

    setLoading(false);
  };

  const renderDetails = () => {
    if (socialType === 'youtube' && analyticsData) {
      return (
        <YoutubeDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else if (socialType === 'tiktok' && analyticsData) {
      return (
        <TiktokDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else if (socialType === 'pinterest' && analyticsData) {
      return (
        <PinterestDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else if (socialType === 'instagram' && analyticsData) {
      return (
        <InstagramDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else if (socialType === 'facebook' && analyticsData) {
      return (
        <FacebookDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else if (socialType === 'linkedin' && analyticsData) {
      return (
        <LinkedinDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else if (socialType === 'reddit' && analyticsData) {
      return (
        <RedditDetails
          details={analyticsData}
          socialPost={socialPost}
          duration={duration}
        />
      );
    } else {
      return null;
    }
  };

  const drawerStyle: React.CSSProperties = {
    width: width,
    transform: isVisible ? 'translateX(0)' : `translateX(500px)`,
  };

  if (!socialPost) return null;

  return (
    <div className="analytics-drawer" ref={drawerRef} style={drawerStyle}>
      <div onClick={handleClose} className="analytics-drawer__close-btn">
        <IconArrowRight />
      </div>
      <div className="analytics-drawer__container">
        <div className="analytics-drawer__header">
          <div className="analytics-drawer__header-title">
            <div className="analytics-drawer__title">Analytics</div>
            {renderSocialTag(
              socialPost?.post?.platforms &&
              socialPost?.post?.platforms.length > 0 &&
              socialPost?.post?.platforms[0],
            )}
          </div>

          <IconRefresh
            className={`refresh-icon ${loading ? 'loading' : ''}`}
            onClick={refetchDetails}
          />
        </div>
        <div className="analytics-drawer__divider" />
        {requestTime && (
          <p className="analytics-drawer__refresh-text">
            Last update: {requestTime}
          </p>
        )}

        {renderDetails()}
      </div>
    </div>
  );
};

const DetailItem = ({ title, value }: { title: string; value: any }) => {
  if (value === null || value === undefined) return null;
  return (
    <div className="analytics-drawer__details-item">
      <div className="analytics-drawer__details-item-title">{title}:</div>
      <div className="analytics-drawer__details-item-value">{value}</div>
    </div>
  );
};

const YoutubeDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: IYoutubeAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.youtube?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      {/* <DetailItem
        title="Description"
        value={details.youtube.analytics.description}
      /> */}
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />
      {details.youtube.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a href={details.youtube.postUrl} target="_blank" rel="noreferrer">
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem title="Views" value={details.youtube.analytics.views} />
      <DetailItem title="Likes" value={details.youtube.analytics.likes} />
      <DetailItem title="Comments" value={details.youtube.analytics.comments} />

      <div className="analytics-drawer__divider" />

      <DetailItem
        title="Avarage View Duration"
        value={details.youtube.analytics.averageViewDuration}
      />
      <DetailItem
        title="Avarage View Rate"
        value={details.youtube.analytics.averageViewPercentage}
      />
      <DetailItem title="Shares" value={details.youtube.analytics.shares} />

      <div className="analytics-drawer__divider" />

      <DetailItem
        title="Subscribers Gained"
        value={details.youtube.analytics.subscribersGained}
      />
      <DetailItem
        title="Subscribers Lost"
        value={details.youtube.analytics.subscribersLost}
      />

      <div className="analytics-drawer__divider" />

      <div className="disclaimer">
        <IconWarning />
        <span>
          YouTube can take up to 24 hours to process analytics for videos with
          fewer views.
        </span>
      </div>
    </div>
  );
};

const TiktokDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: ITiktokAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.tiktok?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />

      {details.tiktok.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a href={details.tiktok.postUrl} target="_blank" rel="noreferrer">
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem title="Views" value={details.tiktok.analytics.videoViews} />
      <DetailItem title="Likes" value={details.tiktok.analytics.likeCount} />
      <DetailItem title="Comments" value={details.tiktok.analytics.comments} />

      <div className="analytics-drawer__divider" />

      <DetailItem title="Shares" value={details.tiktok.analytics.shareCount} />
      {/* <DetailItem
        title="Full Watch Rate"
        value={details.tiktok.analytics.fullVideoWatchedRate}
      /> */}
      {/* <DetailItem
        title="Avarage View Duration"
        value={details.tiktok.analytics.averageTimeWatched}
      />
      <DetailItem
        title="Avarage View Rate"
        value={details.tiktok.analytics.fullVideoWatchedRate}
      /> */}
    </div>
  );
};

const PinterestDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: IPinterestAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.pinterest?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />

      {details.pinterest.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a
              href={details.pinterest.postUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem
        title="Views"
        value={details.pinterest.analytics.videoMrcView}
      />
      <DetailItem
        title="Impressions"
        value={details.pinterest.analytics.impression}
      />

      <div className="analytics-drawer__divider" />

      <DetailItem title="Pins" value={details.pinterest.analytics.pinClick} />
      <DetailItem title="Saves" value={details.pinterest.analytics.save} />
      <DetailItem
        title="Avarage View Duration"
        value={details.pinterest.analytics.videoAvgWatchTime}
      />
    </div>
  );
};

const InstagramDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: IInstagramAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.instagram?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />

      {details.instagram.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a
              href={details.instagram.postUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem
        title="Views"
        value={details.instagram.analytics.videoViews}
      />
      <DetailItem title="Likes" value={details.instagram.analytics.likeCount} />
      <DetailItem
        title="Comments"
        value={details.instagram.analytics.commentsCount}
      />

      <div className="analytics-drawer__divider" />

      <DetailItem
        title="Shares"
        value={details.instagram.analytics.sharesCount}
      />
      <DetailItem
        title="Saved"
        value={details.instagram.analytics.savedCount}
      />
      <DetailItem
        title="Avarage View Duration"
        value={details.instagram.analytics.igReelsAvgWatchTimeCount}
      />
    </div>
  );
};

const FacebookDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: IFacebookAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.facebook?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />

      {details.facebook.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a href={details.facebook.postUrl} target="_blank" rel="noreferrer">
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem title="Views" value={details.facebook.analytics.videoViews} />
      <DetailItem title="Likes" value={details.facebook.analytics.likeCount} />
      <DetailItem
        title="Comments"
        value={details.facebook.analytics.commentCount}
      />

      <div className="analytics-drawer__divider" />

      <DetailItem
        title="Shares"
        value={details.facebook.analytics.sharesCount}
      />
      <DetailItem
        title="Negative Feedback"
        value={details.facebook.analytics.negativeFeedback}
      />
      <DetailItem
        title="Complete Views"
        value={details.facebook.analytics.totalVideoCompleteViews}
      />
      <DetailItem
        title="Avarage View Duration"
        value={details.facebook.analytics.totalVideoAvgTimeWatched}
      />
    </div>
  );
};
const LinkedinDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: ILinkedInAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.linkedin?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />

      {details.linkedin.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a href={details.linkedin.postUrl} target="_blank" rel="noreferrer">
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem title="Views" value={details.linkedin.analytics.videoViews} />
      <DetailItem title="Likes" value={details.linkedin.analytics.likeCount} />
      <DetailItem
        title="Comments"
        value={details.linkedin.analytics.commentCount}
      />
    </div>
  );
};

const RedditDetails = ({
  details,
  socialPost,
  duration,
}: {
  details: IRedditAnalytics;
  socialPost?: ISocialPost;
  duration: string;
}) => {
  if (!details?.reddit?.analytics || !socialPost) {
    return <div>No details</div>;
  }

  return (
    <div className="analytics-drawer__details-list">
      <DetailItem title="Title" value={socialPost.post.post} />
      <DetailItem
        title="Posted"
        value={formatReadableDate(socialPost.post.created)}
      />
      <DetailItem title="Duration" value={duration} />

      <div className="analytics-drawer__divider" />

      {details.reddit.postUrl && (
        <DetailItem
          title="Video URL"
          value={
            <a href={details.reddit.postUrl} target="_blank" rel="noreferrer">
              <div className="analytics-drawer__post-url-btn">
                <IconLink />
              </div>
            </a>
          }
        />
      )}
      <DetailItem title="Likes" value={details.reddit.analytics.ups} />
      <DetailItem
        title="Subreddit"
        value={details.reddit.analytics.subreddit}
      />
    </div>
  );
};

export default AnalyticsDrawer;
