import React, { useState, useMemo, useCallback, useEffect, createRef } from 'react';
import { Carousel, Image, Slider, message } from 'antd';
import axios from 'axios';
import { ReactComponent as BinIcon } from '../../../assets/red_bin.svg';
import './TextInsertTab.less';
import TextArea from 'antd/lib/input/TextArea';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TwitterPicker } from 'react-color';
import { COLORS } from '../../../themes/colors';
import ColorPickerIcon from '../../../assets/color-picker.svg';
import { ReactComponent as IconCollapse } from '../../../assets/collapse-icon.svg';
import { ReactComponent as IconArrowLeft } from '../../../assets/caret_left.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/caret_right.svg';
import { ReactComponent as DropdownIndicatorIcon } from '../../../assets/dropdown-indicator-small.svg';
import { ReactComponent as ColorWheelIcon } from '../../../assets/color-wheel.svg';
import { ReactComponent as BlockIcon } from '../../../assets/block-icon.svg';
import { SketchPicker } from 'react-color';
import UppercaseIcon from '../../../assets/uppercase-icon.svg';
import BoldIcon from '../../../assets/bold-icon.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import StrokeLetterIcon from '../../../assets/stroke-letter.svg';
import { editorSubtitleColors } from '../../../constants/content-constants';
import { Collapse } from 'react-collapse';
import { SliderMarks } from 'antd/lib/slider';
import { useTraceUpdate } from '../../../utils';
import { useFocus } from '../Editor.page';
import Select, { DropdownIndicatorProps, components } from 'react-select';

type TextTransform = 'uppercase' | 'lowercase' | 'none' | 'capitalize' | 'initial' | 'inherit';

const TEMPLATES_LIST = [
  {
    name: 'Default',
    style: {
      background: '#000000',
      color: '#FFFFFF',
      fontFamily: 'Impact',
      fontWeight: 'normal',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Heavy',
      fontSize: 'Large',
    },
    isActive: true
  },
  {
    name: 'RedAlrt',
    style: {
      background: '#F44336',
      color: '#FFEB3B',
      fontFamily: 'Oswald',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'Sneakers',
    style: {
      background: '#673AB7',
      color: '#FFEB3B',
      fontFamily: 'Bebas neue',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Heavy',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'Ruby',
    style: {
      background: '#000000',
      color: '#E0115F',
      fontFamily: 'Arial',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Heavy',
      fontSize: 'Large',
    },
    isActive: true
  },
  {
    name: 'Emerald',
    style: {
      background: '#284828',
      color: '#04F827',
      fontFamily: 'Georgia',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Huge',
    },
    isActive: true
  },
  {
    name: 'NghtVlt',
    style: {
      background: '#673AB7',
      color: '#FFFFFF',
      fontFamily: 'Kanit',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'Silver',
    style: {
      background: '#C0C0C0',
      color: '#222222',
      fontFamily: 'Times New Roman',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium',
    },
    isActive: true
  },
  {
    name: 'Sunshin',
    style: {
      background: '#FFC107',
      color: '#2196F3',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'Mono',
    style: {
      background: '#FFFFFF',
      color: '#000000',
      fontFamily: 'Arial',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'SkyHigh',
    style: {
      background: '#2196F3',
      color: '#FFFFFF',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'Tangeri',
    style: {
      background: '#FF9800',
      color: '#000000',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'GrnGlow',
    style: {
      background: '#4CAF50',
      color: '#FFFFFF',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Huge'
    }
  },
  {
    name: 'SpcShad',
    style: {
      background: '#FF5722',
      color: '#FFFFFF',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'BlBliss',
    style: {
      background: '#03A9F4',
      color: '#FFFFFF',
      fontFamily: 'Work Sans',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'GldGlow',
    style: {
      background: '#FFC107',
      color: '#FFFFFF',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium'
    },
    isActive: true
  },
  {
    name: 'MidnightBlue',
    style: {
      background: '#003366',
      color: '#FFFFFF',
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Huge',
    },
    isActive: true
  },
  {
    name: 'RoseGold',
    style: {
      background: '#B76E79',
      color: '#FFFFFF',
      fontFamily: 'Serif',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Light',
      fontSize: 'Huge',
    },
    isActive: true
  },
  {
    name: 'OceanWave',
    style: {
      background: '#00BFFF',
      color: '#f0f8ff',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium',
    },
    isActive: true
  },
  {
    name: 'LimeLight',
    style: {
      background: '#00FF00',
      color: '#000000',
      fontFamily: 'Helvetica',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Huge',
    },
    isActive: true
  },
  {
    name: 'VintageCharm',
    style: {
      background: '#F5F5DC',
      color: '#8B4513',
      fontFamily: 'Garamond',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Huge',
    },
    isActive: true
  },
  // {
  //   name: 'Winter',
  //   style: {
  //     background: '#E0FFFF',
  //     color: '#000080',
  //     fontFamily: 'Caprasimo',
  //     fontWeight: 'bold',
  //     textTransform: 'uppercase' as TextTransform,
  //     textShadow: 'Medium',
  //     fontSize: 'Medium',
  //   },
  //   isActive: true
  // },
  {
    name: 'NeonPink',
    style: {
      background: '#FF1493',
      color: '#FFFFFF',
      fontFamily: 'Impact',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium',
    },
    isActive: true
  },
  {
    name: 'DarkForest',
    style: {
      background: '#228B22',
      color: '#F0E68C',
      fontFamily: 'Arial Narrow',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium',
    },
    isActive: true
  },
  {
    name: 'SapphireGlow',
    style: {
      background: '#0F52BA',
      color: '#FFFFFF',
      fontFamily: 'Koulen',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium',
    },
    isActive: true
  },
  {
    name: 'GoldenSunset',
    style: {
      background: '#FFD700',
      color: '#8B0000',
      fontFamily: 'Times New Roman',
      fontWeight: 'bold',
      textTransform: 'uppercase' as TextTransform,
      textShadow: 'Medium',
      fontSize: 'Medium',
    },
    isActive: true
  }

  // Add other box objects with style property...
];


// Available font size options
const fontSizeOptions = [
  { value: 20, label: 'S' },
  { value: 28, label: 'M' },
  { value: 32, label: 'L' },
  { value: 35, label: 'XL' },
];

const fontFamilyOptions = [
  { value: 'Arial', label: 'Arial', icon: 'AliwangwangOutlined' },
  { value: 'Georgia', label: 'Georgia', icon: 'AliwangwangOutlined' },
  {
    value: 'Times New Roman',
    label: 'Times New Roman',
    icon: 'AliwangwangOutlined',
  },
  // { value: 'Verdana', label: 'Verdana', icon: 'AliwangwangOutlined' },
  { value: 'Roboto', label: 'Roboto', icon: 'AliwangwangOutlined' },
  { value: 'Poppins', label: 'Poppins', icon: 'AliwangwangOutlined' },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    icon: 'AliwangwangOutlined',
  },
  {
    value: 'Roboto Condensed',
    label: 'Roboto Condensed',
    icon: 'AliwangwangOutlined',
  },
  { value: 'Open Sans', label: 'Open Sans', icon: 'AliwangwangOutlined' },
  // { value: 'Impact', label: 'Impact', icon: 'AliwangwangOutlined' },
  {
    value: 'Bebas Neue',
    label: 'Bebas Neue',
    icon: 'AliwangwangOutlined',
  },
  {
    value: 'Belanosima',
    label: 'Belanosima',
    icon: 'AliwangwangOutlined',
  },
  { value: 'Caprasimo', label: 'Caprasimo', icon: 'AliwangwangOutlined' },
  {
    value: 'Red Hat Display',
    label: 'Red Hat Display',
    icon: 'AliwangwangOutlined',
  },
  { value: 'Work Sans', label: 'Work Sans', icon: 'AliwangwangOutlined' },
  { value: 'Kanit', label: 'Kanit', icon: 'AliwangwangOutlined' },
  { value: 'Oswald', label: 'Oswald', icon: 'AliwangwangOutlined' },
  { value: 'Koulen', label: 'Koulen', icon: 'AliwangwangOutlined' },
];

const textShadowOptions = [
  { value: 'none', label: 'None', icon: StrokeLetterIcon },
  { value: 'heavy', label: 'Heavy', icon: StrokeLetterIcon },
  { value: 'medium', label: 'Medium', icon: StrokeLetterIcon },
  { value: 'light', label: 'Light', icon: StrokeLetterIcon },
];

const strokeMarks: SliderMarks = {
  0: 'none',
  1: 'medium',
  2: 'heavy',
};

export interface ITextInsertTab {
  textFeatureSliders: any[];
  clipDescription?: string;
  handleAddTextToFeatureSliders: (textValue: string, ratio: number, fontFamily: string, fontColor: string, fontWeight: string | number, textShadow: string, fontSize: number, textTransform: string, textShadowColor: string) => void;
  editFeatureSlider: (featureSliderId: number, updatedFields: any) => void;
  handleDeleteFeatureSlider: (id: number) => void;
}

const TextInsertTab = ({ clipDescription, textFeatureSliders, handleAddTextToFeatureSliders, editFeatureSlider, handleDeleteFeatureSlider }: ITextInsertTab) => {
  const alreadyAddedExampleText = Boolean(localStorage.getItem('shown_text_insert_example'));

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1
      },
      contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    } as const;
  }, []);

  useEffect(() => {
    if (textFeatureSliders.length === 0) {
      if (clipDescription) {
        addDefaultTextSlider(clipDescription);
      } else {
        addDefaultTextSlider();
      }
    }
  }, []);

  const addDefaultTextSlider = (text?: string) => {
    handleAddTextToFeatureSliders(text || '', 1.3, 'Impact', '#FFFFFF', 'normal', 'heavy', 32, 'uppercase', '#000000');
  };

  return (
    <div className="subtitles-wrapper scrollbar" style={styles.container}>
      <div className="text-insert">
        {textFeatureSliders.length > 0 && <div className="text-insert__content">
          {textFeatureSliders.map(slider =>
            <TextInsertItem key={slider.id} slider={slider} editFeatureSlider={editFeatureSlider} handleDeleteSlider={handleDeleteFeatureSlider} />
          )}
        </div>}

        <div className="text-insert__add-button" onClick={() => addDefaultTextSlider()}>Add new text</div>
      </div>
    </div>
  );
};

interface ITextInsertItem {
  slider: any;
  editFeatureSlider: (featureSliderId: number, updatedFields: any) => void;
  handleDeleteSlider: (id: number) => void;
}

const TextInsertItem = ({ slider, editFeatureSlider, handleDeleteSlider }: ITextInsertItem) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openStrokeColorPicker, setOpenStrokeColorPicker] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const colorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenColorPicker(false) });
  const strokeColorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenStrokeColorPicker(false) });
  const [inputRef, setInputFocus] = useFocus();

  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);


  const carouselRef = createRef<any>();

  useEffect(() => {
    updateTextRectangleDymensions();
  }, [slider?.textTransform, slider?.textShadow, slider?.fontWeight, slider?.fontSize, slider?.fontFamily]);

  const handleOnInputClick = (e: any) => {
    e.preventDefault();
    inputRef.current.blur();
    setInputFocus();
  }

  const convertNumberToStroke = (number: number) => {
    switch (number) {
      case 0:
        return 'none';
      case 1:
        return 'medium';
      case 2:
        return 'heavy';

      default:
        return 'none';
    }
  };

  const convertStrokeToNumber = (stroke: string) => {
    switch (stroke) {
      case 'none':
        return 0;
      case 'medium':
        return 1;
      case 'heavy':
        return 2;

      default:
        return 0;
    }
  };



  const handleChangeText = (text: string) => {
    if (text.length <= 500) {
      editFeatureSlider(slider.id, { text, featureName: `Text - ${text}` });
      updateTextRectangleDymensions();
    } else {
      messageApi.info('The limit is 500 symbols', 3);
    }
  };

  const updateTextRectangleDymensions = () => {
    setTimeout(() => {
      const desktopTextBox = document.getElementById(`text-desktop-${slider.id}`);
      const mobileTextBox = document.getElementById(`text-mobile-${slider.id}`);

      const desktopHeight = desktopTextBox?.offsetHeight;
      const desktopWidth = desktopTextBox?.offsetWidth;
      const mobileHeight = mobileTextBox?.offsetHeight;
      const mobileWidth = mobileTextBox?.offsetWidth;

      if (desktopHeight && desktopWidth) {
        editFeatureSlider(slider.id, {
          desktopDymension: {
            height: desktopHeight,
            width: desktopWidth + 2,
            ratio: (desktopWidth + 2 / desktopHeight).toFixed(1),
            x: slider.desktopDymension.x,
            y: slider.desktopDymension.y,
          }
        });
      }

      if (mobileHeight && mobileWidth) {
        editFeatureSlider(slider.id, {
          mobileDymension: {
            height: mobileHeight,
            width: mobileWidth + 2,
            ratio: (mobileWidth + 2 / mobileHeight).toFixed(1),
            x: slider.mobileDymension.x,
            y: slider.mobileDymension.y,
          }
        });
      }
    }, 100);
  };

  const handleFontSizeChange = (size: number) => {
    editFeatureSlider(slider.id, { fontSize: size });
  };

  const handleTransformChange = () => {
    if (slider.textTransform === 'uppercase') {
      editFeatureSlider(slider.id, { textTransform: 'lowercase' });
    } else {
      editFeatureSlider(slider.id, { textTransform: 'uppercase' });
    }
  };

  const handleFontWeightChange = () => {
    if (slider.fontWeight === 'bold') {
      editFeatureSlider(slider.id, { fontWeight: 'normal' });
    } else {
      editFeatureSlider(slider.id, { fontWeight: 'bold' });
    }
  };

  const handleFontFamilyChange = (fontFamily: any) => {
    editFeatureSlider(slider.id, { fontFamily });
  };

  const handleOpenColorPicker = useCallback(() => {
    setOpenColorPicker(val => !val);
  }, []);

  const handleChooseColor = useCallback((color: any) => {
    editFeatureSlider(slider.id, { fontColor: color.hex });
  }, []);

  const handleOpenStrokeColorPicker = () => {
    setOpenStrokeColorPicker(open => !open);
    setOpenColorPicker(false);
  };

  const handleChooseStrokeColor = (color: any) => {
    editFeatureSlider(slider.id, { textShadowColor: color.hex });
  };

  const handleStrokeChange = (value: string) => {
    editFeatureSlider(slider.id, { textShadow: value });
  };

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingLeft: '0'
      },
      inputContainer: {
        display: 'flex',
        width: '100%',
        padding: '10px 15px',
        background: '#272836',
        alignItems: 'center',
        gap: 22,
        borderBottom: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        height: 60,
        borderRadius: '4px 4px 0 0'
      },
      filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '0 0 4px 4px'
      },
      fontSizeItemsContainer: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
      },
      filterWrapper: {
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#272836',
        borderRight: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        borderRadius: '0 0 0 4px'
      },
      colorsItem: {
        padding: '10px 12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#272836',
        width: '100%',
        borderRadius: '0 0 4px 0'
      },
      input: {
        background: COLORS.GRAY_CONTROLS,
      },
      clearIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '7px',
        marginRight: '5px',
        cursor: 'pointer',
      },
      timeRange: {
        height: 40,
        padding: 5,
        backgroundColor: COLORS.CONTROL_BUTTON_BACKGROUND,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px'
      },
      controlTopButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: 9,
        position: 'relative',
        marginLeft: 'auto'
      },
      setButton: {
        height: '34px !important',
        maxHeight: '34px !important',
        minHeight: '34px !important',
        fontSize: 12,
        fontWeight: 400
      },
      downloadQualityBox: {
        height: '34px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY,
        cursor: 'pointer',
        position: 'relative',
        padding: '4px 6px'
      },
      downloadQualityText: {
        fontSize: '12px',
        color: COLORS.BACKGROUND_DARK_GRAY,
        fontWeigth: 400,
        whiteSpace: 'nowrap'
      },
      downloadQualityBoxDisabled: {
        backgroundColor: COLORS.GRAY_BACKGROUND,
      },
      disabledClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
      downloadQualityTextDisabled: {
        color: COLORS.GRAY_DISABLED,
      },
      buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
        height: '100%',
        position: 'relative'
      },
      title: {
        fontSize: '10px',
        lineHeight: '15px',
        fontWeight: 400,
        color: COLORS.LIGHT_GRAY_TEXT,
        marginBottom: '10px',
      },
      button: {
        height: '40px',
        minWidth: '40px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none'
      },
      boxContainer: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center',
        padding: '20px',
        height: '100%',
        overflow: 'auto',
      },
      box: {
        color: 'var(--White, #FFF)',
        textAlign: 'center' as const,
        fontFamily: 'Poppins',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '97.846px',
        maxHeight: '98px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        border: '3px solid transparent',
        position: 'relative' as const,
      },
      focusedBox: {
        border: '1px solid #fff'
      },
      notFocused: {
        border: '1px solid transparent',
      },
      smallText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'var(--White, #67FFCC)',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
      inactiveBox: {
        opacity: 0.5, // Make inactive boxes grayed out
        cursor: 'not-allowed', // Make inactive boxes not clickable
      },
      comingSoonText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'orange',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
    } as const;
  }, []);

  const handleCarouselChange = (currentSlide: number) => {
    setIsFirstSlide(currentSlide === 0);
    setIsLastSlide(currentSlide === Math.round(TEMPLATES_LIST.length / 4) - 1);
  };


  const handlePrevSlide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselRef.current.prev();
  };

  const handleNextSlide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselRef.current.next();
  };

  const handleChooseTemplate = (template: typeof TEMPLATES_LIST[0]) => {
    const fontSize = formatFontSizeForTemplate(template.style.fontSize);
    editFeatureSlider(slider.id, { fontSize: fontSize });
    editFeatureSlider(slider.id, { fontColor: template.style.color });
    editFeatureSlider(slider.id, { textShadowColor: template.style.background });
    editFeatureSlider(slider.id, { fontWeight: template.style.fontWeight });
    editFeatureSlider(slider.id, { textTransform: template.style.textTransform });
    editFeatureSlider(slider.id, { textShadow: template.style.textShadow.toLowerCase() });
    editFeatureSlider(slider.id, { fontFamily: template.style.fontFamily });
  }

  const formatFontSizeForTemplate = (size: string) => {
    if (size === 'Small') return 20;
    else if (size === 'Normal') return 20;
    else if (size === 'Medium') return 28;
    else if (size === 'Large') return 32;
    else if (size === 'Huge') return 35;
    else return 28;
  }


  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  const selectedTemplate = TEMPLATES_LIST.find(template => template.style.color === slider?.fontColor && template.style.background === slider?.textShadowColor && template.style.textTransform === slider?.textTransform && template.style.textShadow.toLowerCase() === slider?.textShadow && slider?.textTransform && template.style.fontWeight === slider?.fontWeight);

  return (
    <div key={slider.id} className="text-insert__item">
      {contextHolder}
      <div className="text-insert__top-section">
        <div onClick={() => setIsCollapseOpen(prev => !prev)} className={`text-insert__collapse-button ${isCollapseOpen && 'active'}`}>
          <span>Styling</span>
          <IconCollapse />
        </div>
        <BinIcon className="bin-icon" onClick={() => handleDeleteSlider(slider.id)} />
      </div>
      <Collapse isOpened={isCollapseOpen}>
        <div style={styles.container}>
          <div className="editor-font-design__section">
            <div className="editor-font-design__section-content">
              <p className="editor-font-design__section-title">Font Styling</p>
              <div className="editor-font-design__buttons">
                <div onClick={handleFontWeightChange} className={`editor-font-design__button ${slider.fontWeight === 'bold' && 'active'}`} style={{ fontWeight: 700 }}>B</div>
                <div onClick={handleTransformChange} className={`editor-font-design__button ${slider.textTransform === 'uppercase' && 'active'}`}>AA</div>
                <Select
                  className="font-size-select"
                  classNamePrefix="font-size-select-prefix"
                  value={fontSizeOptions.find(el => el.value === slider.fontSize)}
                  onChange={({ value }: any) => handleFontSizeChange(value)}
                  options={fontSizeOptions}
                  components={{ DropdownIndicator }}
                  placeholder='Fonts'
                />
                <div ref={colorPickerRef} className="editor-font-design__color-button">
                  <div onClick={handleOpenColorPicker} className="editor-font-design__button">
                    <ColorWheelIcon />
                  </div>
                  {openColorPicker &&
                    <div className="editor-font-design__font-colorpicker">
                      <SketchPicker color={slider.fontColor} onChangeComplete={handleChooseColor} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 0 }} className="editor-font-design__section">
            <div className="editor-font-design__section-content">
              <p className="editor-font-design__section-title">Stroke</p>
              <div className="editor-font-design__buttons">
                <div onClick={() => handleStrokeChange('none')} className={`editor-font-design__button ${slider.textShadow === 'none' && 'active'}`}><BlockIcon /></div>
                <div onClick={() => handleStrokeChange('medium')} className={`editor-font-design__button ${slider.textShadow === 'medium' && 'active'}`}>M</div>
                <div onClick={() => handleStrokeChange('heavy')} className={`editor-font-design__button ${slider.textShadow === 'heavy' && 'active'}`}>L</div>
                <div ref={strokeColorPickerRef} className="editor-font-design__color-button">
                  <div onClick={handleOpenStrokeColorPicker} className="editor-font-design__button">
                    <ColorWheelIcon />
                  </div>
                  {openStrokeColorPicker &&
                    <div className="editor-font-design__stroke-colorpicker">
                      <SketchPicker color={slider.textShadowColor} onChangeComplete={handleChooseStrokeColor} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* <div style={styles.inputContainer}>
            <Select
              value={slider.fontFamily}
              onChange={handleFontFamilyChange}
              options={fontFamilyOptions}
            />
            <div style={styles.controlTopButtons}>
              <div
                ref={strokeColorPickerRef}
                className={`subtitles-select-button stroke-width-button ${openStrokeColorPicker ? 'opened-color-picker' : ''}`}
                style={styles.button}
              >
                <div style={{ cursor: 'pointer', position: 'relative' }} onClick={handleOpenStrokeColorPicker}>
                  {textShadowOptions[0].icon && (
                    <Image
                      src={textShadowOptions[0].icon}
                      preview={false}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {openStrokeColorPicker && <TwitterPicker triangle='top-right' color={slider.textShadowColor} onChangeComplete={handleChooseStrokeColor} colors={editorSubtitleColors} />}
                <div className="stroke-slider-background" />
                <Slider value={convertStrokeToNumber(slider.textShadow)} onChange={handleStrokeChange} marks={strokeMarks} max={2} step={1} tooltip={{ open: false }} />
              </div>
            </div>
          </div>
          <div style={styles.filtersContainer}>
            <div style={styles.filterWrapper}>
              <div style={{ ...styles.fontSizeItemsContainer, backgroundColor: '#1E1E29', padding: '4px 5px' }}>
                {fontSizeOptions.map((item) => (
                  <div
                    onClick={() => handleFontSizeChange(item.value)}
                    key={item.value}
                    className={`font-size-item ${item.value === slider.fontSize ? 'font-size-item-selected' : ''}`}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
            <div style={styles.colorsItem}>
              <div className="color-items" style={styles.fontSizeItemsContainer}>
                <div
                  onClick={handleTransformChange}
                  className={`color-item ${slider.textTransform === 'uppercase' && 'active'}`}
                >
                  <Image
                    src={UppercaseIcon}
                    preview={false}
                    className="cursor-pointer"
                  />
                </div>
                <div
                  onClick={handleFontWeightChange}
                  className={`color-item ${slider.fontWeight === 'bold' && 'active'}`}
                >
                  <Image
                    src={BoldIcon}
                    preview={false}
                    className="cursor-pointer"
                  />
                </div>
                <div ref={colorPickerRef}>
                  <div
                    onClick={handleOpenColorPicker}
                    className='color-item'
                    style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY }}
                  >
                    <Image
                      src={ColorPickerIcon}
                      preview={false}
                      className="cursor-pointer"
                    />
                  </div>
                  {openColorPicker && <TwitterPicker triangle='top-right' color={slider.fontColor} onChangeComplete={handleChooseColor} colors={editorSubtitleColors} />}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Collapse>

      <div className='text-insert__carousel-inner' >
        {!isFirstSlide ? <div onClick={handlePrevSlide} className='text-insert__arrow-icon'><IconArrowLeft /></div> : <div style={{ width: 40 }} />}
        <div style={{ maxWidth: '100%', width: '76%' }}>

          <Carousel
            ref={carouselRef}
            dots={false}
            infinite={false}
            afterChange={handleCarouselChange}
          >

            {Array.from({ length: Math.round(TEMPLATES_LIST.length / 4) }, (value, i) => (
              <div key={i}>
                <div style={{ margin: '0 10px' }} className="editor-grid">
                  <>
                    {TEMPLATES_LIST
                      .slice(i * 4, i * 4 + 4)
                      .map((box, index) => (
                        <div
                          id={box.name}
                          key={index}
                          className={`presetBox editor-grid__item ${selectedTemplate?.name === box.name && 'selected'}`}
                          style={{
                            ...styles.box,
                            // ...(activeBox === box.name ? styles.focusedBox : styles.notFocused),
                            ...(box.isActive === false ? styles.inactiveBox : {}),
                            ...box.style, // Apply box style
                            textShadow: `0px 2.34375px 0.0046875px ${box.style.background}, 2.296875px 0.4921875px 0.0046875px ${box.style.background}, 0.984375px -2.1328125px 0.0046875px ${box.style.background}, -1.875px -1.40625px 0.0046875px ${box.style.background}, -1.78125px 1.5234375px 0.0046875px ${box.style.background}, 1.125px 2.0625px 0.0046875px ${box.style.background}, 2.25px -0.65625px 0.0046875px ${box.style.background}, -0.1640625px -2.34375px 0.0046875px ${box.style.background}, -2.3203125px -0.3515625px 0.0046875px ${box.style.background}`,
                          }}
                          onClick={() => handleChooseTemplate(box)}
                        >

                          <span style={{ height: 'auto', userSelect: 'none' }}>{box.name}</span>

                          {box.isActive === false && (
                            <div style={styles.comingSoonText}>Coming Soon</div>
                          )}
                        </div>
                      ))}
                  </>
                </div>
              </div>
            ))}
          </Carousel>

        </div>
        {!isLastSlide && <div onClick={handleNextSlide} className='text-insert__arrow-icon' ><IconArrowRight /></div>}
      </div>


      <div style={{ padding: '0 18px' }}>
        <TextArea
          ref={inputRef}
          onClick={handleOnInputClick}
          className="text-insert-textarea"
          value={slider.text}
          onChange={(e) => handleChangeText(e.target.value)}
          placeholder="Enter your text here"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </div>
    </div>
  );
};

export default TextInsertTab;
