export enum EGeneratedClipType {
  SPIKE = 'spike',
  MANUAL_SPIKE = 'manual spike',
  TWITCH = 'twitch',
  UPLOAD = 'upload',
  TWITCH_UPLOAD = 'upload_twitch',
  MANUAL_COMBINED = 'manual_combined',
  AI_MANUAL_COMBINED = 'AI_manual_combined',
  SPIKE_RAID = 'spike_raid',
  MANUAL_SPIKE_RAID = 'manual_spike_raid',
  MANUAL_COMBINED_RAID = 'manual_combined_raid',
  AI_MANUAL_COMBINED_RAID = 'AI_manual_combined_raid',





}