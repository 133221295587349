/* eslint-disable react/no-unescaped-entities */
import { Fragment, createRef, memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Space from 'antd/lib/space';
import {
  Card,
  Col,
  Divider,
  Image,
  Row,
  Tooltip,
  Typography,
  Button,
  Carousel,
  Spin,
  message,
  Progress,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { COLORS } from '../../themes/colors';
import { SpikeCard } from './spike-card';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';
import dayjs from 'dayjs';
import { secondsToTime } from '../../utils/generic';
import axios from 'axios';
import { globalStyles } from '../../utils/globalStyles';
import { postAudit } from '../../services/audit.service';
import { EAuditAction } from '../../data/intefaces/audit.interface';
import { ESpikeStatus } from '../../data/enums/spike-status.enum';
import { LockedSpike } from './locked-spike';

import Loader from '../../assets/loader.gif';
import IconSpike from '../../assets/spike.svg';
import IconTwitch from '../../assets/icon-twitch.svg';
import IconClock from '../../assets/icon-clock.svg';
import IconCalendar from '../../assets/icon-calendar.svg';
import IconPrev from '../../assets/icon-prev.svg';
import IconNext from '../../assets/icon-next.svg';
import IconPrevTwitchClip from '../../assets/icon-prev-twitch-clip.svg';
import IconNextTwitchClip from '../../assets/icon-next-twitch-clip.svg';
import IconPrevDisabled from '../../assets/icon-prev-disabled.svg';
import IconNextDisabled from '../../assets/icon-next-disabled.svg';
import EmptyChatStream from '../../assets/empty_chat_stream.png';
import IconRefresh from '../../assets/icon-refresh.svg';
import IconRefreshLight from '../../assets/icon-refresh-light.svg';
import IconRefreshDisabled from '../../assets/icon-refresh-disabled.svg';
import IconLightning from '../../assets/lightning-icon.svg';
import IconEye from '../../assets/eye-icon.svg';
import ArrowRight from '../../assets/arrow-right.svg';
import DisabledArrow from '../../assets/disabled-arrow.svg';
import IconGrayStar from '../../assets/gray-star.svg';
import IconEdit from '../../assets/edit-icon.svg';
import { UserContext } from '../../data/userContext';
import { EStreamStatus } from '../../data/enums/stream-status.enum';
import { InviteModal } from '../invite-modal';
import { makeStreamClips } from '../../services/streamer.service';

import './StreamCard.less';
import { SpikeScore } from '../spike-score';
import { SpikeCardNew } from './spike-card-new';
import { useNavigate } from 'react-router';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { EGeneratedClipType } from '../../data/enums/clip-type.enum';
import { Collapse } from 'react-collapse';
import { calculateProgressBar, getTimeRangeBetweenCurrentAndGivenDate, roundUpToNearestMultipleOfThree } from '../../utils';
import { isMobile } from 'react-device-detect';
import { CheckStatusModal } from '../check-status-modal';
import { LoaderFacts } from '../loader-facts';

const CLIPS_PER_PAGE = 3;

const LOADER_TEXT = [
  'Your best moments are on their way',
  'This may take a few moments',
  'Server 1 is watching your Spikes with pleasure',
  'Server 2 is fighting with Server 1 over watching your Spikes',
  'Server 3 is eating popcorn',
];

export interface IStreamCardProps {
  stream: IStream & IVideo;
  isTwitchCard?: boolean;
  index: number;
  tutorialOpen?: boolean;
  handleCloseTour?: () => void;
  isYoutubeCard?: boolean;
  refetch?: () => void;
  withoutDivider?: boolean;
}

export const StreamCard = memo((props: IStreamCardProps) => {

  const { stream, isTwitchCard = false, isYoutubeCard = false, index, tutorialOpen = false, handleCloseTour, refetch, withoutDivider = false } = props;

  const userContext = useContext(UserContext);
  const [page, setPage] = useState<number>(
    stream ? Math.max(Math.ceil(stream.clips.length / CLIPS_PER_PAGE), 1) : 1,
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [loaderTextId, setLoaderTextId] = useState<number>(0);
  const [loadingMakeStreams, setLoadingMakeStreams] = useState<boolean>(false);
  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [showCheckStatusModal, setShowCheckStatusModal] = useState<boolean>(false);
  const carouselRef = createRef<any>();
  const navigate = useNavigate();
  const subscriptions = userContext?.user?.subscriptions;
  const usedStreams = userContext?.user?.used_streams;
  const totalStreams = userContext?.user?.total_streams;
  const USER_NOT_SUBSCRIBED =
    subscriptions == null || subscriptions.length == 0;
  const showProgressBar = isYoutubeCard && stream?.status.toString() !== 'ready'; //(!stream?.clips || stream?.clips?.length === 0);
  const totalTimeToGenerateClips = Math.round(72 + stream.duration / 3) * 2;

  const cardRef = useRef<any>(null);

  const executeScroll = () => cardRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  const roundEstimatedDuration = (durationSeconds: number, perMinutes: number) => {
    const rounded = Math.ceil(durationSeconds / 60 / perMinutes);
    return rounded;
  };

  const getPerMinutes = (durationSeconds: number) => {
    if (durationSeconds <= 899) {
      return 3;
    } else if (durationSeconds <= 3599) {
      return 4;
    } if (durationSeconds <= 8999) {
      return 6;
    } if (durationSeconds <= 14399) {
      return 8;
    } if (durationSeconds <= 1000000) {
      return 10;
    } else {
      return 10;
    }
  };

  const roundedDuration = roundEstimatedDuration(stream.duration, getPerMinutes(stream.duration));
  const estimatedClipsRange = [roundedDuration, roundedDuration * 2]; // 1-2 clips per 3/4/6/8/10 minutes

  const getLoadingClipId = () => {
    const storedData = localStorage.getItem('loadingClipId');
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    if (getLoadingClipId()) {
      const clipId = getLoadingClipId();

      if (stream?.clips?.length > 0) {
        const foundClipIndex = stream?.clips.findIndex(clip => clip?.id === clipId);
        if (foundClipIndex >= 0) {
          executeScroll();
          const carouselSlide = foundClipIndex === 0 ? 0 : Math.ceil((foundClipIndex / 2) - 1);

          carouselRef?.current?.goTo(carouselSlide);
          handleCarouselChange(carouselSlide);
        }
      }
    }
  }, []);


  useEffect(() => {
    if (showProgressBar) {
      const percent = Math.ceil(getTimeRangeBetweenCurrentAndGivenDate(stream.createdAt) * 100 / totalTimeToGenerateClips);
      if (percent < 100) {
        setProgress(percent);
      } else {
        setProgress(99);
      }
    }
  }, [progress, totalTimeToGenerateClips, showProgressBar, Date.now(), stream.createdAt]);

  useEffect(() => {
    setIsReady(
      isTwitchCard
        ? stream.clips.length > 0
        : stream.clips.length >= CLIPS_PER_PAGE,
    );

    stream.clips.length > 0 && loadingMakeStreams ? setLoadingMakeStreams(false) : null;

    // update only when new stream data arrives
    if (stream.clips.length < CLIPS_PER_PAGE) {
      setPage(Math.max(Math.ceil(stream.clips.length / CLIPS_PER_PAGE), 1));
    }
  }, [stream]);


  useEffect(() => {
    if (isReady) return;

    const timeout = setTimeout(
      () => setLoaderTextId((loaderTextId + 1) % LOADER_TEXT.length),
      6500,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [loaderTextId]);

  const makeClipsButtonStyle =
    isMobile ?
      {
        borderLeft: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 15px',
        backgroundColor: COLORS.PRIMARY,
        cursor: 'pointer',
        borderRadius: '0 4px 4px 0'
      }
      :
      {
        cursor: stream.clips_created ? 'not-allowed' : 'pointer',
        backgroundColor: stream.clips_created ? COLORS.DISABLED : COLORS.PRIMARY,
        borderRadius: '0 8px 8px 0',
        height: '100%',
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      };

  const styles = useMemo(() => {
    return {
      streamBarContainer: {
        minWidth: '910px',
        margin: '0 auto',
        borderRadius: '8px',
        backgroundColor: COLORS.GRAY_CONTROLS,
        display: 'flex',
        height: 56,
      },
      streamBarContent: {
        flex: '1',
        display: 'flex',
        padding: '11px 24px',
        justifyContent: 'space-between',
      },
      streamBarDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      makeClipsButton: {
        cursor: 'pointer',
        backgroundColor: stream.clips_created ? COLORS.DISABLED : COLORS.PRIMARY,
        borderRadius: '0 8px 8px 0',
        height: '100%',
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      makeClipsText: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.BACKGROUND_DARK_GRAY,
        marginLeft: '12px',
      },
      viewsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 4
      },
      viewsText: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.WHITE,
        marginRight: 8,
      },
      videoName: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.WHITE,
      },
      timeBox: {
        backgroundColor: COLORS.CONTROL_BUTTON_SELECTED_BACKGROUND,
        padding: '3px 9px',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      timeText: {
        fontSize: '18px',
        fontWeight: 400,
        color: COLORS.WHITE,
      },
      streamDate: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.PRIMARY,
      },
      horizontalDivider: {
        height: 1.5,
        width: 40,
        backgroundColor: COLORS.GRAY_CONTROLS,
        borderRadius: 2,
        margin: '20px auto',
      },
      videosBlock: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      lightningButton: {
        borderLeft: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 15px'
      },
      lightningButtonEnabled: {
        backgroundColor: COLORS.PRIMARY,
        cursor: 'pointer',
        borderRadius: '0 4px 0 0'
      },
      videosContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '24px 24px 0 24px' : '24px 48px 0 48px',
        backgroundColor: COLORS.GRAY_BACKGROUND,
        borderRadius: '0 0 8px 8px',
      },
      progressContainer: {
        maxWidth: isMobile ? '100%' : '910px',
        width: '100%',
        margin: '0 auto',
        padding: '24px 48px',
        borderRadius: '0 0 8px 8px',
        backgroundColor: COLORS.GRAY_BACKGROUND,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        wordWrap: 'break-word',


      },
      carouselControllersContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px',
      },
      verticalDivider: {
        height: 40,
        width: 1.5,
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
        margin: '0 20px',
      },
      videoItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxWidth: 624,
      },
      videoTopInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '13px 24px',
        alignItems: 'center',
        flex: 1,
      },
      videoLengthText: {
        fontSize: '16px',
        fontWeight: 400,
      },
      videoTime: {
        fontSize: '20px',
        fontWeight: 400,
      },
      subscribeBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 0',
      },
      //
      lockedText: {
        fontSize: 24,
        fontWeight: 600,
        width: 1400,
        top: 200,
        position: 'absolute',
        textAlign: 'center',
        marginTop: 10,
      },
      container: {
        borderRadius: stream.status == 'empty chat' ? '35px' : '20px',
        overflow: 'hidden',
      },
      containerBody: {
        backgroundColor: isTwitchCard
          ? '#150137'
          : COLORS.HIGHLIGHT_CARD_BACKGROUND,
        padding: 30,
        position: 'relative',
        height: isTwitchCard ? 'auto' : 437,
      },
      containerBody1: {
        backgroundColor: '#150137',
        padding: 30,
        position: 'relative',
        height: 500,
      },
      label: {
        color: COLORS.LABEL,
        fontSize: 16,
        fontWeight: 200,
      },
      viewers: {
        color: COLORS.PRIMARY,
        fontSize: 18,
        fontWeight: 500,
      },
      divider: {
        borderLeftColor: COLORS.DIVIDER,
        height: 30,
      },
      regenerate: {
        background: isTwitchCard ? 'transparent' : '#102830',
        border: isTwitchCard ? '1px solid #9147FF' : 'none',
        borderRadius: 4,
        padding: 6,
        display: 'flex',
        position: 'absolute',
        top: 22,
        right: 30,
      },
      regenerateButton: {
        background: 'rgba(0, 239, 248, 0.1)',
        borderRadius: 2,
        padding: isTwitchCard ? '4px 8px' : '6px 8px',
        cursor: 'pointer',
      },
      regenerateButtonLeft: {
        background:
          page <= 1
            ? isTwitchCard
              ? 'transparent'
              : '#0D252D'
            : 'rgba(0, 239, 248, 0.1)',
        border: isTwitchCard ? '1px solid #9147FF' : 'none',
      },
      regenerateButtonRight: {
        background:
          page >= (stream ? stream.clips.length : 3) / CLIPS_PER_PAGE
            ? isTwitchCard
              ? 'transparent'
              : '#0D252D'
            : 'rgba(0, 239, 248, 0.1)',
        border: isTwitchCard ? '1px solid #9147FF' : 'none',
      },
      regenerateLeft: {
        fontWeight: 700,
        background:
          stream.remaining_retries > 0 && !isRefreshing
            ? isTwitchCard
              ? '#9147FF'
              : '#0EF0F8'
            : isTwitchCard
              ? 'transparent'
              : '#0D252D',
        borderRadius: 2,
        boxShadow:
          stream.remaining_retries > 0 && !isRefreshing
            ? `0px 0px 10px ${isTwitchCard ? '#9147FF' : '#00EFF8'}`
            : 'none',
        color:
          stream.remaining_retries > 0 && !isRefreshing
            ? COLORS.WHITE
            : '#66757A',
        padding: '6px 13px',
        margin: '0 6px',
        fontSize: 15,
        cursor:
          stream.remaining_retries > 0 && !isRefreshing ? 'pointer' : 'unset',
      },
      left: {
        color:
          stream.remaining_retries > 0 && !isRefreshing
            ? isTwitchCard
              ? COLORS.WHITE
              : COLORS.BLACK
            : '#66757A',
      },
      regenerateIcon: {
        marginTop: -4,
      },
      spikeCardContainer: {
        backgroundColor: 'transparent',
        marginTop: 115,
      },
      stepCardBody: {
        borderRadius: '20px',
        border: '1px solid rgba(0, 239, 248, 0.03)',
        backgroundColor: '#10272f',
      },
      spikeImgOverlay: {
        background:
          'none',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        borderRadius: 11,
      },
      spikeTimeRange: {
        color: COLORS.LABEL,
        fontWeight: 200,
      },
      spikes: {
        visibility: isRefreshing || !isReady ? 'hidden' : 'visible',
      },
      inviteButton: {
        background: isTwitchCard ? '#9147FF' : COLORS.PRIMARY,
        color: isTwitchCard ? COLORS.WHITE : COLORS.BLACK,
        borderColor: isTwitchCard ? '#9147FF' : COLORS.PRIMARY,
        left: 665,
        top: 310,
      },
      loading: {
        cursor: 'not-allowed',
      },
      progressText: {
        marginTop: 10
      },
      arrowStyle: {
        width: 35
      },
      errorWrapper: {},
      estimatedClips: {
        color: '#F2F2F2',
        fontSize: 20
      },
      estimatedRight: {
        color: COLORS.YELLOW
      },
      bold: {
        fontWeight: 700
      },
      estimatedText: {
        fontSize: 14,
        color: COLORS.YELLOW
      },
      checkStatusButton: {
        backgroundColor: COLORS.BLACK,
        color: COLORS.WHITE,
        width: 200,
        marginTop: 20,
        height: 50
      },
    };
  }, [stream.remaining_retries, isRefreshing, page, isReady, isTwitchCard]);

  const firstClip = (page - 1) * CLIPS_PER_PAGE;
  const lastClip = page * CLIPS_PER_PAGE;
  const prevButton = isTwitchCard ? IconPrevTwitchClip : IconPrev;
  const nextButton = isTwitchCard ? IconNextTwitchClip : IconNext;
  const refresh_hover_text = isTwitchCard
    ? 'Generate three more clips'
    : 'Generate three new Spikes from this stream';

  const refresh = async () => {
    if (stream.remaining_retries == 0) return;

    setIsRefreshing(true);
    stream.remaining_retries -= 1;

    const url = isTwitchCard
      ? '/streamer/refresh-twitch-clips'
      : '/streamer/refresh-stream';

    const streamData = await axios
      .put(url, {
        stream: stream.id,
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });

    if (!isTwitchCard && streamData) {
      stream.clips = streamData.data[0].clips;
      setPage(1);
    }

    if (isTwitchCard) {
      const left = userContext?.user?.platform_clips_remaining_refreshes
        ? userContext?.user?.platform_clips_remaining_refreshes - 1
        : 0;
      const user = userContext!.user;
      user!.platform_clips_remaining_refreshes = left;
      userContext?.setUser(user!);
    }

    setIsRefreshing(false);

    postAudit({
      user_action: EAuditAction.AuditActionRefresh,
      stream: stream.id,
    });
  };

  const inviteClicked = () => {
    setShowInviteModal(true);
  };

  const handlePrevSlide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselRef.current.prev();
  };

  const handleNextSlide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselRef.current.next();
  };

  const showClips = stream.status.toString() == 'ready' && stream.clips.length !== 0;

  // stream.status !== EStreamStatus.FAILED &&
  // stream.status !== EStreamStatus.LOCKED &&
  // stream.status !== EStreamStatus.EMPTY_CHAT &&
  // stream.clips.length !== 0;

  const mappedArray = new Array(Math.round(stream.clips.length / 2));

  mappedArray.forEach((item, i) => item = i);

  const makeClipsOnClick = async () => {
    if (tutorialOpen && handleCloseTour) {
      handleShowTutorialInProgress();
      handleCloseTour();
    }
    if (usedStreams && totalStreams && (usedStreams >= totalStreams)) {
      navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum stream limit.' } });
    } else {
      const id = stream.id;
      setLoadingMakeStreams(true);
      const user = await makeStreamClips(id);

      if (!user) {
        setLoadingMakeStreams(false);
        messageApi.warning('Stream is not ready yet! Please try again in a couple of minutes', 5);
      }
      // if (user.message === 'Stream not ready') {
      //   messageApi.warning('Stream is not ready yet! Please try again in a couple of minutes', 5);
      // }
      if (user != null) {
        userContext?.setUser(user);
      }
      //setLoadingMakeStreams(false);
    }
  };

  let disabledMakeClipsButton = false;

  stream.clips.forEach(clip => {
    if (clip.clip_type === EGeneratedClipType.MANUAL_COMBINED || clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD || clip.clip_type === EGeneratedClipType.SPIKE) {
      disabledMakeClipsButton = true;
    };
  });

  const handleCarouselChange = (currentSlide: number) => {
    setIsFirstSlide(currentSlide === 0);
    if (isMobile) {
      setIsLastSlide(currentSlide === Math.round(stream.clips.length) - 1);
    } else {
      setIsLastSlide(currentSlide === Math.round(stream.clips.length / 2) - 1);
    }
  };

  const handleShowTutorialInProgress = () => {
    messageApi.info('If you ever want a refresher, the tutorial is waiting for you in the dropdown. 😊', 5);
  };

  const toggleCollapse = () => {
    setIsOpenCollapse(isOpen => !isOpen);
  };

  const renderLoadingText = (percent: number) => {
    if (percent > 80) {
      return 'Adding some stylish touches';
    } else if (percent > 60) {
      return 'Polishing things up';
    } else if (percent > 40) {
      return 'you will receive an email when the highlights are ready';
    } else if (percent > 20) {
      return 'Transcribing';
    } else return 'Getting your video ready';
  };

  if (stream.status === EStreamStatus.FAILED || stream.status === EStreamStatus.EMPTY_CHAT || stream.status === EStreamStatus.PLATFORM_UPLOAD_FAILED || stream.status === EStreamStatus.PLATFORM_UPLOAD_FAILED_UNAVAILABLE) return (
    <div
      className="stream-card"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {contextHolder}
      {index > 0 && !withoutDivider && <div style={styles.horizontalDivider} />}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore  */}
      <div style={styles.videosBlock}>
        <div
          style={{
            borderRadius: '8px 8px 0 0',
            backgroundColor: COLORS.GRAY_CONTROLS,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={styles.streamBarContent}>
            <div style={styles.streamBarDetailsContainer}>
              <Tooltip title={stream.title}>
                <div className="video-name-container">
                  <Typography className="video-name" style={styles.videoName}>
                    {stream.title}
                  </Typography>
                </div>
              </Tooltip>
              <div className="small-vertical-divider" />
              <div style={styles.timeBox}>
                <Typography style={styles.timeText}>
                  {secondsToTime(stream.duration)}
                </Typography>
              </div>
              {!isYoutubeCard &&
                <>
                  <div className="small-vertical-divider" />
                  <Typography style={styles.streamDate}>
                    {dayjs(parseInt(stream.start_date)).format('MMMM DD YYYY')}
                  </Typography>
                </>
              }
            </div>
            <div style={styles.viewsContainer}>
              <Typography style={styles.viewsText}>
                {stream?.viewer_stats?.view_count.toLocaleString('en-US') || stream?.total_views.toLocaleString('en-US')}
              </Typography>
              <Image src={IconEye} preview={false} />
            </div>
          </div>
          <div style={{ ...styles.lightningButton }}>
            <Image src={IconLightning} preview={false} />
          </div>
        </div>
        {
          (stream.status === EStreamStatus.FAILED || stream.status === EStreamStatus.PLATFORM_UPLOAD_FAILED || stream.status === EStreamStatus.PLATFORM_UPLOAD_FAILED_UNAVAILABLE) &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore 
          <ErrorItem />
        }
        {
          stream.status === EStreamStatus.EMPTY_CHAT &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore 
          <ErrorItem isEmptyChat />
        }
      </div>
    </div>
  );

  const handleDeleteClip = async (clipId: string) => {
    const response = await axios.put('/streamer/update-favorite-clip', {
      clip: clipId,
      is_removed_from_favorite: true
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    if (response?.status === 200) {
      refetch && refetch();
      messageApi.success('Clip removed!', 3);
    }
  };

  const checkStatus = () => {
    setShowCheckStatusModal(true);
  };

  const handleConfirmRefund = async () => {
    const response = await axios.put('/streamer/refund-upload', {
      id: stream.id
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
        messageApi.error('Error refunding credits. Please, try again.', 5);
      }
    });

    if (response?.status === 200) {
      // messageApi.success('We refunded the minutes for this video. You can always try to process it again.', 5);
      userContext?.setUser(response?.data);
      refetch && refetch();

    } else {
      messageApi.error('Error refunding credits. Please, try again.', 5);
    }

    setShowCheckStatusModal(false);
  };

  const getEstimatedClipsBlock = () =>
    <>
      <div className="small-vertical-divider" />
      <Typography style={styles.estimatedClips}>
        <span style={styles.bold}>{stream.clips.length}</span> <span style={{ color: '#D0D3D7' }}>/</span> <span style={styles.estimatedRight}>{estimatedClipsRange[0]}-{estimatedClipsRange[1]}&nbsp;<span style={styles.estimatedText}>{!isMobile && 'Estimated amount of '}Clips</span></span>
      </Typography>
    </>;

  return (
    <div
      ref={cardRef}
      className="stream-card"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {contextHolder}
      {index > 0 && !withoutDivider && <div style={styles.horizontalDivider} />}
      <CheckStatusModal open={showCheckStatusModal} onClose={() => setShowCheckStatusModal(false)} onConfim={handleConfirmRefund} />
      {!showClips && (
        <>
          <div className="stream-bar-container" style={showProgressBar ? { ...styles.streamBarContainer, borderRadius: '8px 8px 0 0 ' } : { ...styles.streamBarContainer }}>
            <div style={styles.streamBarContent}>
              <div style={styles.streamBarDetailsContainer}>
                <Tooltip title={stream.title}>
                  <div className="video-name-container">
                    <Typography className="video-name" style={styles.videoName}>
                      {stream.title}
                    </Typography>
                  </div>
                </Tooltip>
                <div className="small-vertical-divider" />
                <div style={styles.timeBox}>
                  <Typography style={styles.timeText}>
                    {secondsToTime(stream.duration)}
                  </Typography>
                </div>
                {!isYoutubeCard &&
                  <>
                    <div className="small-vertical-divider" />
                    <Typography style={styles.streamDate}>
                      {dayjs(parseInt(stream.start_date)).format('MMMM DD YYYY')}
                    </Typography>
                  </>
                }
                {(stream.platform === 'youtube' || stream.platform === 'personal') && stream.upload_mode !== 'transcribe' &&
                  <>{getEstimatedClipsBlock()}</>
                }
              </div>
              <div style={styles.viewsContainer}>
                <Typography style={styles.viewsText}>
                  {stream?.viewer_stats?.view_count.toLocaleString('en-US') || stream?.total_views.toLocaleString('en-US')}

                </Typography>
                <Image src={IconEye} preview={false} />
              </div>
            </div>
            {isYoutubeCard ?
              <div style={{ ...styles.lightningButton }}>
                <Image src={IconLightning} preview={false} />
              </div>
              :
              <div className={(!stream.clips_created && !loadingMakeStreams) ? 'primary-hover' : ''} onClick={() => !stream.clips_created && !loadingMakeStreams ? makeClipsOnClick() : null} data-tour={index === 0 ? 'make-clips' : ''} style={{ ...makeClipsButtonStyle, ...(loadingMakeStreams && styles.loading) }}>
                {loadingMakeStreams ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />} /> : <Image src={IconLightning} preview={false} />}
                {!isMobile &&
                  <Typography style={styles.makeClipsText}>{loadingMakeStreams ? 'Making Clips' : 'Make Clips'}</Typography>
                }
              </div>
            }
          </div>

          {showProgressBar &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
            // @ts-ignore 
            <div style={styles.progressContainer}>
              <LoaderFacts uniqueId={stream.id} setRefundButton={setShowCheckStatusModal} />
            </div>
          }
        </>
      )
      }

      {
        showClips && (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <div className={`card-header ${!isOpenCollapse && 'card-header-closed'}`} style={styles.videosBlock}>
              <div
                onClick={toggleCollapse}
                className='cursor-pointer'
                style={{
                  borderRadius: '8px 8px 0 0',
                  backgroundColor: COLORS.GRAY_CONTROLS,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={styles.streamBarContent}>
                  <div style={styles.streamBarDetailsContainer}>
                    <Tooltip title={stream.title}>
                      <div className="video-name-container">
                        <Typography className="video-name" style={styles.videoName}>
                          {stream.title}
                        </Typography>
                      </div>
                    </Tooltip>
                    <div className="small-vertical-divider" />
                    <div style={styles.timeBox}>
                      <Typography style={styles.timeText}>
                        {secondsToTime(stream.duration)}
                      </Typography>
                    </div>
                    {!isYoutubeCard &&
                      <>
                        <div className="small-vertical-divider" />
                        <Typography style={styles.streamDate}>
                          {dayjs(parseInt(stream.start_date)).format('MMMM DD YYYY')}
                        </Typography>
                      </>
                    }
                    {(stream.platform === 'youtube' || stream.platform === 'personal') && stream.upload_mode !== 'transcribe' &&
                      <>{getEstimatedClipsBlock()}</>
                    }
                  </div>
                  <div style={styles.viewsContainer}>
                    <Typography style={styles.viewsText}>
                      {stream?.viewer_stats?.view_count.toLocaleString('en-US') || stream?.total_views.toLocaleString('en-US')}

                    </Typography>
                    <Image src={IconEye} preview={false} />
                  </div>
                </div>
                {isYoutubeCard ?
                  <div style={{ ...styles.lightningButton }}>
                    <DownOutlined className={`collapse-icon ${isOpenCollapse ? 'collapse-icon-open' : ''}`} />
                  </div>
                  :
                  <div data-tour={index === 0 ? 'make-clips' : ''} onClick={() => !stream.clips_created && !loadingMakeStreams && !disabledMakeClipsButton ? makeClipsOnClick() : null} style={{ ...styles.lightningButton, ...(!disabledMakeClipsButton ? styles.lightningButtonEnabled : {}) }}>
                    {loadingMakeStreams ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />} /> : <DownOutlined className={`collapse-icon ${isOpenCollapse ? 'collapse-icon-open' : ''}`} />}
                    {/* {loadingMakeStreams ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />} /> : <Image src={IconLightning} preview={false} />} */}
                    {!disabledMakeClipsButton && !isMobile && <Typography onClick={() => !stream.clips_created && !loadingMakeStreams ? makeClipsOnClick() : null} style={styles.makeClipsText}>{loadingMakeStreams ? 'Making AI Clips' : 'Make AI Clips'}</Typography>}
                  </div>
                }
              </div>

              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore  */}
              {stream.status !== EStreamStatus.LOCKED && stream.status !== EStreamStatus.EMPTY_CHAT && stream.status !== EStreamStatus.PLATFORM_UPLOAD_FAILED && stream.status !== EStreamStatus.PLATFORM_UPLOAD_FAILED_UNAVAILABLE &&
                <Collapse isOpened={isOpenCollapse}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore  */}
                  <div style={styles.videosContainer}>
                    <div style={styles.carouselControllersContainer}>
                      {!isFirstSlide ?
                        <div className="cursor-pointer" onClick={handlePrevSlide}>
                          <Image
                            style={{ ...styles.arrowStyle, transform: 'rotate(180deg)' }}
                            src={ArrowRight}
                            preview={false}
                          />
                        </div>
                        :
                        <div />
                      }
                      {!isLastSlide ?
                        <div className="cursor-pointer" onClick={handleNextSlide}>
                          <Image
                            style={styles.arrowStyle}
                            src={ArrowRight}
                            preview={false}
                          />
                        </div>
                        :
                        <div />
                      }

                    </div>
                    {isMobile ?
                      <Carousel
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ref={carouselRef}
                        dots={false}
                        infinite={false}
                        afterChange={handleCarouselChange}
                      >
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {stream.status !== EStreamStatus.FAILED &&
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          stream.status !== EStreamStatus.LOCKED &&
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          stream.status !== EStreamStatus.EMPTY_CHAT &&
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          stream.status !== EStreamStatus.PLATFORM_UPLOAD_FAILED &&
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          stream.status !== EStreamStatus.PLATFORM_UPLOAD_FAILED_UNAVAILABLE &&
                          stream.clips.sort((a, b) => b.clip_order - a.clip_order).map((clip, i) => (
                            <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {clip.status === ESpikeStatus.BLOCKED ? (
                                <LockedSpike isTwitchCard={isTwitchCard} />
                              ) : (
                                <SpikeCardNew
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  clip={clip}
                                  platformStreamId={stream.platform_stream_id}
                                  isVisible
                                  // isVisible={i >= firstClip && i < lastClip}
                                  isTwitchCard={isTwitchCard}
                                  isYoutubeCard={isYoutubeCard}
                                  onRemoveCard={handleDeleteClip}
                                  enablePreview
                                />
                              )}
                            </div>
                          ))}
                      </Carousel>
                      :
                      <Carousel
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ref={carouselRef}
                        dots={false}
                        infinite={false}
                        afterChange={handleCarouselChange}
                      >

                        {Array.from({ length: Math.round(stream.clips.length / 2) }, (value, i) => (
                          <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                            {stream.status !== EStreamStatus.FAILED &&
                              stream.status !== EStreamStatus.LOCKED &&
                              stream.status !== EStreamStatus.EMPTY_CHAT &&
                              (
                                <>
                                  {stream.clips
                                    .slice(i * 2, i * 2 + 2)
                                    .sort((a, b) => b.clip_order - a.clip_order)
                                    .map((clip, i) => (
                                      <Fragment key={clip.id}>
                                        {clip.status === ESpikeStatus.BLOCKED ? (
                                          <LockedSpike isTwitchCard={isTwitchCard} />
                                        ) : (
                                          <>
                                            {i > 0 && (
                                              <div style={styles.verticalDivider} />
                                            )}
                                            <SpikeCardNew
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-ignore
                                              clip={clip}
                                              platformStreamId={stream.platform_stream_id}
                                              isVisible
                                              // isVisible={i >= firstClip && i < lastClip}
                                              isTwitchCard={isTwitchCard}
                                              isYoutubeCard={isYoutubeCard}
                                              onRemoveCard={handleDeleteClip}
                                              enablePreview
                                            />
                                          </>
                                        )}
                                      </Fragment>
                                    ))}
                                </>
                              )
                            }
                          </div>
                        ))}
                      </Carousel>
                    }


                    <div style={styles.subscribeBox}>
                      {USER_NOT_SUBSCRIBED && (
                        <>
                          <Image src={IconGrayStar} preview={false} />
                          <Typography
                            style={{
                              color: COLORS.YELLOW,
                              fontSize: 16,
                              marginLeft: 8,
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => navigate('/subscriptions')}
                          >
                            Upgrade
                          </Typography>
                          <Typography
                            style={{ color: COLORS.GRAY_DISABLED, fontSize: 16 }}
                          >
                            &nbsp; to download in HD
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                </Collapse>
              }
            </div>
          </>
        )
      }
    </div >
  );
});

StreamCard.displayName = 'StreamCard';

export const ErrorItem = ({ isEmptyChat = false, isNoStreams = false }: { isEmptyChat?: boolean; isNoStreams?: boolean }) => {
  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 48px 24px 48px',
        backgroundColor: COLORS.GRAY_BACKGROUND,
        borderRadius: '0 0 8px 8px',
      },
      errorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      title: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 30
      },
      text: {
        fontSize: 20,
        marginBottom: 25,
        textAlign: 'center'
      },
      uppercase: {

      }
    };
  }, []);

  if (isNoStreams) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div style={styles.container}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div style={styles.errorWrapper}>

          <Typography style={{ ...styles.title, ...styles.uppercase }}>We can’t find your videos!</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={styles.text}>In order to view your spikes, please start saving your VODs!</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={{ ...styles.text, ...styles.uppercase }}>We're always here if you need more help!</Typography>
        </div>
      </div>
    );
  }


  if (isEmptyChat) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div style={styles.container}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div style={styles.errorWrapper}>

          <Typography style={{ ...styles.title, ...styles.uppercase }}>One of your stream&apos;s features has an extremely low impact.</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={styles.text}>No worries though, no credits have been deducted.</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={{ ...styles.text, ...styles.uppercase }}>Write <span style={{ color: COLORS.PRIMARY }}>&apos;Spike&apos;</span> in chat to ensure your moment is getting here.</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={{ ...styles.text, ...styles.uppercase }}>We&apos;re always here if you need more help!</Typography>

        </div>
      </div>
    );
  } else {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div style={styles.container}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div style={styles.errorWrapper}>

          <Typography style={styles.title}>Oops! Video processing hiccup.</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={styles.text}>We couldn&apos;t process your video due to potential issues like a blocked VOD or copyright claim. No worries though, no credits have been deducted.</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={styles.text}>Please check these issues and try again, here&apos;s a blog on the subject.</Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Typography style={styles.text}>We&apos;re always here if you need more help!</Typography>

        </div>
      </div>
    );
  }
};

export default StreamCard;
