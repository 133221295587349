import React, { useState, useEffect, useContext } from 'react';
import { Typography, Layout, Button } from 'antd';
import { UserContext } from '../../data/userContext';
import './loyaltyProgram.less';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const { Title } = Typography;
const { Content } = Layout;

import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';

import { MainLayout } from '../../layouts';
import { isBrowser } from 'react-device-detect';
import { Header } from '../../components/header';
import { Footer as AppFooter } from '../../components/footer';

const MAX_DISPLAYED_ROWS = 21;
const DEFAULT_DISPLAYED_ROWS = 10;

interface LevelData {
  key: number;
  level: number;
  title: string;
  required_points: number;
  reward_minutes: string;
}

const LoyaltyProgram: React.FC = () => {
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [displayedRows, setDisplayedRows] = useState<number>(DEFAULT_DISPLAYED_ROWS);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [levelProgress, setLevelProgress] = useState<number>(0);
  const [levelData, setLevelData] = useState<LevelData[]>([]);

  const userContext = useContext(UserContext);

  const updateUserData = async () => {
    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200 || response?.status === 304) {
        const updatedUser = response.data;
        userContext?.setUser(updatedUser);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        const response = await axios.get('/streamer/loyalty-levels');
        if (response?.status === 200) {
          const serverLevelData: LevelData[] = response.data;
          const updatedLevelData: LevelData[] = [
            { key: 0, level: 0, title: '🐥 Baby Chick', required_points: 0, reward_minutes: '0' },
            ...serverLevelData,
          ];
          setLevelData(updatedLevelData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchLevelData();
    updateUserData();
  }, []);

  useEffect(() => {
    if (userContext?.user) {
      const currentLevel = userContext.user.loyalty_level || 0;
      const nextLevel = currentLevel + 1;

      const currentLevelData = levelData.find((level) => level.level === currentLevel);
      const nextLevelData = levelData.find((level) => level.level === nextLevel);

      if (currentLevelData && nextLevelData) {
        const currentPoints = userContext.user.loyalty_score;
        const currentLevelPoints = currentLevelData.required_points;
        const nextLevelPoints = nextLevelData.required_points;

        const pointsWithinCurrentLevel = currentPoints - currentLevelPoints;
        const levelRange = nextLevelPoints - currentLevelPoints;
        const levelProgressPercentage =
          pointsWithinCurrentLevel >= 0
            ? (pointsWithinCurrentLevel / levelRange) * 100
            : 0;

        if (levelProgressPercentage >= 100) {
          setLevelProgress(0);
        } else {
          setLevelProgress(levelProgressPercentage);
        }
      }
    }
  }, [userContext?.user, levelData]);

  const toggleShowTable = () => {
    setDisplayedRows(displayedRows === MAX_DISPLAYED_ROWS ? DEFAULT_DISPLAYED_ROWS : MAX_DISPLAYED_ROWS);
  };

  return (
    <>
      <MainLayout
        header={isBrowser ? <Header title={''} /> : null}
        footer={<AppFooter shadow={false} />}
        marginTop='26px'
      >
        <div style={{ maxWidth: 850, margin: '0 auto' }} className="general-content-wrapper">
          <Title className="loyalty-program-title">Loyalty Program</Title>

          <div className="loyalty-program-progress-bar">
            <div className="progress-label">Current Level: {userContext?.user?.loyalty_title || '🐥 Baby Chick'}</div>
            <div className="custom-progress-bar">
              <div
                className="custom-progress-fill"
                style={{
                  width: `${levelProgress}%`,
                  transition: 'width 2.5s ease-in-out',
                }}
              >
                {levelProgress > 0 && ( // Conditionally render the percentage text
                  <div className="progress-text">
                    {Math.round(levelProgress)}%
                  </div>
                )}
              </div>
            </div>
          </div>
          {showTable && (
            <table className="loyalty-program-table custom-table">
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Title</th>
                  <th>Required Points</th>
                  <th>Reward Minutes</th>
                </tr>
              </thead>
              <tbody>
                {levelData.slice(0, displayedRows).map((level) => (
                  <tr key={level.key}>
                    <td>{level.level}</td>
                    <td>{level.title}</td>
                    <td>{level.required_points}</td>
                    <td>{level.reward_minutes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="learn-more-link">
            <a href="https://www.notion.so/affiliate-spikes-studio/New-Rewards-Program-2e39c99ba91e46bd8910a158bf9bd2e7" target="_blank" rel="noopener noreferrer">
              Learn More
            </a>
          </div>
          <div className="show-more-button-container">
            <button className="show-more-button" onClick={toggleShowTable}>
              {displayedRows === MAX_DISPLAYED_ROWS ? 'Show Less Rows' : 'Show More Rows'}
            </button>
          </div>
        </div>
        <FooterResponsive />
      </MainLayout>
    </>
  );
};

export default LoyaltyProgram;
