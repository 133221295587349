import { useMemo } from 'react';
import { Typography, Image } from 'antd';
import { Element } from 'react-scroll';

import './AboutUs.less';
import { COLORS } from '../../../../themes/colors';
import IconLaptop from '../../../../assets/icon-laptop.svg';
import IconPhone from '../../../../assets/icon-phone.svg';
import IconTablet from '../../../../assets/icon-tablet.svg';
import AboutAsset from '../../../../assets/homepage-about-asset.svg';
import SecondImage from '../../../../assets/about-us-second-asset.png';
import ThirdImage from '../../../../assets/about-us-third-asset.png';
import FirstMobileImage from '../../../../assets/about-us-mobile-first.png';
import SecondMobileImage from '../../../../assets/about-us-mobile-second.png';
import SpikesSmallIcon from '../../../../assets/spikes-small-icon.svg';
import { useWindowDimensions } from '../../../Editor/Editor.page';

const AboutUs = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 1440;

  const styles = useMemo(() => {
    return {
      container: {
        backgroundColor: COLORS.MAIN_BACKGROUND
      },
      sectionTitle: {
        color: '#666',
        fontSize: 36,
        fontWeight: 300,
        marginBottom: 78,
        display: 'flex',
        justifyContent: 'center'
      },
      platformsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
      },
      icon: {
        borderRadius: 5,
        height: 72,
        width: 72,
        padding: 15,
        background: '#3A3A3A'
      },
      iconMobile: {
        borderRadius: 5,
        height: 53,
        width: 53,
        padding: 8,
        background: '#3A3A3A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      iconsContainer: {
        display: 'flex',
        gap: 20
      }
    } as const;
  }, []);

  const DesktopView = () => (
    <div className="homepage-wrapper">
      <Typography style={styles.sectionTitle}>About</Typography>

      <div className="about-us__item" style={{ marginBottom: 136 }}>
        <div className="about-us__description-block">
          <Image src={AboutAsset} preview={false} width={360} />
          <Typography className="about-us__description-title">Trim. Edit. Share. <span className="secondary-text">Repeat!</span></Typography>
          <Typography className="about-us__description-text">Cutting-edge technology <span className="primary-text">processing</span> <span className="primary-text no-wrap">24-hour long</span> videos with no limitations.</Typography>
          <div style={styles.platformsContainer}>
            <Typography className="about-us__platforms-text">All platforms ready</Typography>
            <div style={styles.iconsContainer}>
              <div style={styles.icon}><Image src={IconLaptop} preview={false} /></div>
              <div style={styles.icon}><Image src={IconPhone} preview={false} /></div>
              <div style={styles.icon}><Image src={IconTablet} preview={false} /></div>
            </div>
          </div>
        </div>
        <div className="about-us__video" style={{ paddingTop: 26 }}>
          <video style={{ borderRadius: 10 }} height="97.5%" width="100%" muted autoPlay loop>
            <source
              src="welcome-video.webm"
              type="video/webm"
            />
            <p>Editor showcase video</p>
          </video>
        </div>
      </div>

      <div className="about-us__item" style={{ marginBottom: 136 }}>
        <div className="about-us__video">
          <Image src={SecondImage} preview={false} />
        </div>
        <div className="about-us__description-block">
          <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <Typography className="about-us__description-title"><span className="no-wrap">Our <span className="primary-text">AI&nbsp;</span>improves</span></Typography>
            <Typography className="about-us__description-title">with every Use</Typography>
          </div>
          <Typography style={{ textAlign: 'left' }} className="about-us__description-text">Our AI does not just work for you, it learns from you. With every interaction, it gains insights into your unique needs and personalizing its services. This is more than just an algorithm.</Typography>

        </div>
      </div>

      <div className="about-us__item">
        <div className="about-us__description-block">
          <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <Typography className="about-us__description-title"><span className="no-wrap"><span className="primary-text">Direct&nbsp;</span>downloads</span></Typography>
            <Typography className="about-us__description-title">to your phone</Typography>
          </div>
          <Typography className="about-us__description-text">You can download your clips directly via QR code to your phone or send them to your Twitter, WhatsApp, Email, Facebook, or Telegram account</Typography>

        </div>
        <div className="about-us__video">
          <Image src={ThirdImage} preview={false} />
        </div>
      </div>
    </div>
  );

  const MobileView = () => (
    <div className="homepage-wrapper mobile">
      <Typography className="about-title">About</Typography>
      <Typography className="about-label">Trim, edit & share</Typography>
      <div style={styles.iconsContainer}>
        <div style={styles.iconMobile}><Image src={IconLaptop} preview={false} style={{ height: 30 }} /></div>
        <div style={styles.iconMobile}><Image src={IconPhone} preview={false} style={{ height: 30 }} /></div>
        <div style={styles.iconMobile}><Image src={IconTablet} preview={false} style={{ height: 30 }} /></div>
      </div>

      <div style={{ marginTop: 60 }}>
        <Typography className="about-item__text">Cutting-edge technology <span style={{ color: COLORS.PRIMARY }}>processing
          24-hour</span> long videos with no limitations.</Typography>
      </div>

      <div className="about-us__video">
        <Image src={FirstMobileImage} preview={false} />
      </div>
      <Image src={SpikesSmallIcon} preview={false} style={{ marginTop: 25, marginBottom: 25 }} />

      <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <Typography className="about-us__description-title"><span className="no-wrap">Our <span className="primary-text">AI&nbsp;</span>improves</span></Typography>
        <Typography className="about-us__description-title">with every Use</Typography>
      </div>
      <Typography className="about-item__text" style={{ marginTop: 13, marginBottom: 26 }}>Our AI does not just work for you, it learns from you. With every interaction, it gains insights into your unique needs. This is more than just an algorithm.</Typography>
      <div className="about-us__video">
        <Image src={SecondMobileImage} preview={false} />
      </div>
    </div>
  );

  return (
    <Element name="about-section">
      <div className="homepage-container about-us" style={styles.container}>
        {/* <DesktopView /> */}
        {isMobile ? <MobileView /> : <DesktopView />}
      </div>
    </Element >
  );
};

export default AboutUs;
