import React, { useContext, useState } from 'react'
import { SocialType } from './ShareModal';
import { IClip, IClipEdit, IVideoClip } from '../../data/intefaces/stream.interface';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';

import { ReactComponent as IconCollapse } from '../../assets/collapse-share.svg';
import { ReactComponent as IconDisconnect } from '../../assets/disconnect.svg';
import { ReactComponent as IconConnect } from '../../assets/connect.svg';
import { ReactComponent as IconSchedule } from '../../assets/schedule.svg';
import './ShareModal.less';
import { Collapse } from 'react-collapse';
import TextArea from 'antd/lib/input/TextArea';
import { DtPicker } from 'react-calendar-datetime-picker';
import 'react-calendar-datetime-picker/dist/style.css';
import { postAudit } from '../../services/audit.service';
import { Radio, message } from 'antd';
import { convertToISO, renderScheduleDate } from '../../utils';


interface IShareTabProps {
  type: SocialType;
  isOpened: boolean;
  onTabClick: (type: SocialType) => void;
  icon: any;
  name: string;
  videoUrl?: string | null | undefined;
  handlePostNow: (description: string, data: any, type: SocialType) => void;
  handleCloseModal: () => void;
  redirectToSocialExternalUrl: () => void;
  clip?: IClip & Partial<IClipEdit> & IVideoClip;
  disabled?: boolean;
  isConnected?: boolean;
};

export const ShareTab = ({ type, isOpened, onTabClick, icon, name, videoUrl, handlePostNow, handleCloseModal, clip, disabled, redirectToSocialExternalUrl, isConnected }: IShareTabProps) => {
  const [selectedYoutubePrivacy, setSelectedYoutubePrivacy] = useState('public');
  const [selectedInstagramPostType, setSelectedInstagramPostType] = useState('post');
  const [selectedFacebookPostType, setSelectedFacebookPostType] = useState('post');
  const [selectedYoutubePostType, setSelectedYoutubePostType] = useState('video');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedSubreddit, setSelectedSubreddit] = useState('');
  const [scheduleDate, setScheduleDate] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const allowDirectShare = userContext?.user?.permissions?.direct_share;

  const isTitleFieldMandatory = Boolean(type === 'youtube' || type === 'pinterest' || type === 'reddit');

  const getIsAlreadyShared = () => {
    let result = false;
    if (clip?.social_posts && clip?.social_posts?.length > 0) {

      clip?.social_posts.forEach(post => {
        post.post.platforms.forEach(platform => {
          if (platform.toLocaleLowerCase() === type.toLocaleLowerCase()) {
            result = true;
          }
        });
      })
    }

    return result;
  }

  const handleHeaderLeftClick = () => {
    if (isConnected) {
      onTabClick(type);
    } else {
      redirectToSocialExternalUrl();
    }
  };

  const handleClickConnect = async () => {
    redirectToSocialExternalUrl();
  };

  const handleClickDisconnect = () => {
    redirectToSocialExternalUrl();
  };

  const clickPostNow = () => {
    if (allowDirectShare) {
      const options: any = getOptionsObject();

      if (scheduleDate) {
        options.publishAt = convertToISO(scheduleDate);
      }

      // Stops the function is fields are not valid
      const isValid = validateFields();
      if (!isValid) return;

      handlePostNow(selectedDescription, options, type);
    } else {
      navigate('/subscriptions');
    }
  };

  const getOptionsObject = () => {
    if (type === 'youtube') {
      return { title: selectedTitle, visibility: selectedYoutubePrivacy, shorts: selectedYoutubePostType === 'shorts' }
    } else if (type === 'instagram') {
      return { reels: selectedInstagramPostType === 'reels', stories: selectedInstagramPostType === 'stories' }
    } else if (type === 'facebook') {
      return { reels: selectedFacebookPostType === 'reels' }
    } else if (type === 'pinterest') {
      return { title: selectedTitle, thumbNail: clip?.mobile_thumbnail_url || clip?.thumbnail_url }
    } else if (type === 'reddit') {
      return { title: selectedTitle, subreddit: selectedSubreddit }
    } else {
      return {}
    }
  }

  const clickSchedule = () => {
    if (allowDirectShare) {
      return;
    } else {
      navigate('/subscriptions');
    }
  };

  const validateFields = () => {
    if (!selectedDescription) {
      messageApi.error('Description is required.', 5);
      return false;
    }
    if (isTitleFieldMandatory) {
      if (!selectedTitle) {
        messageApi.error('Title is required.', 5);
        return false;
      } else {
        return true;
      }
    }
    if (type === 'reddit' && !selectedSubreddit) {
      messageApi.error('Subreddit is required.', 5);
      return false;
    }
    return true;
  }

  return (
    <div className={`share-tab ${disabled && 'disabled'} ${isOpened && 'active'}`}>
      {contextHolder}
      <div className="tab-header">
        <div onClick={handleHeaderLeftClick} className="header-left">
          <IconCollapse />
          {icon}
          <span>{name}</span>
          {getIsAlreadyShared() && <span className='share-tab__already-shared'>(Already shared)</span>}
        </div>

        {isConnected ?
          <div onClick={handleClickDisconnect} className="header-right">
            <IconDisconnect />
            <span>Disconnect</span>
          </div>
          :
          <div onClick={handleClickConnect} className="header-right">
            <IconConnect />
            <span>Connect</span>
          </div>
        }

        {/* <div className="header-right">
          <span style={{ color: '#A8A8A8' }}>Coming soon...</span>
        </div> */}

      </div>
      <Collapse isOpened={isOpened}>
        <div className="tab-content">
          {isTitleFieldMandatory &&
            <TextArea
              className="share-textarea"
              value={selectedTitle}
              onChange={(e) => setSelectedTitle(e.target.value)}
              placeholder="Title (mandatory field)"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          }
          <TextArea
            className="share-textarea"
            value={selectedDescription}
            onChange={(e) => setSelectedDescription(e.target.value)}
            placeholder="Description (mandatory field)"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          {type === 'reddit' &&
            <TextArea
              className="share-textarea"
              value={selectedSubreddit}
              onChange={(e) => setSelectedSubreddit(e.target.value)}
              placeholder="Subreddit (mandatory field)"
              autoSize={{ minRows: 2, maxRows: 3 }}
            />
          }
          {type === 'youtube' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedYoutubePrivacy}
              onChange={(e) => setSelectedYoutubePrivacy(e.target.value)}
            >
              <Radio value='public'>Public</Radio>
              <Radio value='private'>Private</Radio>
              <Radio value='unlisted'>Unlisted</Radio>
            </Radio.Group>
          }
          {type === 'youtube' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedYoutubePostType}
              onChange={(e) => setSelectedYoutubePostType(e.target.value)}
            >
              <Radio value='video'>Video</Radio>
              <Radio value='shorts'>Shorts</Radio>
            </Radio.Group>
          }
          {type === 'instagram' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedInstagramPostType}
              onChange={(e) => setSelectedInstagramPostType(e.target.value)}
            >
              <Radio value='post'>Post</Radio>
              <Radio value='reels'>Reels</Radio>
              <Radio value='stories'>Stories</Radio>
            </Radio.Group>
          }
          {type === 'facebook' &&
            <Radio.Group
              className='share-radio-group'
              buttonStyle='outline'
              value={selectedFacebookPostType}
              onChange={(e) => setSelectedFacebookPostType(e.target.value)}
            >
              <Radio value='post'>Post</Radio>
              <Radio value='reels'>Reels</Radio>
            </Radio.Group>
          }
        </div>
        <div className='action-buttons'>
          <div onClick={clickSchedule} className='schedule-button'>
            <IconSchedule />
            <DtPicker autoClose={false} inputClass='tiktok-date__input' onChange={setScheduleDate} withTime />

            <span>{scheduleDate ? renderScheduleDate(scheduleDate) : 'Schedule post'}</span>
          </div>
          <div onClick={clickPostNow} className={`post-button ${!allowDirectShare && 'disabled'}`}>{scheduleDate ? 'Schedule Post' : 'Post now'}</div>
        </div>
      </Collapse>
    </div>
  );
};