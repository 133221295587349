import React, { useState, useMemo, useEffect, useContext, createRef } from 'react';
import axios from 'axios';
import './BrandingTab.less';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { checkFileType, getEnabledBrandingForUser, removeFileExtension } from '../../../utils';
import { ReactComponent as IconSearch } from '../../../assets/input-search.svg';
import { UserContext } from '../../../data/userContext';
import { postAudit } from '../../../services/audit.service';
import * as tus from 'tus-js-client';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { ReactComponent as DropdownIndicatorIcon } from '../../../assets/dropdown-indicator-small.svg';
import { ReactComponent as IconArrowLeft } from '../../../assets/caret_left.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/caret_right.svg';
import { Carousel, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { WatermarkBlock } from './WatermarkBlock';

export interface IBrandingTabProps {
  watermarkFeatureSlider: any;
  handleChooseWatermark: (imageUrl: string, ratio: number, position: string) => void;
  handleSelectNoneWatermark: () => void;
  handleDeleteFeatureSlider: (id: number) => void;
}

const positionOptions = [
  {
    value: 'top-left',
    label: 'Top Left'
  },
  {
    value: 'top-right',
    label: 'Top Right'
  },
  {
    value: 'bottom-left',
    label: 'Bottom Left'
  },
  {
    value: 'bottom-right',
    label: 'Bottom Right'
  },
  {
    value: 'custom',
    label: 'Custom'
  },
]

export interface Asset {
  createdAt: number
  updatedAt: number
  id: string
  type: string
  name: string
  url: string
  is_default: boolean
  streamer: string
}


const BrandingTab = ({ watermarkFeatureSlider, handleDeleteFeatureSlider, handleChooseWatermark, handleSelectNoneWatermark }: IBrandingTabProps) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const [allAssets, setAllAssets] = useState<Asset[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const userContext = useContext(UserContext);

  const brandingTabEnabledForUser = getEnabledBrandingForUser(userContext?.user);

  useEffect(() => {
    // fetchAllAssets();
  }, [])

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const fetchAllAssets = async () => {
    const response = await axios.get('/streamer/assets');
    if (response?.data) {
      setAllAssets(response?.data)
    }
  }

  return (
    <div className='subtitles-wrapper branding scrollbar'>
      {contextHolder}
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

      <WatermarkBlock handleDeleteFeatureSlider={handleDeleteFeatureSlider} watermarkFeatureSlider={watermarkFeatureSlider} handleChooseWatermark={handleChooseWatermark} handleSelectNoneWatermark={handleSelectNoneWatermark} />
    </div>
  );
};

export default BrandingTab;
