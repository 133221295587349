import { message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { changeMonthToCurrent, changeMonthToFollowing, isDateBeforeToday, changeYearToFollowing } from '../../utils';
import dayjs from 'dayjs';
import { postAudit } from '../../services/audit.service';
import axios from 'axios';
import './CancelSubscriptionModal.less';
import Modal from 'react-modal';
import { LeaveReason } from './LeaveReason';
import { SuggestGift } from './SuggestGift';
import { ProceedCancellation } from './ProceedCancellation';
import { SubscriptionCancelled } from './SubscriptionCancelled';
import { leaveReasonList } from './LeaveReason';

export const CancelSubSteps = {
  REASON: 'reason',
  SUGGEST_GIFT: 'suggest_gift',
  CANCELLATION: 'cancellation',
  SUBSCRIPTION_CANCELLED: 'subscription_cancelled'
}

export const CancelSubscriptionModal = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(CancelSubSteps.REASON)
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [chosenReason, setChosenReason] = useState<null | string>(null);
  const [loadingCancelling, setLoadingCancelling] = useState(false);

  const leaveReasonLabel = leaveReasonList.find((item: any) => item.value === chosenReason)?.label || leaveReasonList.find((item: any) => item.value === 'other')?.label;

  const subscriptions = userContext?.user?.subscriptions;
  const existingSubscription = subscriptions && subscriptions?.length > 0 ? subscriptions[0] : null;

  const skipGiftStep = Boolean(subscriptions?.find(sub => sub.is_coupon_activated || sub?.plan_interval === 'year' || Boolean(sub?.paypal_id)));

  useEffect(() => {
    if (currentStep === CancelSubSteps.REASON) {
      if (!(existingSubscription && existingSubscription !== '')) {
        handleCloseModal();
      }
    }
  }, [existingSubscription, currentStep])

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/spikes/videos');
  }

  const cancelSubscription = async (id: string) => {
    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `Cancel subscription - STARTED. Payload: ${JSON.stringify(userContext?.user?.subscriptions[0].id)}`,
      user_id: userContext?.user?.id
    });
    const response = await axios.post('/streamer/cancel-subscription', {
      id: id,
      cancellation_reason: JSON.stringify(leaveReasonLabel)
    }).catch((error) => {
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `Cancel subscription - FAILED API call. Error: ${JSON.stringify(error)}`,
        user_id: userContext?.user?.id
      });
      if (error.response) {
        console.log(error.response);
        return null;
      }
    });

    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `Cancel subscription - API call finished. Response: ${JSON.stringify(response)}`,
      user_id: userContext?.user?.id
    });
    if (response?.status === 200) {
      return response.data;
    }
  };

  const handleConfirmCancellation = async () => {
    if (loadingCancelling) return null;
    setLoadingCancelling(true);
    const user = await cancelSubscription(userContext?.user?.subscriptions[0].id);
    if (user != null) {
      userContext?.setUser(user);
      setCurrentStep(CancelSubSteps.SUBSCRIPTION_CANCELLED);
    } else {
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `Cancel subscription - FAILED. No user returned from the API`,
        user_id: userContext?.user?.id
      });
      message.error(
        <>
          Oops! Looks like we hit a bump. Don&apos;t worry though, we&apos;ve got your back.
          Please reach out to us directly at{' '}
          <a href="mailto:support@spikes.studio">support@spikes.studio</a> and we&apos;ll help sort
          out your subscription cancellation. Sorry for the hassle!
        </>
      );
    }
    setLoadingCancelling(false);
  };

  const getRenewalDate = () => {
    let createdDate = userContext?.user?.subscriptions[0]?.createdAt;
    if (!createdDate) return null;
    const number_of_renewel = userContext?.user?.subscriptions[0]?.number_of_renewel;
    if (createdDate.toString().length === 10) {
      createdDate *= 1000;
    }

    const isCreatedBeforeToday = isDateBeforeToday(createdDate);
    if (isCreatedBeforeToday) {
      if (number_of_renewel == 1) {
        return dayjs(parseInt(JSON.stringify(changeMonthToFollowing(createdDate)))).format('MMMM DD YYYY');
      }
      else {
        return dayjs(parseInt(JSON.stringify(changeYearToFollowing(createdDate)))).format('MMMM DD YYYY');
      }

    } else {
      return dayjs(parseInt(JSON.stringify(changeMonthToCurrent(createdDate)))).format('MMMM DD YYYY');
    }
  };


  const handleItemClick = (value: string) => {
    setChosenReason(value);
  };

  const handleSubmitReasonStep = () => {
    // Skip this step if user already received a gift
    if (!skipGiftStep) {
      setCurrentStep(CancelSubSteps.SUGGEST_GIFT);
    } else {
      setCurrentStep(CancelSubSteps.CANCELLATION);
    }
  }

  const handleProceedCancellation = () => {
    setCurrentStep(CancelSubSteps.CANCELLATION);
  }

  const handleKeepPlan = () => {
    if (loadingCancelling) return null;
    handleCloseModal();
  }

  return (
    <Modal
      isOpen={isModalOpen}
      className="Modal"
      overlayClassName="Overlay Cancel-Subscription-Overlay"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseModal}
    >
      <div className='cancel-sub'>
        {currentStep === CancelSubSteps.REASON && <LeaveReason handleItemClick={handleItemClick} chosenReason={chosenReason} handleSubmit={handleSubmitReasonStep} />}
        {currentStep === CancelSubSteps.SUGGEST_GIFT && <SuggestGift chosenReason={chosenReason} handleCloseModal={handleCloseModal} handleProceedCancellation={handleProceedCancellation} />}
        {currentStep === CancelSubSteps.CANCELLATION && <ProceedCancellation handleConfirmCancellation={handleConfirmCancellation} handleKeepPlan={handleKeepPlan} renewalDate={getRenewalDate()} loadingCancelling={loadingCancelling} />}
        {currentStep === CancelSubSteps.SUBSCRIPTION_CANCELLED && <SubscriptionCancelled handleCloseModal={handleCloseModal} />}
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;