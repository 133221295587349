import styled from 'styled-components';
import { Avatar, Layout as AntLayout, Typography } from 'antd';
import { Footer as AppFooter } from '../components/footer';
import { rem } from 'polished';

const { Text } = Typography;

const { Sider: AntAside, Header: AntHeader, Footer: AntFooter, Content: AntContent } = AntLayout;

const StyledFrontLayoutContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  background: #1C1D21; radial-gradient(107.5% 112.85% at 9.13% 18.18%, #1C1D21 0%, #39393C 100%);
`;

const StyledFrontLayout = styled(AntLayout)`
  height: 100%;
  width: 100%;
  background: transparent;
  .ant-layout {
    background: transparent;
  }
`;

const Header = styled(AntHeader)`
  background-color: transparent;
  height: auto;

  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #33474E;
  display: flex;
  padding: ${rem('30px')} 0 0 0;
  justify-content: space-between;
  z-index: 30;
`;

const Content = styled(AntContent)`
  background-color: transparent;


  margin-left: auto;
  margin-right: auto;
  padding: ${rem('38px')} 0;
  min-height: unset !important;
`;

const Footer = styled(AntFooter)`
  background-color: #010714;
  height: auto;
  border-bottom: 1px solid #33474E;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

type TMainLayout = {
  header?: React.ReactNode;
  children?: React.ReactNode;
};

const FrontLayout = ({ header, children }: TMainLayout) => {
  return (
    <StyledFrontLayoutContainer>
      <StyledFrontLayout>
        <AntLayout>
          <Header>
            {header}
          </Header>
          <Content>{children}</Content>
          <Footer>
            <AppFooter />
          </Footer>
        </AntLayout>
      </StyledFrontLayout>
    </StyledFrontLayoutContainer>
  );
};

export default FrontLayout;
