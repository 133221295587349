import './CancelSubscriptionModal.less';
import { ReactComponent as IconRedClose } from '../../assets/red_close.svg';

const losingBenefitsList = [
  {
    label: 'Watermark-free clips'
  },
  {
    label: '1080p export resolution'
  },
  {
    label: 'Social media scheduling'
  },
  {
    label: 'Animated Caption'
  },
  {
    label: 'Full access to the advanced editor'
  },
]

interface IProceedCancellationProps {
  handleKeepPlan: () => void;
  handleConfirmCancellation: () => void;
  renewalDate: any;
  loadingCancelling: boolean;
}

export const ProceedCancellation = ({ handleKeepPlan, handleConfirmCancellation, renewalDate, loadingCancelling }: IProceedCancellationProps) => {
  return (
    <div className='proceed-cancellation'>
      <div className="title">You will retain your Pro account benefits until {renewalDate || '(Not mentioned)'}. After this date, your account will lose the following features:</div>
      <div className="lost-benefits-list">
        {losingBenefitsList.map(item => (
          <div key={item.label} className='lost-benefit'>
            <IconRedClose />
            <span>{item.label}</span>
          </div>
        ))}
      </div>

      <div className="proceed-cancellation__subtitle">We hope to see you back on Pro soon! If you still wish to proceed, click &apos;Confirm cancellation&apos;</div>

      <div className="suggest-gift__buttons">
        <div onClick={handleKeepPlan} className="suggest-gift__button secondary">
          Keep my plan
        </div>
        <div onClick={handleConfirmCancellation} className="suggest-gift__button">
          {loadingCancelling ? 'Loading...' : 'Confirm cancellation'}
        </div>
      </div>
    </div>
  )
}