import { Col, Row, Space, Button, Typography } from 'antd';
import { useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import Modal from 'react-modal';
import './DeleteUploadModal.less';

export const DeleteUploadModal = ({ open, setOpen, onDeleteUpload }: { open: boolean; setOpen: (isOpen: boolean) => void; onDeleteUpload: () => void; }) => {

  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 24,
        overflow: 'hidden',
        border: '1px solid #FFF',
        borderColor: COLORS.PRIMARY,
      },
      modalBody: {
        background: '#001922',
        padding: 40,
      },
      checkboxStyle: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 0
      }
    } as const;
  }, []);


  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className="delete-upload-modal">
        <span onClick={onClose} className="icon-close">X</span>
        <h1>Remove the Upload</h1>
        <p>Are you sure you&apos;d like to remove the upload?</p>
        <div className='delete-upload-modal__buttons'>
          <div onClick={onClose} className="delete-upload-modal__button cancel">Keep Upload</div>
          <div onClick={onDeleteUpload} className="delete-upload-modal__button delete">Remove Upload</div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUploadModal;