import { Modal, Image, Button, message } from 'antd';
import { useContext, useMemo } from 'react';
import { COLORS } from '../../themes/colors';

import IconClose from '../../assets/modal-close.svg';
import { UserContext } from '../../data/userContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Refferal from '../../assets/referral.png';
import { TwitterShareButton } from 'react-share';


export interface IInviteModalProps {
  onClose: () => void
}

export const InviteModal = ({ onClose }: IInviteModalProps) => {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const invitationUrl = `https://${window.location.hostname.replace('www.', '')}/login?ref=${userContext?.user?.referral_key}`;
  //const shareTwitterText = `Are you looking to capture clips using AI? Check out @SpikesGG.  Its free to signup, use my link <3! ${invitationUrl} #spikesgg #twitchclips`;
  const shareTwitterText = 'Attention to all my awesome peeps \uD83D\uDE80\n\nI\'m exploring the future with @SpikesGG, an AI tool that captures all your best moments on stream and turns them into epic clips!\n\nBTW It\'s FREE \u2764\ufe0f \n\nUse my link.';
  const shareEmailText = `Are you looking to capture clips using AI? Check out @SpikesGG.  Its free to signup, use my link <3! ${invitationUrl}`;
  const navigate = useNavigate();

  const styles = useMemo(() => {
    return {
      modal: {
        borderRadius: 10,
        overflow: 'hidden',
        textAlign: 'center',
      },
      modalBody: {
        background: '#2D2D2D',
        padding: 0,
      },
      inviteCode: {
        background: 'linear-gradient(166.65deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.05) 100%)',
        borderRadius: 15,
        padding: '5px 20px',
        fontSize: 20,
        fontWeight: 700,
      },
      invitationUrl: {
        background: '#404040',
        padding: '10px 20px',
        borderRadius: 10,
        fontSize: 20,
        textAlign: 'start',
        color: '#A0A0A0'
      },
      buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 34
      },
      copyButton: {
        letterSpacing: 'normal',
        height: 56,
        width: 100,
        fontSize: 24
      },
      shareButton: {
        height: 56
      },
      content: {
        padding: '26px 35px 40px 35px',
        display: 'flex',
        flexDirection: 'column'
      }
    } as const;
  }, []);

  const handleClose = () => {
    onClose();
    navigate('/spikes/videos');
  };

  const copyInvitationUrl = () => {
    navigator.clipboard.writeText(invitationUrl);
    message.success({
      content: 'Invitation URL copied!', style: {
        color: COLORS.BLACK,
      }
    });
  };

  return (
    <Modal
      open={true}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modal}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      closable={false}
      maskClosable={false}
      width={647}
      centered
    >
      <div style={{ position: 'relative' }}>
        <Image src={Refferal} preview={false} />
        <div style={{ position: 'absolute', top: 20, right: 20 }} className='cursor-pointer' onClick={handleClose}>
          <Image src={IconClose} preview={false} width={24} />
        </div>
        <div style={styles.content}>
          <div style={styles.invitationUrl}>{invitationUrl}</div>
          <div style={styles.buttonGroup}>
            <Button className='button' type="primary" size="small" onClick={copyInvitationUrl} style={{ width: 176, height: 45 }}>
              Copy URL
            </Button>
            <TwitterShareButton url={invitationUrl}>
              <Button className='button' type="primary" size="small" onClick={copyInvitationUrl} style={{ width: 176, height: 45 }}>
                Twitter
              </Button>
            </TwitterShareButton>
            <a href={`https://mail.google.com/mail/u/0/?fs=1&body=${shareEmailText}&tf=cm`} target="_blank" rel="noreferrer">
              <Button className='button' type="primary" size="small" onClick={copyInvitationUrl} style={{ width: 176, height: 45 }}>
                Gmail
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteModal;