import React, { useEffect, useState } from 'react';
import './Toast.less';
import { CloseCircleOutlined } from '@ant-design/icons';

interface ToastProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const Toast = ({ children, isOpen, onClose }: ToastProps) => {
  const [visible, setVisible] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setVisible(true); // Set visible to true immediately to render the component
      // Delay adding the animation class to trigger the slide-in effect
      setTimeout(() => setAnimateIn(true), 10);
    } else {
      setAnimateIn(false); // Start slide-out animation
      // Delay removing the component until the animation is complete
      const timer = setTimeout(() => setVisible(false), 300); // Match the duration of your CSS transition
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    visible ? (
      <div className={`toast ${animateIn ? 'toast--open' : ''}`}>
        <div className="toast__container">
          <CloseCircleOutlined onClick={onClose} className='toast__close' />
          <div className='toast__title'>PRO Tip!</div>
          <div className="toast__content">
            {children}
          </div>
        </div>
      </div>
    ) : <></>
  );
};
