import React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { UserContext } from '../../data/userContext';
import { Typography, Layout, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';
import checkMarkIcon from '../../assets/check-mark-icon.png';
import backgroundGreenDots from '../../assets/background-green-dots.svg';

import './thankYou.less';

declare global {
  interface Window {
    dataLayer: any[];
  }
}




const ThankYou = () => {

  const { Title } = Typography;
  const { Content } = Layout;
  const userContext = useContext(UserContext);
  console.log(userContext);

  
  return (
    <>
      <Layout className="layout">
        <HeaderResponsive />
        <Content className="content">
          <div className="thank-you">
            <img src={checkMarkIcon} alt="Check Mark Icon" className="image" />
            <Title className="title">Thank you for your purchase!</Title>
            <p className="semi-title">
              Your order was completed successfully, you should receive a confirmation email shortly.
            </p>
            <Link to="/spikes/streams" className="link">
              <Button className="custom-button">Continue to Spikes</Button>
            </Link>
          </div>
        </Content>
        <FooterResponsive />
      </Layout>
    </>
  );
};

export default ThankYou;
