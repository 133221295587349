/* eslint-disable react/no-unknown-property */
import { useMemo } from 'react';
import Space from 'antd/lib/space';
import { Card, Typography } from 'antd';
import { COLORS } from '../../../themes/colors';

export const TermsCard = () => {
  const styles = useMemo(() => {
    return {
      container: {
        borderRadius: '20px',
        backgroundColor: COLORS.HIGHLIGHT_CARD_BACKGROUND,
        opacity: 0.7,
      },
      title: {
        fontWeight: 600,
      },
      containerBody: {
        padding: 0,
      },
      image: {
        filter: 'blur(8px)',
        borderRadius: 2,
      },
      overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'black',
      },
      login: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
      },
      termsText: {
        color: COLORS.WHITE,
        texttransform: 'uppercase',
        fontSize: 16,
        fontWeight: 500,
        width: 1200,
        textAlign: 'start',
        lineHeight: '22px',
      },
    } as const;
  }, []);

  return (
    <Card
      bordered={false}
      bodyStyle={styles.containerBody}
      style={styles.container}
    >
      <div style={styles.overlay}></div>
      <Space style={styles.login} direction="horizontal" align="baseline">
        <Typography style={styles.termsText}>
          <p>
            These Terms and Conditions (
            <span style={styles.title}>&quot;Terms&quot;</span>,{' '}
            <span style={styles.title}>&quot;Agreement&quot;</span>) apply to
            your access to, and/or use of, spikes.studio, its subdomains, as well as
            any tools or functionalities made available via the foregoing
            including any of its products or services (
            <span style={styles.title}>&quot;Website&quot;</span> or{' '}
            <span style={styles.title}>&quot;Services&quot;</span>). You must
            read this Agreement carefully, and by accessing or using the
            Website, you confirm that you have read, understood, and agree to be
            bound by:
          </p>
          <ol type={'a'}>
            <li>These Terms and Conditions in their entirety; and</li>
            <li>
              Any supplemental terms or policies in this Agreement which are
              stated to be incorporated into this Agreement.
            </li>
          </ol>

          <p>
            IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS OF THIS
            AGREEMENT, YOU MAY NOT ACCESS OR USE THE WEBSITE OR SERVICES
          </p>

          <p>
            This Agreement is a binding agreement between you (
            <span style={styles.title}>&quot;User&quot;</span>,{' '}
            <span style={styles.title}>&quot;you&quot;</span> or{' '}
            <span style={styles.title}>&quot;your&quot;</span>) and
            <span style={styles.title}> PLAYSTREAM LTD</span>., an Israeli
            corporation located at Nachal Netafim 20, Modiin, 7174205, Israel (
            <span style={styles.title}>&quot;spikes.studio&quot;</span>,{' '}
            <span style={styles.title}>&quot;we&quot;</span>,{' '}
            <span style={styles.title}>&quot;us&quot;</span>, and{' '}
            <span style={styles.title}>&quot;our&quot;</span>). However, at
            spikes.studio sole discretion, any spikes.studio obligation may be performed
            (in whole or in part), and any spikes.studio right or remedy may be
            exercised (in whole or in part), by a spikes.studio Affiliate (defined
            below).
          </p>

          <p>
            spikes.studio reserves the right to modify this Agreement at any time by
            posting the modified Agreement at{' '}
            <a
              href="https://www.spikes.studio/terms"
              className="primary-color"
              target="_blank"
              rel="noreferrer"
            >
              www.spikes.studio/terms
            </a>
            . Such modifications will be effective ten (10) days after such
            posting (unless we specify a later effective date). However, we
            undertake no obligation to update, amend or clarify information on
            the Website including, without limitation, pricing information,
            except as required by law. No specified update or refresh date
            applied on the Website should be taken to indicate that all
            information on the Website or on any related Service has been
            modified or updated.
          </p>
          <h3>1. DEFINITIONS AND INTERPRETATION</h3>
          <p>
            This Agreement contains a range of capitalized terms, some of which
            are defined in this Section and some of which are defined elsewhere.
            The Section headings in this Agreement are for convenience of
            reading only and may not to be used or relied upon for interpretive
            purposes.
          </p>
          <p>
            <span style={styles.title}>&quot;spikes.studio Affiliate&quot;</span>{' '}
            means, with respect to spikes.studio, any organization or entity
            controlling, controlled by, or under common control with spikes.studio,
            where &quot;control&quot; means the possession, directly or
            indirectly, of the power to direct or cause the direction of the
            management and policies of such person, organization or entity,
            whether through the ownership of voting securities, by contract, or
            otherwise.
          </p>
          <p>
            <span style={styles.title}> &quot;spikes.studio Materials&quot;</span>{' '}
            means, collectively, the Website and any Content appearing or
            displayed on or in the Website.
          </p>
          <p>
            <span style={styles.title}>&quot;Content&quot;</span> means any
            software, code, text, data, information, images, graphics, sounds,
            videos, audio clips, links, and/or similar materials and content.
          </p>

          <p>
            <span style={styles.title}>“Dispute” </span> means any claim,
            dispute or controversy under, or otherwise in connection with, this
            Agreement.
          </p>
          <p>
            <span style={styles.title}>
              {' '}
              &quot;Intellectual Property&quot;{' '}
            </span>
            means any and all inventions, discoveries, improvements, works of
            authorship, technical information, data, technology, know-how,
            show-how, designs, drawings, utility models, topography and
            semiconductor mask works, specifications, formulas, methods,
            techniques, processes, databases, computer software and programs
            (including object code, source code, APIs, and non-literal aspects),
            algorithms, architecture, records, documentation, and other similar
            intellectual property, in any form and embodied in any media.
          </p>
          <p>
            <span style={styles.title}>
              {' '}
              &quot;Intellectual Property Rights&quot;{' '}
            </span>
            means any and all rights, titles, and interests in and to
            Intellectual Property (under any jurisdiction or treaty, whether
            protectable or not, and whether registered or unregistered) and
            includes without limitation patents, copyright and similar
            authorship rights, personal rights (such as Moral Rights, rights of
            privacy, and publicity rights), architectural, building and location
            (and similar geography-based) rights, mask work rights, trade secret
            and similar confidentiality rights, design rights, industrial
            property rights, trademark, trade name, trade dress and similar
            branding rights, as well as: (a) all applications, registrations,
            renewals, extensions, continuations, continuations-in-part,
            divisions or reissues of the foregoing rights; and (b) all goodwill
            associated with the foregoing rights.
          </p>
          <p>
            <span style={styles.title}> &quot;Law&quot;</span> means any
            federal, state, foreign, regional or local statute, regulation,
            ordinance, or rule, in any jurisdiction.
          </p>
          <p>
            <span style={styles.title}> &quot;Moral Rights&quot;</span> means
            any rights of paternity or integrity, or any right to claim
            authorship of a work, to object to any distortion, mutilation or
            other modification of, or other derogatory action in relation to,
            any work, whether or not such would be prejudicial to the honor or
            reputation of the creator of the work, and any similar right,
            existing under judicial or statutory Law of any jurisdiction, or
            under any treaty.
          </p>
          <p>
            <span style={styles.title}> &quot;Privacy Policy&quot; </span> means
            Spikes privacy policy currently available at{' '}
            <a
              href={'https://www.spikes.studio/privacy'}
              className={'primary-color'}
              target={'_blank'}
              rel="noreferrer"
            >
              www.spikes.studio/privacy
            </a>
            .
          </p>
          <h3>2. WEBSITE ACCESS</h3>
          <p>
            For such time as this Agreement is in effect, we hereby grant you a
            personal, limited, nonexclusive, non-assignable, non-sublicensable,
            revocable right to access and use the Website solely for your own
            personal and non-commercial use, and provided that you comply with
            this Agreement. Except for the foregoing right, spikes.studio does not
            grant you any right or license to any of Spikes or a third party
            Intellectual Property Rights.
          </p>
          {/* <h3>3. PAYMENT AND FEES</h3>
          <ol type={'a'}>
            <li>
                            We allow, under certain circumstances, monetary transfers from one User to another
                            as part of our Service. The user hereby agree that every monetary transaction made
                            by him/her, as part of his/her use of the Service, gives rise to 10% remuneration
                            payment out of the transaction to spikes.studio (&quot;Service Fee&quot;).

            </li>
            <li>
                            In addition to the Service Fee, User hereby agrees that every monetary transaction
                            made or received by him/her, as part of his/her use of the Service, gives rise to a
                            separate fee payment by User to PayPal in accordance with its rate charges.
            </li>
          </ol> */}
          <h3>3. ADVERTISEMENTS</h3>
          <p>
            During use of the Website, you may enter into correspondence with or
            participate in promotions of advertisers or sponsors showing their
            goods or services through the Website. Any such activity, and any
            terms, conditions, warranties or representations associated with
            such activity, is solely between you and the applicable third-party.
            We shall have no liability, obligation or responsibility for any
            such correspondence, purchase or promotion between you and any such
            third-party.
          </p>
          <h3>4. USAGE RESTRICTIONS</h3>
          <p>
            As a condition to your right to access and use the Website, you
            shall not (and shall not permit or encourage any third party to) do
            any of the following, in whole or in part: (a) for any unlawful
            purpose; (b) to solicit others to perform or participate in any
            unlawful acts; (c) to violate any international, federal, provincial
            or state regulations, rules, laws, or local ordinances; (d) to
            infringe upon or violate our intellectual property rights or the
            intellectual property rights of others; (e) to harass, abuse,
            insult, harm, defame, slander, disparage, intimidate, or
            discriminate based on gender, sexual orientation, religion,
            ethnicity, race, age, national origin, or disability; (f) to submit
            false or misleading information; (g) to upload or transmit viruses
            or any other type of malicious code that will or may be used in any
            way that will affect the functionality or operation of the Service
            or of any related website, other websites, or the Internet; (h) to
            collect or track the personal information of others; (i) to spam,
            phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene
            or immoral purpose; or (k) to interfere with or circumvent the
            security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website for violating any of the
            prohibited uses.
          </p>
          <h3>5. LINKING</h3>
          <p>
            spikes.studio permits you to link to the Website provided that: (i) you
            link to (but do not replicate) any page on this Website; (ii) the
            hyperlink text shall accurately describe the Content as it appears
            on the Website; (iii) you shall not misrepresent your relationship
            with spikes.studio or present any false information about spikes.studio, and
            shall not imply in any way that we are endorsing you or any services
            or products, unless we have given you our express prior written
            consent to do so; (iv) you shall not link from a website which
            prohibits linking to third parties; (v) the website from which you
            link to the Website does not contain Content that is offensive or
            controversial (both as determined at our discretion), that
            infringes, misappropriates, or violates any Intellectual Property
            Rights; and (vi) you, and your website, comply with this Agreement
            and applicable Law.
          </p>
          <h3>6. LINKS TO OTHER WEBSITES</h3>
          <p>
            Although this Website may link to other websites, we are not,
            directly or indirectly, implying any approval, association,
            sponsorship, endorsement, or affiliation with any linked website,
            unless specifically stated herein. Some of the links on the Website
            may be &quot;affiliate links&quot;. This means if you click on the
            link and purchase an item, spikes.studio will receive an affiliate
            commission. We are not responsible for examining or evaluating, and
            we do not warrant the offerings of, any businesses or individuals or
            the content of their websites. We do not assume any responsibility
            or liability for the actions, products, services, and content of any
            other thirdparties. You should carefully review the legal statements
            and other conditions of use of any website which you access through
            a link from this Website. Your linking to any other off-site
            websites is at your own risk.
          </p>
          <h3>7. THIRD PARTY CONTENT</h3>
          <p>
            The Website may display, link to, and/or otherwise allow you to
            view, access, or interact with, Content from third parties and other
            sources that are not owned or controlled by spikes.studio (such Content,{' '}
            <span style={styles.title}>&quot;Third Party Content&quot;</span> ).
            The Website may also enable you to communicate with the related
            third parties. The display or communication to you of such Third
            Party Content does not (and shall not be construed to) in any way
            imply, suggest, or constitute any sponsorship, endorsement, or
            approval by spikes.studio of such Third Party Content or third party, or
            by such third party of spikes.studio, and nor any affiliation between
            spikes.studio and such third party. spikes.studio does not assume any
            responsibility or liability for Third Party Content, or any third
            party terms of use, privacy policies, actions, omissions, or
            practices. Please read the terms of use and privacy policies of any
            third party that you interact with before you engage in any such
            activity.
          </p>
          <h3>8. INTELLECTUAL PROPERTY</h3>
          <p>
            As between you and spikes.studio, spikes.studio is and shall remain the sole
            and exclusive owner of all right, title, and interest (including,
            but not limited to, all Intellectual Property Rights) in and to:
          </p>
          <ol type={'a'}>
            <li>
              The spikes.studio Materials, as well as any computer programs
              (including APIs), databases, or other Intellectual Property
              underlying, operating, or otherwise embodied in any spikes.studio
              Materials; and
            </li>
            <li>
              The trademarks, service marks, trade names, service names, trade
              dress, symbols, brands, and logos displayed on contained on or in
              any spikes.studio Materials ({' '}
              <span style={styles.title}>&quot;Trademarks&quot;</span>).
            </li>
          </ol>
          <p>
            All user-generated content, which includes but is not limited to live streams, segments of streams, personal videos uploaded from the user&#39;s device, and clips imported from other streaming platforms like Twitch or YouTube, remains under the sole and exclusive ownership of the user on this platform.
          </p>
          <p>
            You acknowledge that the items in paragraphs (a) and (b) above may
            be protected by Intellectual Property Rights treaties and Laws.
            Without limiting paragraph (b) above the name spikes.studio, and its
            logo, are the Trademarks of spikes.studio or any spikes.studio Affiliate.
            Other Trademarks displayed on contained on or in any spikes.studio
            Materials may be owned by third parties.
          </p>
          <p>
            Except for the limited right granted to you in Section 2 (Website
            Access), spikes.studio and its licensors reserve all rights in and to
            their respective Intellectual Property Rights.
          </p>
          <h3>9. WARRANTY DISCLAIMERS</h3>
          <p>
            THE spikes.studio MATERIALS ARE PROVIDED AND MADE AVAILABLE TO YOU ON AN
            &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITH ALL
            FAULTS AND AT YOUR SOLE RISK, AND WITHOUT ANY REPRESENTATION,
            WARRANTY, GUARANTEE OR CONDITION OF ANY KIND WHATSOEVER, WHETHER
            EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, SATISFACTORY QUALITY, QUIET POSSESSION, TITLE,
            NON-INFRINGEMENT, OR THAT OTHERWISE ARISE FROM A COURSE OF
            PERFORMANCE OR DEALING, OR USAGE OF TRADE, ALL OF WHICH ARE HEREBY
            DISCLAIMED BY spikes.studio AND ITS LICENSORS AND SUPPLIERS. NO ORAL OR
            WRITTEN INFORMATION OR ADVICE GIVEN BY spikes.studio OR AN spikes.studio
            REPRESENTATIVE, SHALL CREATE A REPRESENTATION, WARRANTY, GUARANTEE,
            OR CONDITION.
          </p>

          <p>
            IN ADDITION, NEITHER spikes.studio NOR ITS LICENSORS OR SUPPLIERS MAKE
            ANY REPRESENTATION, WARRANTY, GUARANTEE, OR CONDITION:
          </p>
          <ol type={'A'}>
            <li>
              REGARDING THE EFFECTIVENESS, USEFULNESS, RELIABILITY,
              AVAILABILITY, TIMELINESS, ACCURACY, OR COMPLETENESS OF ANY
              spikes.studio MATERIALS;
            </li>
            <li>
              THAT YOUR USE OF, OR RELIANCE UPON, spikes.studio MATERIALS WILL MEET
              YOUR REQUIRMENTS OR EXPECTATIONS;
            </li>
            <li>
              THAT spikes.studio MATERIALS WILL BE UNINTERRUPTED, SECURE, ERROR-FREE
              OR VIRUS-FREE, OR THAT DEFECTS WILL BE CORRECTED; OR
            </li>
            <li>
              REGARDING THE SATISFACTION OF, OR COMPLIANCE WITH, ANY GOVERNMENT
              REGULATIONS OR STANDARDS.
            </li>
          </ol>
          <p>
            <span style={styles.title}>
              {' '}
              The above disclaimers apply to the maximum extent permitted by
              applicable Law.{' '}
            </span>{' '}
            You may have legal rights in your country of residence which would
            prohibit the above disclaimers from (fully or partially) applying to
            you (for example, some jurisdictions Laws do not allow the
            disclaimer of certain implied warranties or conditions, and do not
            allow limitations to be imposed on statutory rights), and, to the
            extent the above disclaimers are prohibited, then you and spikes.studio
            agree that they will not apply to you.
          </p>
          <h3>10. LIMITATION OF LIABILITY</h3>
          <p>
            EXCEPT FOR A PARTY LIABILITY FOR BREACH OF THE OTHER PARTY
            INTELLECTUAL PROPERTY RIGHTS, IN NO EVENT SHALL EITHER PARTY BE
            LIABLE UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT, FOR:
          </p>
          <ol type={'A'}>
            <li>
              ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE
              DAMAGES;
            </li>
            <li>
              {' '}
              ANY LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF REVENUE, LOSS OF
              ANTICIPATED SAVINGS, OR OTHER ECONOMIC LOSS;
            </li>
            <li>
              {' '}
              ANY LOSS OF, OR DAMAGE TO, DATA, REPUTATION, OR GOODWILL; AND/OR
            </li>
            <li>
              THE COST OF COVER, OR OF PROCURING ANY SUBSTITUTE GOODS OR
              SERVICES.
            </li>
          </ol>
          <p>
            THE COMBINED AGGREGATE LIABILITY OF spikes.studio AND ALL spikes.studio
            AFFILIATES UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT
            SHALL NOT EXCEED THE GREATER OF{' '}
            <span style={styles.title}>ONE US DOLLARS (US$ 1)</span> , OR ANY
            AMOUNTS ACTUALLY PAID IN CASH BY YOU TO spikes.studio FOR THE PRIOR ONE
            MONTH PERIOD PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO
            SUCH LIABILITY.
          </p>

          <p>
            THE FOREGOING EXCLUSIONS AND LIMITATIONS SHALL APPLY: (i) TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW (SUCH AS, FOR EXAMPLE, IF
            A JURISDICTION DOES NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, OR OF LIABILITY
            FOR PERSONAL INJURY OR DEATH CAUSED BY NEGLIGENCE); (ii) EVEN IF A
            PARTY HAS BEEN ADVISED, OR SHOULD HAVE BEEN AWARE, OF THE
            POSSIBILITY OF LOSSES, DAMAGES, OR COSTS; (iii) EVEN IF ANY REMEDY
            IN THIS AGREEMENT FAILS OF ITS ESSENTIAL PURPOSE; AND (iv)
            REGARDLESS OF THE THEORY OR BASIS OF LIABILITY, INCLUDING WITHOUT
            LIMITATION BREACH OF WARRANTY, NEGLIGENCE, MISREPRESENTATION, STRICT
            LIABILITY, OR OTHER CONTRACT, TORT OR STATUTORY LIABILITY.
          </p>
          <h3>11. INDEMNIFICATION</h3>
          <p>
            If any third party (including, but not limited to, a regulatory or
            governmental authority) makes or institutes any demand, claim, suit,
            action or proceeding against spikes.studio, an spikes.studio Affiliate,
            and/or any of our or their respective directors, officers,
            employees, or representatives (each, an{' '}
            <span style={styles.title}>&quot;Indemnitee&quot;</span>), and it is
            based upon or arises from any breach by you under this Agreement (in
            each case, an{' '}
            <span style={styles.title}>&quot;Indemnity Claim&quot;</span>),
            then, upon written request by spikes.studio (to be decided in its sole
            discretion), you agree to assume full control of the defense and
            settlement of the Indemnity Claim; provided, however, that (a)
            spikes.studio reserves the right, at any time thereafter, to take over
            full or partial control of the defense and/or settlement of the
            Indemnity Claim, and in such cases you agree to reasonably cooperate
            with spikes.studio defense counsel and activities at your own cost and
            expense; and (b) you shall not settle any Indemnity Claim, or admit
            to any liability thereunder, without the express prior written
            consent of the Indemnitee(s).
          </p>
          <p>
            In addition, and regardless of whether (or the extent to which) you
            controlled or participated in the defense and/or settlement of an
            Indemnity Claim, you agree to indemnify and hold harmless the
            Indemnitee(s) for and against: (A) any costs and expenses (including
            reasonable attorneys fees) incurred by the Indemnitee(s) in the
            defense of the Indemnity Claim; and (b) any amounts awarded against,
            or imposed upon, the Indemnitee(s) under such Indemnity Claim, or
            otherwise paid in settlement of the Indemnity Claim (including, but
            not limited to, any fines or penalties).
          </p>
          <h3>12. GOVERNING LAW; JURISDICTION AND VENUE </h3>
          <p>
            The formation, interpretation, and performance of this Agreement and
            any disputes arising out of it shall be governed by the substantive
            and procedural laws of Israel without regard to its rules on
            conflicts or choice of law and, to the extent applicable, the laws
            of Israel. The exclusive jurisdiction and venue for actions related
            to the subject matter hereof shall be the courts located in Israel,
            and you hereby submit to the personal jurisdiction of such courts.
            You hereby waive any right to a jury trial in any proceeding arising
            out of or related to this Agreement. The United Nations Convention
            on Contracts for the International Sale of Goods does not apply to
            this Agreement.
          </p>
          <p>
            Regardless of any Law to the contrary, any claim or cause of action
            arising under, or otherwise in connection with, this Agreement must
            be filed within ONE (1) YEAR after such claim or cause of action
            arose, or else you agree that such claim or cause of action will be
            barred forever.
          </p>
          <h3>13. TERM AND TERMINATION </h3>
          <p>
            This Agreement is effective until terminated by spikes.studio or you.
            spikes.studio, in its sole discretion, has the right to terminate this
            Agreement and/or your access to the Site, or any part thereof,
            immediately at any time and with or without cause (including,
            without any limitation, for a breach of this Agreement). spikes.studio
            shall not be liable to you or any third party for termination of the
            Site, or any part thereof. If you object to any term or condition of
            this Agreement, or any subsequent modifications thereto, or become
            dissatisfied with the Site in any way, your only recourse is to
            immediately discontinue use of the Site. Upon termination of this
            Agreement, you shall cease all use of the Site. This Section 14 and
            Sections 9 (Intellectual Property), 10 (Warranty Disclaimers), 11
            (Limitation of Liability), 12 (Indemnification), 15.7 (Privacy), and
            15.10 (Relationship) shall survive termination of this Agreement.
          </p>
          <h3>14. MISCELLANEOUS</h3>
          <ol className={'ol-miscellaneous'}>
            <li>
              <span className={'text-underline'}>Entire Agreement.</span> This
              Agreement (together with its Schedules) represents the entire
              agreement between you and spikes.studio with respect to the subject
              matter hereof, and supersedes and replaces any and all prior and
              contemporaneous oral and/or written agreements, understandings and
              statements between you and spikes.studio with respect to such subject
              matter. You acknowledge and agree that in entering into this
              Agreement you have not relied on any statement or representation
              (whether negligently or innocently made) not expressly set out in
              this Agreement, such as statements and explanations in any FAQs,
              summaries or explanatory guides regarding this Agreement, or other
              marketing material on the Website
            </li>
            <li>
              <span className={'text-underline'}>Age Representation.</span> You
              represent that you are at least thirteen (13) years old, but in
              any event of a legal age to form a binding contract. Children
              under the age of thirteen (13) are not permitted to access or use
              the Website. If you are between thirteen (13) and eighteen (18)
              years old, your review of (and any performance under) this
              Agreement must involve your parent or guardian.
            </li>
            <li>
              <span className={'text-underline'}>
                Language; Electronic Contract.
              </span>{' '}
              The language of this Agreement is expressly agreed to be the
              English language. By entering into the Agreement, you hereby
              irrevocably waive, to the maximum extent legally permitted, any
              Law applicable to you requiring that the Agreement be localized to
              meet your language (as well as any other localization
              requirements), or requiring an original (non-electronic) signature
              or delivery or retention of non-electronic records. We may be able
              (but are not obligated) to provide you with copies of this
              Agreement on written request; however, please be sure to print a
              copy of this Agreement for your own records.
            </li>
            <li>
              <span className={'text-underline'}>Assignment.</span> spikes.studio
              may assign this Agreement (or any of its rights and/or obligations
              hereunder) without your consent, and without notice or obligation
              to you. This Agreement is personal to you, and, except as
              permitted by this Agreement, you may not assign (or in any other
              way transfer) this Agreement (or any of its obligations or rights
              hereunder) without spikes.studio express prior written consent. Any
              prohibited assignment shall be null and void.
            </li>
            <li>
              <span className={'text-underline'}> Severability. </span> If any
              provision of this Agreement is held by a court of competent
              jurisdiction to be illegal, invalid or unenforceable, then: (a)
              the remaining provisions of this Agreement shall remain in full
              force and effect; and (b) the Parties hereto agree that the court
              making such determination shall have the power to limit the
              provision, to delete specific words or phrases, or to replace the
              provision with a provision that is legal, valid and enforceable
              and that most closely approximates the original legal intent and
              economic impact of such provision, and this Agreement shall be
              enforceable as so modified in respect of such jurisdiction. In the
              event such court does not exercise the power granted to it as
              aforesaid, then such provision will be ineffective solely as to
              such jurisdiction (and only to the extent and for the duration of
              such illegality, invalidity or unenforceability), and will be
              substituted (in respect of such jurisdiction) with a valid, legal
              and enforceable provision that most closely approximates the
              original legal intent and economic impact of such provision.
            </li>
            <li>
              <span className={'text-underline'}>Remedies.</span> Except as may
              be expressly stated otherwise in this Agreement, no right or
              remedy conferred upon or reserved by any party under this
              Agreement is intended to be, or shall be deemed, exclusive of any
              other right or remedy under this Agreement, at law or in equity,
              but shall be cumulative of such other rights and remedies.
            </li>
            <li>
              <span className={'text-underline'}>Privacy.</span> We collect
              certain data and information about you in connection with your
              access and use of the Website. We will use, store and otherwise
              process such data and information in accordance with our Privacy
              Policy, which is hereby incorporated into, and made a part of,
              this Agreement by reference.
            </li>
            <li>
              <span className={'text-underline'}> Spam. </span> You agree not
              to, and will not, use the communications systems provided by the
              Site to send unauthorized commercial communications and you shall
              be solely responsible and liable for any such unauthorized
              communications.
            </li>
            <li>
              <span className={'text-underline'}> Waiver.</span> No failure or
              delay on the part of any party in exercising any right or remedy
              under this Agreement shall operate as a waiver thereof, nor shall
              any single or partial exercise of any such right or remedy
              preclude any other or further exercise thereof or the exercise of
              any other right or remedy. Any waiver granted hereunder must be in
              writing (for waivers by you, emails will be acceptable; for
              waivers by spikes.studio, the writing must be duly signed by an
              authorized representative of spikes.studio), and shall be valid only
              in the specific instance in which given.
            </li>
            <li>
              <span className={'text-underline'}>Relationship.</span> The
              relationship of the parties is solely that of independent
              contractors. Nothing in this Agreement shall be deemed to create
              any employment, fiduciary, joint venture, agency or other
              relationship between the parties.
            </li>
            <li>
              <span className={'text-underline'}>Notices.</span> You agree that
              spikes.studio may send you notices by email and/or by regular mail.
              Except as stated otherwise in this Agreement or required by law
              applicable to you, you agree to send all notices to spikes.studio, to:{' '}
              <a href={'mailto:support@spikes.studio'} className={'primary-color'}>
                support@spikes.studio
              </a>
              .
            </li>
            <li>
              <span className={'text-underline'}>Epic Games.</span> For the
              avoidance of doubt, User acknowledges that spikes.studio is not
              affiliated with Epic Games, Inc. (&quot;Epic Games&quot;) in any
              manner. spikes.studio is not responsible for, and expressly disclaims,
              and you expressly release spikes.studio from, any and all liability
              whatsoever from any controversies, claims, suits, harm, losses
              and/or damages arising from and/or in any way related to Epic
              Games decisions, actions or failure to act
            </li>
            <li>
              <span className={'text-underline'}>
                {' '}
                No Third Party Beneficiaries.
              </span>{' '}
              Except as otherwise expressly provided in this Agreement (such as
              spikes.studio Affiliates, spikes.studio licensors and suppliers, and
              Indemnitees), there shall be no third-party beneficiaries of, or
              under, this Agreement, and no third party shall be entitled to
              enforce any of these terms and conditions.
            </li>

          </ol>
          <h3>15. Refund Policy</h3>
          <ol className={'ol-miscellaneous'}>
            <li>
              <span className={'text-underline'}> Refund Policy. </span>
              <span>
                At Spikes Studio, our Paid Services include an initial period with a one-time charge, followed by recurring charges. By opting for a recurring payment plan, you acknowledge responsibility for all recurring charges until cancellation. Spikes Studio may process periodic charges (e.g., monthly) without further authorization, until you terminate this authorization or change your payment method via your account settings. Notifications to terminate or modify payment methods will not affect charges already in process. Unless auto-renewal is deactivated in account settings, Paid Services automatically renew for the same term length at the current non-promotional rate. Changes or cancellations of Paid Services can be made anytime in account settings. Upon termination of a Paid Service, you may use your subscription until the end of the current term, but no renewal will occur post-term. No prorated refunds are provided for any portion of the subscription fee for the current period. To avoid recurring charges, cancel the Paid Services through your account settings or terminate your Spikes Studio account before the end of the subscription period. Paid Services cannot be terminated before the end of the period already paid for, and Spikes Studio does not refund fees already paid, except as explicitly stated in this agreement.
              </span>
            </li>
          </ol>

        </Typography>
      </Space>
    </Card>
  );
};

export default TermsCard;
