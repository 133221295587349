import { Space } from 'antd';
import { isBrowser } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Header } from '../../components/header';
import { PrivacyCard } from '../../components/privacy-card';
import { MainLayout } from '../../layouts';
import { Helmet } from 'react-helmet';
import { STRUCTURED_DATA_PRIVACY_PAGE } from '../../structured-data';

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Spikes Studio | Privacy Policy</title>
        <link rel="canonical" href="https://spikes.studio/privacy" />
        <script type="application/ld+json">
          {JSON.stringify(STRUCTURED_DATA_PRIVACY_PAGE)}
        </script>
      </Helmet>
      <MainLayout
        header={isBrowser ? <Header title={'Privacy Policy'} /> : null}
      >
        <Space direction='vertical' size='large' className='w-100'>
          <PrivacyCard />
        </Space>
      </MainLayout>
    </>
  );
};

export default PrivacyPage;
