import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { ReactComponent as IconSiteSearch } from '../../assets/site_search.svg';
import { ReactComponent as IconChevron } from '../../assets/chevron_right.svg';
import { ReactComponent as IconPage } from '../../assets/icon_page.svg';
import { ReactComponent as IconHashtag } from '../../assets/hashtag.svg';
import { ReactComponent as IconYoutubeChannel } from '../../assets/youtube_channel.svg';
import { ReactComponent as IconVideoLibrary } from '../../assets/video_library.svg';
import { ReactComponent as IconCamera } from '../../assets/camera.svg';
import './SiteSearchModal.less';
import Modal from 'react-modal';
import useAxios from 'axios-hooks';
import axios from 'axios';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';
import { checkStreamIsAvailableToRender } from '../../utils';

const PAGES_LIST = [
  {
    label: 'AI Clip Generator',
    route: '/spikes/videos'
  },
  {
    label: 'Live Streams AI', // Remove for Google users
    route: '/spikes/streams',
    onlyTwitch: true
  },
  {
    label: 'Add Captions',
    route: '/spikes/transcribe'
  },
  // {
  //   label: 'Social Analytics', // Add later with logic
  //   route: '/spikes/social'
  // },
  {
    label: 'Dashboard',
    route: '/dashboard'
  },
  {
    label: 'Tutorials',
    route: '/tutorial'
  },
  {
    label: 'API Dashboard',
    route: '/api'
  },
  {
    label: 'Loyalty Program',
    route: '/spikes/loyalty-program'
  },
  {
    label: 'Invite a Friend',
    route: '/affiliate-program'
  },
  {
    label: 'About',
    route: '/about'
  },
]

const highlightText = (text: string, highlight: string, isChannelName?: boolean) => {
  if (!highlight) return text;
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? <span key={index} className={`site-search__highlighted-symbol ${isChannelName && 'channel'}`}>{part}</span> : part
  );
};

export interface ISiteSearchModalProps {
  open: boolean;
  onClose: () => void,
}

export const SiteSearchModal = ({ open, onClose }: ISiteSearchModalProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [pagesList, setPagesList] = useState(PAGES_LIST);
  const [rerender, setRerender] = useState(1);
  const [allProjects, setAllProjects] = useState<null | IVideo[]>(null);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;

  useEffect(() => {
    if (isGoogleUser) {
      const pages = pagesList.filter(page => !page?.onlyTwitch);
      setPagesList(pages);
    }
  }, [isGoogleUser]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await axios.get('/streamer/uploads');
        if (response?.data) {
          const availableProjects = response?.data.filter((project: IStream) => checkStreamIsAvailableToRender(project));
          if (availableProjects) {
            setAllProjects(availableProjects as IVideo[]);
          } else {
            setAllProjects([]);
          }
        } else {
          setRerender(prev => prev + 1);
        }

      } catch (error) {
        setRerender(prev => prev + 1);
      }
    }

    getProjects();
  }, [rerender]);



  const handleCloseModal = () => {
    onClose();
  }

  const filteredPages = searchQuery ? pagesList.filter((page) =>
    page.label.toLowerCase().includes(searchQuery.toLowerCase())
  ) : pagesList.slice(0, 3);

  const filteredProjects = useMemo(() => {
    if (allProjects && allProjects.length) {
      if (!searchQuery) {
        return allProjects.slice(0, 3);
      } else {
        const filtered = allProjects.filter((video) =>
          video.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          video.channel_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return filtered;
      }
    } else {
      return [];
    }
  }, [searchQuery, allProjects])


  return (
    <>
      <Modal
        isOpen={open}
        className="Modal"
        overlayClassName="Overlay Site-Search-Overlay"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
      >
        <div className="glass-modal site-search">
          <div className='site-search__search-bar'>
            <IconSiteSearch className='site-search__search-icon' />
            <input value={searchQuery} onChange={e => setSearchQuery(e.target.value)} className='site-search__input' placeholder='Search website' autoFocus />
            <div onClick={handleCloseModal} className='site-search__escape-tag'>ESC</div>
          </div>
          <div className="site-search__content scrollbar">
            <div className='site-search__sections-wrapper'>
              {filteredPages && filteredPages.length > 0 &&
                <div className='site-search__section-with-title'>
                  <div className="site-search__section-title">
                    <span>Navigation</span>
                    <div className='site-search__section-title-separator' />
                  </div>

                  <div className="site-search__section">
                    {filteredPages.map(page => (
                      <NavigationItem key={page.label} handleCloseModal={handleCloseModal} type='page' label={page.label} route={page.route} searchQuery={searchQuery} />
                    ))}

                  </div>
                </div>
              }
              {filteredProjects && filteredProjects.length > 0 &&
                <div className='site-search__section-with-title'>
                  <div className="site-search__section-title">
                    <span>Projects</span>
                    <div className='site-search__section-title-separator' />
                  </div>
                  <div className="site-search__section">
                    {filteredProjects.map(project => (
                      <ProjectItem key={project.id} handleCloseModal={handleCloseModal} type='project' project={project} searchQuery={searchQuery} />
                    ))}
                  </div>
                </div>
              }
            </div>

            {(!filteredPages || filteredPages?.length === 0) && (!filteredProjects || filteredProjects?.length === 0) &&
              <div className='site-search__no-results'>
                <span>No results for &quot;<span style={{ color: '#eee' }}>{searchQuery}</span>&quot;</span>
              </div>
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

interface INavigationItemProps {
  type: 'page'
  label: string;
  route: string;
  searchQuery: string;
  handleCloseModal: () => void;
}
const NavigationItem = ({ type, label, route, searchQuery, handleCloseModal }: INavigationItemProps) => {
  const navigate = useNavigate();
  const renderIcon = () => {
    switch (type) {
      case 'page':
        return <IconHashtag />;
      default:
        return <IconHashtag />;
    }
  }

  const handleClick = () => {
    navigate(route);
    handleCloseModal();
  }

  return (
    <div onClick={handleClick} className="site-search__navigation-item">
      <div className='site-search__item-left'>
        <div className="site-search__item-type-tag">
          {renderIcon()}
        </div>
        <span>{highlightText(label, searchQuery)}</span>
      </div>
      <IconChevron className='site-search__chevron' />
    </div>
  )
}

interface IProjectItemProps {
  type: 'project'
  project: IVideo;
  searchQuery: string;
  handleCloseModal: () => void;
}
const ProjectItem = ({ type, project, searchQuery, handleCloseModal }: IProjectItemProps) => {
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (type) {
      case 'project':
        return <IconCamera className='site-search__icon-project' />;
      default:
        return <IconCamera className='site-search__icon-project' />;
    }
  }

  const handleOpenProject = () => {
    navigate(`/spikes/project?source=upload&id=${project.id}`);
  };

  const handleClick = () => {
    handleOpenProject();
    handleCloseModal();
  }

  return (
    <div onClick={handleClick} className="site-search__navigation-item site-search__project-item">
      <div className='site-search__item-left'>
        <div className="site-search__item-type-tag">
          {renderIcon()}
        </div>
        <div className="site-search__title-and-channel">
          {project?.title && <span className='site-search__video-name'>{highlightText(project?.title, searchQuery)}</span>}
          {project?.channel_name && <span className='site-search__channel'>
            <IconYoutubeChannel />
            <span>{highlightText(project?.channel_name, searchQuery, true)}</span>
          </span>
          }
        </div>
      </div>

      <div className="site-search__item-image">
        <img src={project?.thumbnail_url} alt='thumbnail' />
      </div>
    </div>
  )
}