import React, { useMemo, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import './SubtitlesEmoji.less';
import { SubtitleEmoji } from '../../../../data/intefaces/stream.interface';
import { COLORS } from '../../../../themes/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import EmojiPicker, { Theme, EmojiStyle } from 'emoji-picker-react';
import { emojiCategories } from '../../../../constants/content-constants';


const emojiSizeOptions = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
];

const emojiRotationOptions = [
  { value: '15', label: '15º' },
  { value: '0', label: '0' },
  { value: '-15', label: '-15º' },
];

const SubtitlesEmoji: React.FC<SubtitleEmoji> = ({
  emoji,
  fontSize,
  alignItems,
  isPreview,
  gap,
  textAlign,
  rotation,
  position,
  isHighlighted,
  onChange,
  word
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  const emojiStyle = {
    inset: alignItems,
    fontSize: fontSize,
    marginTop: position === 'above' ? 0 : gap, // Instead of margin we can just -20px from inset. This way the emoji will not push the text higher
    marginBottom: position === 'above' ? gap : 0, // Instead of margin we can just -20px from inset. This way the emoji will not push the text higher
    width: isPreview ? '100%' : 'auto',
    textAlign: textAlign,
    transform: `rotate(${rotation}deg)`
  };

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      inputContainer: {
        display: 'flex',
        width: '100%',
        padding: '10px 15px',
        background: COLORS.GRAY_CONTROLS,
        alignItems: 'center',
        gap: 22
      },
      filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
      },
      fontSizeItemsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: COLORS.SELECTOR_GRAY,
        padding: '4px 5px'
      },
      filterWrapper: {
        padding: '13px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.FILTERS_BACKGROUND_GRAY,
        borderRight: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`
      },
      colorsItem: {
        padding: '13px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.FILTERS_BACKGROUND_GRAY,
        width: '100%'
      },
      input: {
        background: COLORS.GRAY_CONTROLS,
      },
      clearIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '7px',
        marginRight: '5px',
        cursor: 'pointer',
      },
      emojiPositionButtons: {
        height: 40,
        padding: 9,
        backgroundColor: COLORS.CONTROL_BUTTON_BACKGROUND,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        gap: 10
      },
      emojiPositionItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
        cursor: 'pointer',
        fontSize: 15,
        lineHeight: '17px'
      },
      emojiPositionIndicator: {
        width: 24,
        height: 2,
        borderRadius: 10,
        backgroundColor: COLORS.GRAY_DISABLED,
        transition: '0.3s'
      },
      controlTopButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: 9,
        position: 'relative'
      },
      setButton: {
        height: '34px !important',
        maxHeight: '34px !important',
        minHeight: '34px !important',
        fontSize: 12,
        fontWeight: 400
      },
      downloadQualityBox: {
        height: '34px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY,
        cursor: 'pointer',
        position: 'relative',
        padding: '4px 6px'
      },
      downloadQualityText: {
        fontSize: '12px',
        color: COLORS.BACKGROUND_DARK_GRAY,
        fontWeigth: 400,
        whiteSpace: 'nowrap'
      },
      downloadQualityBoxDisabled: {
        backgroundColor: COLORS.GRAY_BACKGROUND,
      },
      disabledClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
      downloadQualityTextDisabled: {
        color: COLORS.GRAY_DISABLED,
      },
    } as const;
  }, []);

  // Handler for style change events
  const handleStyleChange = (type: string, value: string) => {
    if (onChange) {
      onChange(value, type);
    }
  };

  const handleShowEmojiPicker = () => {
    setOpenEmojiPicker(val => !val);
  };

  const handleChooseEmoji = (emoji: any) => {
    handleStyleChange('emoji', emoji.emoji);
    setOpenEmojiPicker(false);
  };

  const handleDeleteEmoji = () => {
    handleStyleChange('emoji', 'none');
  };

  const handleWordChange = (wrd: string) => {
    if (spanRef.current && onChange) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (wrd?.target.textContent) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange(wrd?.target.textContent, 'emoji');
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange(wrd?.target.textContent, 'emoji');
        }, 100);
      }
    }
  };


  const stylePicker = (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <div style={styles.controlTopButtons}>
          <div
            onClick={handleShowEmojiPicker}
            className='color-item'
            style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY, marginRight: 0, fontSize: 24 }}
          >
            {emoji === 'none' ? <PlusOutlined /> : emoji}
          </div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {openEmojiPicker && <EmojiPicker categories={emojiCategories} onEmojiClick={handleChooseEmoji} theme={Theme.DARK} emojiStyle={EmojiStyle.TWITTER} />}
        </div>

        <div style={styles.emojiPositionButtons}>
          <div onClick={() => handleStyleChange('emoji_captions_pos', 'above')} style={styles.emojiPositionItem}>
            {emoji !== 'none' && emoji}
            <div style={{ ...styles.emojiPositionIndicator, ...(position === 'above' ? { backgroundColor: COLORS.SUBTEXT_GRAY_LIGHT } : {}) }} />
          </div>
          <div onClick={() => handleStyleChange('emoji_captions_pos', 'below')} style={styles.emojiPositionItem}>
            <div style={{ ...styles.emojiPositionIndicator, ...(position !== 'above' ? { backgroundColor: COLORS.SUBTEXT_GRAY_LIGHT } : {}) }} />
            {emoji !== 'none' && emoji}
          </div>
        </div>
        <div
          onClick={handleDeleteEmoji}
          className='color-item'
          style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY, marginRight: 0, marginLeft: 'auto' }}
        >
          <DeleteOutlined style={{ fontSize: 26 }} />
        </div>

      </div>
      <div style={styles.filtersContainer}>
        <div style={styles.filterWrapper}>
          <div style={styles.fontSizeItemsContainer}>
            {emojiSizeOptions.map((item) => (
              <div
                onClick={() => handleStyleChange('emoji_size', item.value)}
                key={item.value}
                className={`font-size-item ${item.value === word?.emoji_size ? 'font-size-item-selected' : ''}`}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div style={styles.colorsItem}>
          <div style={styles.fontSizeItemsContainer}>
            {emojiRotationOptions.map((item) => (
              <div
                onClick={() => handleStyleChange('emoji_rotation_degrees', item.value)}
                key={item.value}
                className={`font-size-item ${Number(item.value) === Number(word?.emoji_rotation_degrees) ? 'font-size-item-selected' : ''}`}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const formatEmoji = (emoji: string | undefined) => {
    if (!emoji) return '';
    if (emoji === 'none') return '';
    return emoji;
  };

  if (!formatEmoji(emoji)) return null;

  return (
    <Tooltip
      title={stylePicker}
      trigger="click"
      placement="top"
      mouseEnterDelay={0.3}
      mouseLeaveDelay={0.1}
      overlayClassName="popup-container emoji-popup"
    >
      {/* Span element representing the subtitle word */}
      <span
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={emojiStyle}
        className={(isHighlighted && !isPreview) ? 'highlighted subtitle ' : 'subtitle'}
        // contentEditable
        // onFocus={handleWordChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={handleWordChange}
        // onBlur={handleWordChange}
        ref={spanRef}
        suppressContentEditableWarning
      >
        {formatEmoji(emoji)}
      </span>
    </Tooltip>
  );
};

export default SubtitlesEmoji;
