import { Space, Typography, Image, Progress, Tooltip } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../themes/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../data/userContext';
import { addDateEnding, changeMonthToCurrent, changeMonthToFollowing, getFirstDayOfNextMonth, getSubscriptionName, isDateBeforeToday, roundWithTwoDecimals } from '../../../utils';
import dayjs from 'dayjs';
import axios from 'axios';
import './TabMenu.less';

export interface ITabMenuProps {
  items: { label: string, route: string, icon: string, textStyle: React.CSSProperties }[];
  onTabSelected: (tabId: number) => void;
  defaultIndex: number;
  route: number;
  setRoute: (route: number) => void;
  handleCloseTour: () => void;
}

export const TabMenu = (props: ITabMenuProps) => {
  const { items, onTabSelected, defaultIndex, route, setRoute, handleCloseTour } = props;
  // const [route, setRoute] = useState<number>(defaultIndex);
  const [planName, setPlanName] = useState<string>('');
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const usedStreams = userContext?.user?.used_streams;
  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalStreams = userContext?.user?.total_streams;
  const totalMinutes = userContext?.user?.total_upload_minutes;
  const hasSubscription = userContext?.user?.subscriptions && userContext?.user?.subscriptions[0];

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userContext?.user?.subscriptions) {
      if (userContext?.user?.subscriptions[0]) {
        const fetchData = async () => {
          try {
            const response = await axios.get('/external/subscription-plans');

            const plan = response?.data?.find((plan: any) => {
              return plan.id === userContext?.user?.subscriptions[0].subscription_plan;
            });
            setPlanName(getSubscriptionName(plan));
          } catch (error: any) {
            if (error.response) {
              console.log(error.response);
            }
          }
        };
        fetchData();
      }
    } else {
      setPlanName('Free plan');
    }

  }, []);

  useEffect(() => {
    const currentRoute = location.pathname;
    if (currentRoute.includes('uploads')) {
      setRoute(1);
      tabSelected(1);
    } else if (currentRoute.includes('spikes/streams')) {
      setRoute(0);
      tabSelected(0);
    } else if (currentRoute.includes('spikes/videos')) {
      setRoute(1);
      tabSelected(1);
    } else if (currentRoute.includes('spikes/transcribe')) {
      setRoute(2);
      tabSelected(2);
    }
  }, [location.pathname]);
  const styles = useMemo(() => {
    return {
      container: {
        height: 85,
        position: 'relative',
      },
      item: {
        fontSize: 20,
        fontFamily: 'Poppins',
        marginLeft: 20,
      },
      itemSelected: {
        color: COLORS.PRIMARY
      },
      divider: {
        height: 20,
        width: 1,
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
        margin: '0 20px',
        marginTop: '-32px'
      },
      dividerSecond: {
        height: 20,
        width: 1,
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
        margin: '13px 15px 0px 30px',
      },
      middleDivider: {
        height: 20,
        width: 1,
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
        marginLeft: 25
      },
      progressBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '-19px'
      },
      progressCaption: {
        color: COLORS.GRAY_DISABLED,
        fontSize: 11
      }
    } as const;
  }, [route]);

  const tabSelected = (index: number) => {
    if (index === 0) {
      handleCloseTour();
    }
    setRoute(index);
    onTabSelected(index);
  };

  const calculatePercentage = (used?: number, total?: number) => {
    if (typeof used === 'number' && typeof total === 'number') {
      return ((total - used) * 100) / total;
    } else {
      return 0;
    };
  };

  const getRenewalDate = () => {
    if (userContext?.user?.subscriptions && userContext?.user?.subscriptions[0] && userContext?.user?.subscriptions[0]?.createdAt) {
      let createdDate = userContext?.user?.subscriptions[0]?.createdAt;
      if (createdDate) {
        if (createdDate.toString().length === 10) {
          createdDate *= 1000;
        }
        const isCreatedBeforeToday = isDateBeforeToday(createdDate);
        if (isCreatedBeforeToday) {
          const timestamp = changeMonthToFollowing(createdDate);
          return dayjs(timestamp).format('MMMM ') + addDateEnding(dayjs(timestamp).date());
        } else {
          const timestamp = changeMonthToCurrent(createdDate);
          return dayjs(timestamp).format('MMMM ') + addDateEnding(dayjs(timestamp).date());
        }
      } else {
        return getFirstDayOfNextMonth();
      }
    } else {
      return getFirstDayOfNextMonth();
    }
  };

  const getProgressColor = (percent: number) => {
    if (percent > 67) {
      return '#67FFCC'; // Green
    } else if (percent > 34) {
      return '#ead270'; // Yellow
    } else if (percent > 1) {
      return '#e54431'; // Red
    } else return '#9e9e9e'; // Grey
  };
  // const getProgressColor = (percent: number) => {
  //   if (percent > 99) {
  //     return '#F65273';
  //   } else if (percent > 70) {
  //     return '#F67E91';
  //   } else if (percent > 50) {
  //     return '#F6BBBB';
  //   } else if (percent > 40) {
  //     return '#C4FFC0';
  //   } else if (percent > 20) {
  //     return '#A1F599';
  //   } else return '#83F47F';
  // };

  const Tab = ({ tabName, tabIndex }: { tabName: string, tabIndex: number }) => {
    return (
      <div onClick={() => tabSelected(tabIndex)} className={`menu-tab ${route === tabIndex && 'active'}`}>
        {tabName}
      </div>
    );
  };

  return (
    <div className="menu-tabs-container">
      {!isGoogleUser &&
        <>
          <Tab tabName='Live Stream AI' tabIndex={0} />
          <div className="menu-divider" />
        </>
      }
      <Tab tabName='AI Clip Generator' tabIndex={1} />
      <div className="menu-divider" />
      <Tab tabName='Add subtitles' tabIndex={2} />
    </div>
  );

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {!isGoogleUser && <div data-tour='live-stream-ai' className='position-relative' onClick={() => tabSelected(0)}>
          <Space>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography className='cursor-pointer' style={{ ...styles.item, ...(route === 0 ? styles.itemSelected : {}) }}>{items[0].label}</Typography>
              <div data-tour='streams-left' style={styles.progressBarContainer}>
                {hasSubscription ? <div style={{ height: 18 }} /> : <Progress style={{ width: 110 }} percent={calculatePercentage(usedStreams, totalStreams)} size="small" showInfo={false} strokeColor={getProgressColor(calculatePercentage(usedStreams, totalStreams))} trailColor={COLORS.GRAY_CONTROLS} />}
                {hasSubscription ?
                  <Typography style={{ fontSize: 16, color: COLORS.LIGHT_GRAY_TEXT, userSelect: 'none' }}>Unlimited</Typography>
                  :
                  <Tooltip placement='bottom' title={`Available Credits: ${totalStreams !== undefined && usedStreams !== undefined && roundWithTwoDecimals(totalStreams! - usedStreams!)}`}>
                    {/* <Tooltip placement='bottom' title={`Credits for your ${planName || 'Free plan'} will renew at ${getRenewalDate()}`}> */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontSize: 16, color: COLORS.LIGHT_GRAY_TEXT, userSelect: 'none' }}>{totalStreams! - usedStreams!}</Typography>
                        <Typography style={{ fontSize: 16, color: COLORS.GRAY_DISABLED, userSelect: 'none' }}>&nbsp;/ {totalStreams}</Typography>
                      </div>
                      <div style={styles.progressCaption}>Streams Remaining</div>
                    </div>
                  </Tooltip>
                }
              </div>

            </div>
            <div style={styles.divider} />
          </Space>
        </div>}

        <div>
          <div style={{ display: 'flex' }}>
            <div className='position-relative' onClick={() => tabSelected(1)}>
              <Space>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography className='cursor-pointer' style={{ ...styles.item, ...(route === 1 ? styles.itemSelected : {}) }}>{items[1].label}</Typography>
                </div>
              </Space>

            </div>
            {/* <div className='position-relative' onClick={() => tabSelected(2)}>
              <Space>
                <div style={styles.middleDivider} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography className='cursor-pointer' style={{ ...styles.item, ...items[2].textStyle, ...(route === 2 ? styles.itemSelected : {}) }} key={items[2].label}>{items[2].label}</Typography>
                </div>
              </Space>
            </div> */}
          </div>
          <div data-tour='minutes-left' style={styles.progressBarContainer}>
            <Progress style={{ width: '70%' }} percent={calculatePercentage(usedMinutes, totalMinutes)} size="small" showInfo={false} strokeColor={getProgressColor(calculatePercentage(usedMinutes, totalMinutes))} trailColor={COLORS.GRAY_CONTROLS} />
            <Tooltip placement='bottom' title={`Available Credits ${totalMinutes !== undefined && usedMinutes !== undefined && roundWithTwoDecimals(totalMinutes! - usedMinutes!)}m`}>
              {/* <Tooltip placement='bottom' title={`Credits for your ${planName || 'Free plan'} will renew at ${getRenewalDate()}`}> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <Typography style={{ fontSize: 16, color: COLORS.LIGHT_GRAY_TEXT, userSelect: 'none' }}>{`${usedMinutes && totalMinutes ? roundWithTwoDecimals(totalMinutes - roundWithTwoDecimals(usedMinutes)) : roundWithTwoDecimals(totalMinutes - usedMinutes)}m`}</Typography>
                  <Typography style={{ fontSize: 16, color: COLORS.GRAY_DISABLED, userSelect: 'none' }}>&nbsp;/ {`${totalMinutes}m`}</Typography>
                </div>
                <div style={styles.progressCaption}>Minutes Remaining</div>
              </div>
            </Tooltip>
          </div>
        </div>
        <div style={{ ...styles.dividerSecond }} />
        <div>
          <div style={{ display: 'flex' }}>
            <div className='position-relative' onClick={() => tabSelected(2)}>
              <Space>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography className='cursor-pointer' style={{ ...styles.item, ...(route === 2 ? styles.itemSelected : {}) }}>{items[2].label}</Typography>
                </div>
              </Space>
            </div>
          </div>
          <div data-tour='minutes-left' style={styles.progressBarContainer}>
            <Progress style={{ width: '70%' }} percent={calculatePercentage(usedMinutes, totalMinutes)} size="small" showInfo={false} strokeColor={getProgressColor(calculatePercentage(usedMinutes, totalMinutes))} trailColor={COLORS.GRAY_CONTROLS} />
            <Tooltip placement='bottom' title={`Available Credits ${totalMinutes !== undefined && usedMinutes !== undefined && roundWithTwoDecimals(totalMinutes! - usedMinutes!)}m`}>
              {/* <Tooltip placement='bottom' title={`Credits for your ${planName || 'Free plan'} will renew at ${getRenewalDate()}`}> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <Typography style={{ fontSize: 16, color: COLORS.LIGHT_GRAY_TEXT, userSelect: 'none' }}>{`${usedMinutes && totalMinutes ? roundWithTwoDecimals(totalMinutes - roundWithTwoDecimals(usedMinutes)) : roundWithTwoDecimals(totalMinutes - usedMinutes)}m`}</Typography>
                  <Typography style={{ fontSize: 16, color: COLORS.GRAY_DISABLED, userSelect: 'none' }}>&nbsp;/ {`${totalMinutes}m`}</Typography>
                </div>
                <div style={styles.progressCaption}>Minutes Remaining</div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

    </div>
  );

  /* Keep older version for future tests */

  // return (
  //   <Space size='large' style={styles.container}>
  //     {items.slice(0, 2).map((item, index) => {
  //       if (isGoogleUser && index === 0) return;
  //       return (
  //         <div data-tour={index === 0 ? 'live-stream-ai' : ''} className='position-relative' key={item.label} onClick={() => tabSelected(index)}>
  //           <Space>
  //             {index > 0 && !isGoogleUser && <div style={styles.divider} />}
  //             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  //               <Typography className='cursor-pointer' style={{ ...styles.item, ...item.textStyle, ...(route === index ? styles.itemSelected : {}) }} key={item.label}>{item.label}</Typography>
  //               <div data-tour={index === 1 ? 'minutes-left' : 'streams-left'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '-19px' }}>
  //                 <Progress style={{ width: 110 }} percent={calculatePercentage(index === 0 ? usedStreams : usedMinutes, index === 0 ? totalStreams : totalMinutes)} size="small" showInfo={false} strokeColor={COLORS.GRAY_DISABLED} trailColor={COLORS.GRAY_CONTROLS} />
  //                 <Tooltip placement='bottom' title={`Credits for your ${planName || 'Free plan'} will renew at ${getRenewalDate()}`}>
  //                   <div style={{ display: 'flex' }}>
  //                     <Typography style={{ fontSize: 16, color: COLORS.LIGHT_GRAY_TEXT, userSelect: 'none' }}>{index === 0 ? usedStreams : `${usedMinutes}m`}</Typography>
  //                     <Typography style={{ fontSize: 16, color: COLORS.GRAY_DISABLED, userSelect: 'none' }}>&nbsp;/ {index === 0 ? totalStreams : `${totalMinutes}m`}</Typography>
  //                   </div>
  //                 </Tooltip>
  //               </div>
  //             </div>
  //             {false && item.icon &&
  //               <Image src={item.icon} preview={false} width={22} />
  //             }
  //           </Space>

  //         </div>
  //       );
  //     })}
  //     <div className='position-relative' onClick={() => tabSelected(2)}>
  //       <Space>
  //         <div style={styles.divider} />
  //         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  //           <Typography className='cursor-pointer' style={{ ...styles.item, ...items[2].textStyle, ...(route === 2 ? styles.itemSelected : {}) }} key={items[2].label}>{items[2].label}</Typography>
  //           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '-19px' }}>
  //             <div style={{ visibility: 'hidden' }}>
  //               <Progress style={{ width: 110 }} percent={calculatePercentage(temp ? usedStreams : usedMinutes, temp ? totalStreams : totalMinutes)} size="small" showInfo={false} strokeColor={COLORS.GRAY_DISABLED} trailColor={COLORS.GRAY_CONTROLS} />
  //               <Tooltip placement='bottom' title={`Credits for your ${planName || 'Free plan'} will renew at ${getRenewalDate()}`}>
  //                 <div style={{ display: 'flex' }}>
  //                   <Typography style={{ fontSize: 16, color: COLORS.LIGHT_GRAY_TEXT, userSelect: 'none' }}>{temp ? usedStreams : `${usedMinutes}m`}</Typography>
  //                   <Typography style={{ fontSize: 16, color: COLORS.GRAY_DISABLED, userSelect: 'none' }}>&nbsp;/ {temp ? totalStreams : `${totalMinutes}m`}</Typography>
  //                 </div>
  //               </Tooltip>
  //             </div>
  //           </div>
  //         </div>
  //         {false && items[2].icon &&
  //           <Image src={items[2].icon} preview={false} width={22} />
  //         }
  //       </Space>

  //     </div>
  //   </Space>
  // );
};

export default TabMenu;
