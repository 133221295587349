import Blog3 from '../assets/simp-preview.png';
import { ReactComponent as EditorIcon } from '../assets/editor-icon.svg';
import { ReactComponent as CaptionIcon } from '../assets/animated_captions.svg';
import { ReactComponent as BRollIcon } from '../assets/b_roll.svg';
import { ReactComponent as TextIcon } from '../assets/text_icon.svg';
import { ReactComponent as ElementsIcon } from '../assets/elements_icon.svg';
import { ReactComponent as GifIcon } from '../assets/gif_icon.svg';
import { ReactComponent as BackgroundIcon } from '../assets/background_icon.svg';
import { BgColorsOutlined } from '@ant-design/icons';

export const DISCORD_LINK = 'https://discord.com/invite/spikes-studio';

export const demoVideoPreview = {
  videoName: 'Sample Video: Twilight Echoes Lofi (Demo)',
  channelName: 'Demo Channel - Spikes Studio 😉',
  viewsCount: '7,777,777',
  duration: '42',
  imageUrl: Blog3,
  viralClipsEstimation: '14-42 Viral Clips'
};

export const editorSubtitleColors = [
  '#FFFFFF',
  '#000000',
  '#FFFF00',
  '#FF5555',
  '#00FFFF',
  '#FF00FF',
  '#008080',
  '#FFA500',
  '#E6E6FA',
  '#CCCCCC',
];


export const emojiCategories = [
  {
    category: 'suggested',
    name: 'Recently Used'
  },
  {
    category: 'smileys_people',
    name: 'Smileys & People'
  },
  {
    category: 'animals_nature',
    name: 'Animals & Nature'
  },
  {
    category: 'food_drink',
    name: 'Food & Drink'
  },
  {
    category: 'travel_places',
    name: 'Travel & Places'
  },
  {
    category: 'activities',
    name: 'Activities'
  },
  {
    category: 'objects',
    name: 'Objects'
  },
  {
    category: 'symbols',
    name: 'Symbols'
  },
];

export enum EditorTabNames {
  BASIC_EDITOR = 'basicEditor',
  FONT_DESIGN = 'fontDesign',
  ANIMATED_CAPTIONS = 'animatedCaptions',
  B_ROLL = 'bRoll',
  TEXT_INSERT = 'textInsert',
  ELEMENTS = 'elements',
  GIFS = 'gifs',
  BACKGROUND = 'background',
  BRANDING = 'branding'
}

export const editorTabsList = [
  {
    id: EditorTabNames.BASIC_EDITOR,
    label: 'Basic Editor',
    icon: <EditorIcon />,
    isActive: true
  },
  {
    id: EditorTabNames.FONT_DESIGN,
    label: 'Font Design',
    icon: <BgColorsOutlined style={{ fontSize: '1.8rem' }} />,
    isActive: true
  },
  {
    id: EditorTabNames.ANIMATED_CAPTIONS,
    label: 'Animated Captions',
    icon: <CaptionIcon />,
    isActive: true
  },
  {
    id: EditorTabNames.B_ROLL,
    label: 'B-Rolls',
    icon: <BRollIcon />,
    isActive: true
  },
  {
    id: EditorTabNames.ELEMENTS,
    label: 'Elements',
    icon: <ElementsIcon />,
    isActive: true
  },
  {
    id: EditorTabNames.GIFS,
    label: 'Gif\'s & Animations',
    icon: <GifIcon />,
    isActive: true
  },
  {
    id: EditorTabNames.TEXT_INSERT,
    label: 'Text Insert',
    icon: <TextIcon />,
    isActive: true
  },
  {
    id: EditorTabNames.BRANDING,
    label: 'Branding',
    icon: <BackgroundIcon />,
    isActive: true
  },
];

export const videoFileTypes = [
  'mp4',
  'mpeg-4',
  'avi'
];

export const audioFileTypes = [
  'mp3',
  'm4a',
  'audio/mpeg',
  'wav',
  'aac',
  'flac',
  'audio/x-wav',
  'audio/flac',
  'audio/aac',
  'audio/wav',
  'audio/mp3'
];

export const NO_SUBTITLES_MESSAGE = 'Spikes Studio didn\'t pick up any chatty content. Need a hand? Join our Discord or drop us a line at help@spikes.studio Spikes Studio didn\'t pick up any chatty content. Need a hand? Join our Discord or drop us a line at help@spikes.studio';

export const supportedLanguages = [
  { label: 'Auto Detect', value: 'auto' },
  { label: 'Afrikaans', value: 'Afrikaans' },
  { label: 'Albanian', value: 'Albanian' },
  { label: 'Amharic', value: 'Amharic' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Armenian', value: 'Armenian' },
  { label: 'Assamese', value: 'Assamese' },
  { label: 'Azerbaijani', value: 'Azerbaijani' },
  { label: 'Bashkir', value: 'Bashkir' },
  { label: 'Basque', value: 'Basque' },
  { label: 'Belarusian', value: 'Belarusian' },
  { label: 'Bengali', value: 'Bengali' },
  { label: 'Bosnian', value: 'Bosnian' },
  { label: 'Breton', value: 'Breton' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Catalan', value: 'Catalan' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Croatian', value: 'Croatian' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English', value: 'English' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Faroese', value: 'Faroese' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'Galician', value: 'Galician' },
  { label: 'Georgian', value: 'Georgian' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Gujarati', value: 'Gujarati' },
  { label: 'Haitian creole', value: 'Haitian creole' },
  { label: 'Hausa', value: 'Hausa' },
  { label: 'Hawaiian', value: 'Hawaiian' },
  { label: 'Hebrew', value: 'Hebrew' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Javanese', value: 'Javanese' },
  { label: 'Kannada', value: 'Kannada' },
  { label: 'Kazakh', value: 'Kazakh' },
  { label: 'Khmer', value: 'Khmer' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Latin', value: 'Latin' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lingala', value: 'Lingala' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Luxembourgish', value: 'Luxembourgish' },
  { label: 'Macedonian', value: 'Macedonian' },
  { label: 'Malagasy', value: 'Malagasy' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Malayalam', value: 'Malayalam' },
  { label: 'Maltese', value: 'Maltese' },
  { label: 'Maori', value: 'Maori' },
  { label: 'Marathi', value: 'Marathi' },
  { label: 'Mongolian', value: 'Mongolian' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Nepali', value: 'Nepali' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Nynorsk', value: 'Nynorsk' },
  { label: 'Occitan', value: 'Occitan' },
  { label: 'Pashto', value: 'Pashto' },
  { label: 'Persian', value: 'Persian' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Punjabi', value: 'Punjabi' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Sanskrit', value: 'Sanskrit' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Shona', value: 'Shona' },
  { label: 'Sinhala', value: 'Sinhala' },
  { label: 'Slovak', value: 'Slovak' },
  { label: 'Slovenian', value: 'Slovenian' },
  { label: 'Somali', value: 'Somali' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swahili', value: 'Swahili' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Tamil', value: 'Tamil' },
  { label: 'Tatar', value: 'Tatar' },
  { label: 'Telugu', value: 'Telugu' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Tibetan', value: 'Tibetan' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'Turkmen', value: 'Turkmen' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Urdu', value: 'Urdu' },
  { label: 'Uzbek', value: 'Uzbek' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Welsh', value: 'Welsh' },
  { label: 'Yiddish', value: 'Yiddish' },
  { label: 'Yoruba', value: 'Yoruba' }
];


/* WATERMARK */

export enum WatermarkSizeHeight {
  'big' = 200,
  'medium' = 150,
  'small' = 100
}