import {
  Col,
  Modal,
  Row,
  Space,
  Image,
  Button,
  Typography,
  Popover,
  Divider,
  message,
  Tooltip,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../themes/colors';
import { IClip } from '../../../data/intefaces/stream.interface';

import IconDownload from '../../../assets/icon-download-spike.svg';
import IconSettings from '../../../assets/icon-settings.svg';
import IconArrowDown from '../../../assets/icon-arrow-down.svg';
import IconShareInstagram from '../../../assets/icon-share-instagram.svg';
import IconShareFacebook from '../../../assets/icon-share-facebook.svg';
import IconShareTwitter from '../../../assets/icon-share-twitter.svg';
import IconShareYoutube from '../../../assets/icon-share-youtube.svg';
import IconShareTiktok from '../../../assets/icon-share-tiktok.svg';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { DesktopOutlined, EditFilled, MobileOutlined } from '@ant-design/icons';
import { CopyFilled } from '@ant-design/icons';
import { globalStyles } from '../../../utils/globalStyles';
import { postAudit } from '../../../services/audit.service';
import { EAuditAction } from '../../../data/intefaces/audit.interface';

export enum EClipType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export interface ISharePopoverProps {
  clip: IClip;
  platform: EClipType;
}

const SharePopover = ({ clip, platform }: ISharePopoverProps) => {
  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      divider: {
        borderLeftColor: COLORS.DIVIDER,
        height: '10px',
      },
      copyIcon: {
        color: COLORS.PRIMARY,
        fontSize: 19,
        marginLeft: 5,
        marginRight: 8,
      },
    } as const;
  }, []);

  const url =
    platform === EClipType.Mobile
      ? clip.mobile_download_url || ''
      : clip.desktop_download_url || clip.url;

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    message.success({
      content: 'Video URL copied!',
      style: {
        color: COLORS.BLACK,
      },
    });

    postAudit({
      user_action: EAuditAction.AuditActionDownload,
      clip: clip.id,
    });
  };

  const facebookShareClick = () => {
    postAudit({
      user_action: EAuditAction.AuditActionShareFacebook,
      clip: clip.id,
    });
  };

  const twitterShareClick = () => {
    postAudit({
      user_action: EAuditAction.AuditActionShareTwitter,
      clip: clip.id,
    });
  };

  return (
    <div style={styles.container}>
      <CopyFilled
        style={styles.copyIcon}
        className="cursor-pointer"
        onClick={copyUrl}
      />
      {/* <Image src={IconShareInstagram} preview={false} width={36} className='cursor-pointer' /> */}

      <Divider type="vertical" style={styles.divider} />

      <FacebookShareButton url={url} onClick={facebookShareClick}>
        <Image
          src={IconShareFacebook}
          preview={false}
          width={36}
          className="cursor-pointer"
        />
      </FacebookShareButton>

      <Divider type="vertical" style={styles.divider} />

      <TwitterShareButton url={url} onClick={twitterShareClick}>
        <Image
          src={IconShareTwitter}
          preview={false}
          width={36}
          className="cursor-pointer"
        />
      </TwitterShareButton>

      {/* <Divider type="vertical" style={styles.divider} />

      <Image src={IconShareYoutube} preview={false} width={36} className='cursor-pointer' /> */}

      {false && (
        <>
          <Divider type="vertical" style={styles.divider} />
          <Image
            src={IconShareTiktok}
            preview={false}
            width={36}
            className="cursor-pointer"
          />
        </>
      )}
    </div>
  );
};

export interface IShareButtonProps {
  clip: IClip;
  disabled?: boolean;
  showEdit?: boolean;
  onEdit?: () => void;
  onVersionSelection?: (value: EClipType) => void;
}

export const ShareButton = ({
  clip,
  disabled = false,
  onEdit,
  showEdit = true,
  onVersionSelection,
}: IShareButtonProps) => {
  const [selectedPlatform, setSelectedPlatform] = useState<EClipType>(
    clip.mobile_download_url ? EClipType.Mobile : EClipType.Desktop,
  );

  useEffect(() => {
    if (clip.status === 'ready' && clip.mobile_download_url) {
      setSelectedPlatform(EClipType.Mobile);
    }
  }, [clip.status]);

  const styles = useMemo(() => {
    return {
      container: {
        opacity: disabled ? 0.4 : 1,
        pointerEvents: disabled ? 'none' : 'all',
      },
      shareButton: {
        background: '#102830',
        border: '1px solid #67FFCC',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        overflow: 'hidden',
        boxShadow: 'inset 0px 0px 12px -4px #67FFCC',
      },
      donwloadButton: {
        background: '#001922',
        padding: '3px 8px 8px',
        borderRight: '1px solid #67FFCC',
      },
      editButton: {
        background: '#001922',
        padding: '7px 8px 4px 8px',
        borderRight: '1px solid #67FFCC',
      },
      shareText: {
        padding: showEdit ? '0px 26px 3px 26px' : '0px 44px 3px 5px',
        color: COLORS.PRIMARY,
        fontSize: 12,
      },
      platformSelection: {
        background: '#001922',
        border: '1px solid #67FFCC',
        borderTop: 0,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        overflow: 'hidden',
      },
      platformDesktop: {
        width: 85,
        padding: '3px 9px',
        background:
          selectedPlatform == EClipType.Desktop ? COLORS.PRIMARY : '#001922',
        transition: '0.3s',
      },
      platformMobile: {
        borderLeft: `1px solid ${COLORS.PRIMARY}`,
        width: 74,
        padding: '3px 6px',
        opacity: clip.mobile_download_url ? 1 : 0.4,
        background:
          selectedPlatform == EClipType.Mobile ? COLORS.PRIMARY : '#001922',
        transition: '0.3s',
      },
      platformText: {
        color: COLORS.WHITE,
        fontSize: 10,
        fontWeight: 399,
        fontFamily: 'Poppins',
      },
      platformDesktopText: {
        color:
          selectedPlatform == EClipType.Desktop ? COLORS.BLACK : COLORS.WHITE,
      },
      platformMobileText: {
        color:
          selectedPlatform == EClipType.Mobile ? COLORS.BLACK : COLORS.WHITE,
      },
    } as const;
  }, [showEdit, disabled, clip.mobile_download_url, selectedPlatform]);

  const download = () => {
    message.loading({
      content: 'Downloading...',
      style: {
        color: COLORS.BLACK,
      },
    });

    const downloadUrl =
      selectedPlatform == EClipType.Desktop
        ? clip.desktop_download_url || clip.download_url
        : clip.mobile_download_url;

    fetch(downloadUrl!, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/mp4',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `spike_${clip.clip_order.toString()}_${clip.clip_ranking.toString()}_${selectedPlatform}.mp4`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    postAudit({
      user_action: EAuditAction.AuditActionDownload,
      clip: clip.id,
    });
  };

  return (
    <div className="position-relative" style={styles.container}>
      <div style={styles.shareButton} className="cursor-pointer space-between">
        <Tooltip
          title="Download approved Spike in the chosen format."
          overlayInnerStyle={globalStyles().helpTooltip}
        >
          <div style={styles.donwloadButton} onClick={download}>
            <Image src={IconDownload} preview={false} width={18} />
          </div>
        </Tooltip>
        {showEdit && (
          <div style={styles.editButton} onClick={onEdit}>
            <EditFilled
              size={30}
              style={{
                fontSize: 18,
                color: clip.mobile_download_url
                  ? COLORS.SECONDARY
                  : COLORS.WHITE,
              }}
            />
          </div>
        )}
        <Popover
          content={<SharePopover clip={clip} platform={selectedPlatform} />}
          placement="topRight"
          color="#001922"
        >
          <Typography style={styles.shareText}>Share</Typography>
        </Popover>
      </div>
      <div
        style={styles.platformSelection}
        className="space-between cursor-pointer"
      >
        <Tooltip
          title={
            selectedPlatform == EClipType.Mobile
              ? 'Switch to desktop version'
              : null
          }
          trigger="hover"
          placement="bottom"
          overlayInnerStyle={globalStyles().helpTooltip}
        >
          <Space
            style={styles.platformDesktop}
            onClick={() => {
              setSelectedPlatform(EClipType.Desktop);
              onVersionSelection && onVersionSelection(EClipType.Desktop);
            }}
          >
            <DesktopOutlined style={{ ...styles.platformDesktopText }} />
            <Typography
              style={{ ...styles.platformText, ...styles.platformDesktopText }}
              className="uppercase"
            >
              {EClipType.Desktop}
            </Typography>
          </Space>
        </Tooltip>
        <Tooltip
          title={
            clip.mobile_download_url
              ? selectedPlatform == EClipType.Desktop
                ? 'Switch to mobile version'
                : null
              : 'Mobile version is only available after editing'
          }
          trigger="hover"
          placement="bottom"
          overlayInnerStyle={globalStyles().helpTooltip}
        >
          <Space
            style={styles.platformMobile}
            onClick={() => {
              if (clip.mobile_download_url) {
                setSelectedPlatform(EClipType.Mobile);
                onVersionSelection && onVersionSelection(EClipType.Mobile);
              } else {
                onEdit && onEdit();
              }
            }}
          >
            <MobileOutlined style={{ ...styles.platformMobileText }} />
            <Typography
              style={{ ...styles.platformText, ...styles.platformMobileText }}
              className="uppercase"
            >
              {EClipType.Mobile}
            </Typography>
          </Space>
        </Tooltip>
      </div>
    </div>
  );
};

export default ShareButton;
