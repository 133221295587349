import { useMemo } from 'react';
import Space from 'antd/lib/space';
import { Image, Typography } from 'antd';
import { COLORS } from '../../themes/colors';

import Logo from '../../assets/spikes-logo.svg';
import IconTwitter from '../../assets/icon-footer-twitter.svg';
import IconInstagram from '../../assets/icon-footer-instagram.svg';
import IconYouTube from '../../assets/icon-footer-youtube.svg';
import IconDiscord from '../../assets/icon-footer-discord.svg';
import IconTikTok from '../../assets/icon-footer-tiktok.svg';
import { DISCORD_LINK } from '../../constants/content-constants';

const MenuItems: {
  label: string;
  route: string;
  highlighted?: boolean;
}[] = [
  // {
  //   label: 'FAQ',
  //   route: '',
  // },
  {
    label: 'Blog',
    route: 'https://blog.spikes.studio/',
  },
  {
    label: 'FAQ',
    route: 'https://intercom.help/spikes-studio/en/',
  },
  // {
  //   label: 'Contact Us',
  //   route: '',
  // },
  {
    label: 'Discord',
    route: DISCORD_LINK,
    highlighted: true,
  },
  // {
  //   label: 'About',
  //   route: '',
  // },
];

interface IFooterParams {
  shadow?: boolean;
}

export const Footer = ({ shadow = true }: IFooterParams) => {
  const styles = useMemo(() => {
    return {
      container: {
        paddingTop: 5,
        flex: 1,
      },
      hello: {
        fontFamily: 'Poppins',
        fontSize: 36,
        marginBottom: 0,
      },
      loggedInTitle: {
        fontWeight: 200,
      },
      avaterContainer: {
        width: 162,
        marginTop: 5,
        textAlign: 'right',
        position: 'relative',
      },
      avaterBorder: {
        border: '1px solid #67FFCC',
        width: 51,
        height: 50,
        borderRadius: 12,
        position: 'absolute',
        right: -5,
        top: 9,
        filter: 'drop-shadow(0px 0px 8px #67FFCC)',
      },
      avatar: {
        backgroundColor: '#FF2C90',
        verticalAlign: 'middle',
        borderRadius: 8,
        fontFamily: 'Poppins',
        fontSize: 22,
      },
      badge: {
        position: 'absolute',
        top: -22,
        right: -17,
        transform: 'scale(2)',
      },
      menuItem: {
        fontSize: 15,
        fontFamily: 'Poppins',
        marginLeft: 10,
      },
      highlight: {
        color: COLORS.SECONDARY,
      },
      bottomLinks: {
        color: 'rgba(244, 244, 244, 0.5)',
        fontWeight: 200,
      },
      bottomContainer: {
        width: 1400,
      },
    } as const;
  }, []);

  return (
    <>
      {shadow && (
        <div
          style={{
            height: 380,
            width: '100%',
            position: 'absolute',
            top: -380,
            left: 0,
            background:
              'linear-gradient(180deg, rgba(1, 7, 20, 0) 5.73%, rgba(1, 7, 20, 0.5) 42.94%, rgba(1, 7, 20, 0.841463) 79.69%, #010714 100%)',
          }}
        ></div>
      )}
      <Space
        direction="vertical"
        align="center"
        size="large"
        style={styles.container}
      >
        <Image src={Logo} preview={false} height={38} />
        <Space size="large">
          {MenuItems.map((item, index) => (
            <a
              href={item.route}
              key={item.label}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                className="uppercase cursor-pointer"
                style={{
                  ...styles.menuItem,
                  ...(item.highlighted ? styles.highlight : {}),
                }}
              >
                {item.label}
              </Typography>
            </a>
          ))}
        </Space>
        <Space style={styles.bottomContainer} className="space-between">
          <Space size="large">
            <a
              href="https://www.spikes.gg/terms"
              target="_blank"
              rel="noreferrer"
            >
              <Typography style={styles.bottomLinks} className="cursor-pointer">
                Terms of Use
              </Typography>
            </a>
            <a
              href="https://spikes.gg/privacy"
              target="_blank"
              rel="noreferrer"
            >
              <Typography style={styles.bottomLinks} className="cursor-pointer">
                Privacy Policy
              </Typography>
            </a>
          </Space>
          <Space size="large">
            <a
              href="https://twitter.com/SpikesGG"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={IconTwitter}
                preview={false}
                className="cursor-pointer"
              />
            </a>
            <a
              href="https://www.instagram.com/spikes.gg"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={IconInstagram}
                preview={false}
                className="cursor-pointer"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC8bBl-2zNisUt4WZ5k6BBlA"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={IconYouTube}
                preview={false}
                className="cursor-pointer"
              />
            </a>
            <a
              href={DISCORD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={IconDiscord}
                preview={false}
                className="cursor-pointer"
              />
            </a>
            <a
              href="https://www.tiktok.com/@spikesgg"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={IconTikTok}
                preview={false}
                className="cursor-pointer"
              />
            </a>
          </Space>
          <Typography style={styles.bottomLinks}>
            Copyright Spikes.gg 2023
          </Typography>
        </Space>
      </Space>
    </>
  );
};

export default Footer;
