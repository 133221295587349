import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'antd';
import { EditorTabNames, editorTabsList } from '../../../constants/content-constants';
import { UserContext } from '../../../data/userContext';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { getEnabledAnimatedCaptionsForUser, getEnabledElementsForUser, getEnabledGifsForUser } from '../../../utils';
import { COLORS } from '../../../themes/colors';


export interface IEditorNavigator {
  activeEditorTab: EditorTabNames;
  handleTabChange: (tab: EditorTabNames) => void;
}

export const EditorNavigator = ({ activeEditorTab, handleTabChange }: IEditorNavigator) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const styles = useMemo(() => {
    return {
      main: {
        width: 72,
        height: '100%',
        backgroundColor: COLORS.MAIN_EDITOR_COLOR,
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.MAIN_EDITOR_COLOR,
        height: '100%'
      },
      navigationItem: {
        padding: '11px 5px 5px 5px',
        width: '100%',
        fontSize: 9,
        lineHeight: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
      },
      label: {
        textAlign: 'center',
        userSelect: 'none'
      }
    } as const;
  }, []);

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const handleItemClick = (id: EditorTabNames) => {
    if (activeEditorTab !== id) {
      // if (id === EditorTabNames.ANIMATED_CAPTIONS) {
      //   if (animatedCaptionsTabEnabledForUser) {
      //     handleTabChange(id);
      //   } else {
      //     // navigate('/subscriptions');
      //     handleOpenPricingModal();
      //   }
      // } else if (id === EditorTabNames.ELEMENTS) {
      //   if (elementsTabEnabledForUser) {
      //     handleTabChange(id);
      //   } else {
      //     handleOpenPricingModal();
      //   }
      // } else if (id === EditorTabNames.GIFS) {
      //   if (gifsTabEnabledForUser) {
      //     handleTabChange(id);
      //   } else {
      //     handleOpenPricingModal();
      //   }
      // } else {
      handleTabChange(id);
      // }
    };
  };

  const getClassnames = (item: typeof editorTabsList[0]) => {
    let classes = 'editor-navigation__item';
    if (item.id === activeEditorTab) {
      classes += ' editor-navigation__item--active';
    }
    if (item.id === EditorTabNames.ELEMENTS) {
      if (!elementsTabEnabledForUser) {
        classes += ' starred';
      }
    }
    if (item.id === EditorTabNames.GIFS) {
      if (!gifsTabEnabledForUser) {
        classes += ' starred';
      }
    }
    if (item.id === EditorTabNames.BRANDING) {
      if (!gifsTabEnabledForUser) {
        classes += ' starred';
      }
    }
    if (item.id === EditorTabNames.ANIMATED_CAPTIONS) {
      if (!animatedCaptionsTabEnabledForUser) {
        classes += ' starred';
      }
    }
    return classes;
  };

  const gifsTabEnabledForUser = getEnabledGifsForUser(userContext?.user);
  const elementsTabEnabledForUser = getEnabledElementsForUser(userContext?.user);
  const animatedCaptionsTabEnabledForUser = getEnabledAnimatedCaptionsForUser(userContext?.user);

  return (
    <div style={styles.main}>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <div style={styles.container}>
        {editorTabsList.map(item => (
          <Tooltip
            title={item.isActive ? '' : 'Coming Soon'}
            key={item.id}
            placement="rightBottom"
          >
            <div
              onClick={item.isActive ? () => handleItemClick(item.id) : undefined}
              className={getClassnames(item)}
            >
              {item.icon}
              <div style={styles.label}>{item.label}</div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );

};
export default EditorNavigator;
