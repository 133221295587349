/* eslint-disable react/no-unescaped-entities */
import {
  Fragment,
  createRef,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Space from 'antd/lib/space';
import {
  Card,
  Col,
  Divider,
  Image,
  Row,
  Tooltip,
  Typography,
  Button,
  Carousel,
  Spin,
  message,
  Progress,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { COLORS } from '../../themes/colors';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';
import dayjs from 'dayjs';
import { secondsToTime } from '../../utils/generic';
import axios from 'axios';
import { globalStyles } from '../../utils/globalStyles';
import { postAudit } from '../../services/audit.service';
import { EAuditAction } from '../../data/intefaces/audit.interface';
import { ESpikeStatus } from '../../data/enums/spike-status.enum';

import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';
import { ReactComponent as IconDropdown } from '../../assets/caret-down.svg';
import { ReactComponent as IconVideoSource } from '../../assets/video_source.svg';
import { ReactComponent as IconError } from '../../assets/alert-error.svg';
import { ReactComponent as IconLanguage } from '../../assets/language.svg';
import { UserContext } from '../../data/userContext';
import { EStreamStatus } from '../../data/enums/stream-status.enum';
import { makeStreamClips } from '../../services/streamer.service';

import './UploadCard.less';
import { useNavigate } from 'react-router';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { EGeneratedClipType } from '../../data/enums/clip-type.enum';
import { Collapse } from 'react-collapse';
import {
  calculateProgressBar,
  getTimeRangeBetweenCurrentAndGivenDate,
  getTimeUntilExpiration,
  roundUpToNearestMultipleOfThree,
  showGoProButton,
} from '../../utils';
import { isMobile } from 'react-device-detect';
import { CheckStatusModal } from '../check-status-modal';
import { DeleteUploadModal } from '../delete-upload-modal';
import { ErrorInfoModal } from '../error-info-modal';
import { PricingModal } from '../pricing-modal/PricingModal';
import { PlatformType } from '../error-info-modal/ErrorInfoModal';

const CLIPS_PER_PAGE = 3;

const LOADER_TEXT = [
  'Your best moments are on their way',
  'This may take a few moments',
  'Server 1 is watching your Spikes with pleasure',
  'Server 2 is fighting with Server 1 over watching your Spikes',
  'Server 3 is eating popcorn',
];

export interface IUploadCardProps {
  stream: IStream & IVideo;
  isTwitchCard?: boolean;
  index: number;
  isYoutubeCard?: boolean;
  refetch?: () => void;
  selectedUploadCard?: string | null;
  loadingCard?: boolean;
}

const THUMBNAIL_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACoCAMAAABt9SM9AAAAA1BMVEUAAACnej3aAAAAR0lEQVR4nO3BAQEAAACCIP+vbkhAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAO8GxYgAAb0jQ/cAAAAASUVORK5CYII=';

export const UploadCard = memo((props: IUploadCardProps) => {
  const {
    stream,
    isTwitchCard = false,
    isYoutubeCard = false,
    index,
    refetch,
    selectedUploadCard,
    loadingCard = false,
  } = props;

  const userContext = useContext(UserContext);
  const [page, setPage] = useState<number>(
    stream ? Math.max(Math.ceil(stream.clips.length / CLIPS_PER_PAGE), 1) : 1,
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [loaderTextId, setLoaderTextId] = useState<number>(0);
  const [loadingMakeStreams, setLoadingMakeStreams] = useState<boolean>(false);
  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(99);
  const [showCheckStatusModal, setShowCheckStatusModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorModal, setShowErroModal] = useState(false);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const navigate = useNavigate();
  const subscriptions = userContext?.user?.subscriptions;
  const usedStreams = userContext?.user?.used_streams;
  const totalStreams = userContext?.user?.total_streams;
  const USER_NOT_SUBSCRIBED =
    subscriptions == null || subscriptions.length == 0;
  const hasSubscription = !showGoProButton(userContext?.user);

  const showProgressBar = isYoutubeCard && loadingCard; //(!stream?.clips || stream?.clips?.length === 0);
  const totalTimeToGenerateClips =
    Math.round(72 + stream.duration / 3) * 2 * 1.5 * 2;

  const isTwitchProject = location.pathname.includes('streams');
  const isUploadProject = location.pathname.includes('videos');
  const isTranscribeProject = location.pathname.includes('transcribe');

  const uploadFailedWithReason =
    stream.status === EStreamStatus.PLATFORM_UPLOAD_FAILED;

  const expirationDate = stream.expiration_date;
  const isProjectExpired = expirationDate && Date.now() >= expirationDate;

  const cardRef = useRef<any>(null);

  const executeScroll = () =>
    cardRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  const roundEstimatedDuration = (
    durationSeconds: number,
    perMinutes: number,
  ) => {
    const rounded = Math.ceil(durationSeconds / 60 / perMinutes);
    return rounded;
  };

  const styles = useMemo(() => {
    return {} as const;
  }, []);

  const getPerMinutes = (durationSeconds: number) => {
    if (durationSeconds <= 899) {
      return 3;
    } else if (durationSeconds <= 3599) {
      return 4;
    }
    if (durationSeconds <= 8999) {
      return 6;
    }
    if (durationSeconds <= 14399) {
      return 8;
    }
    if (durationSeconds <= 1000000) {
      return 10;
    } else {
      return 10;
    }
  };

  const roundedDuration = roundEstimatedDuration(
    stream.duration,
    getPerMinutes(stream.duration),
  );
  const estimatedClipsRange = [roundedDuration, roundedDuration * 2]; // 1-2 clips per 3/4/6/8/10 minutes

  const getLoadingClipId = () => {
    const storedData = localStorage.getItem('loadingClipId');
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    const clipId = getLoadingClipId();

    if (clipId && stream) {
      const hasClipInside = stream.clips.find((clip) => clip.id === clipId);
      if (hasClipInside) {
        executeScroll();
        setTimeout(() => {
          localStorage.removeItem('loadingClipId');
        }, 300);
      }
    }
  }, []);

  useEffect(() => {
    if (showProgressBar) {
      const percent = Math.ceil(
        (getTimeRangeBetweenCurrentAndGivenDate(stream.createdAt) * 100) /
        totalTimeToGenerateClips,
      );
      if (percent < 100) {
        setProgress(percent);
      } else {
        setProgress(99);
      }
    }
  }, [
    progress,
    totalTimeToGenerateClips,
    showProgressBar,
    Date.now(),
    stream.createdAt,
  ]);

  useEffect(() => {
    if (showProgressBar) {
      const percent = Math.ceil(
        (getTimeRangeBetweenCurrentAndGivenDate(stream.createdAt) * 100) /
        totalTimeToGenerateClips,
      );
      if (percent < 100) {
        setProgress(percent);
      } else {
        setProgress(99);
      }
    }
  }, [
    progress,
    totalTimeToGenerateClips,
    showProgressBar,
    Date.now(),
    stream.createdAt,
  ]);

  useEffect(() => {
    setIsReady(
      isTwitchCard
        ? stream.clips.length > 0
        : stream.clips.length >= CLIPS_PER_PAGE,
    );

    stream.clips.length > 0 && loadingMakeStreams
      ? setLoadingMakeStreams(false)
      : null;

    // update only when new stream data arrives
    if (stream.clips.length < CLIPS_PER_PAGE) {
      setPage(Math.max(Math.ceil(stream.clips.length / CLIPS_PER_PAGE), 1));
    }
  }, [stream]);

  useEffect(() => {
    if (isReady) return;

    const timeout = setTimeout(
      () => setLoaderTextId((loaderTextId + 1) % LOADER_TEXT.length),
      6500,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [loaderTextId]);

  // const firstClip = (page - 1) * CLIPS_PER_PAGE;
  // const lastClip = page * CLIPS_PER_PAGE;
  // const prevButton = isTwitchCard ? IconPrevTwitchClip : IconPrev;
  // const nextButton = isTwitchCard ? IconNextTwitchClip : IconNext;
  // const refresh_hover_text = isTwitchCard
  //   ? 'Generate three more clips'
  //   : 'Generate three new Spikes from this stream';

  const refresh = async () => {
    if (stream.remaining_retries == 0) return;

    setIsRefreshing(true);
    stream.remaining_retries -= 1;

    const url = isTwitchCard
      ? '/streamer/refresh-twitch-clips'
      : '/streamer/refresh-stream';

    const streamData = await axios
      .put(url, {
        stream: stream.id,
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });

    if (!isTwitchCard && streamData) {
      stream.clips = streamData.data[0].clips;
      setPage(1);
    }

    if (isTwitchCard) {
      const left = userContext?.user?.platform_clips_remaining_refreshes
        ? userContext?.user?.platform_clips_remaining_refreshes - 1
        : 0;
      const user = userContext!.user;
      user!.platform_clips_remaining_refreshes = left;
      userContext?.setUser(user!);
    }

    setIsRefreshing(false);

    postAudit({
      user_action: EAuditAction.AuditActionRefresh,
      stream: stream.id,
    });
  };

  const inviteClicked = () => {
    setShowInviteModal(true);
  };

  const showClips =
    stream.status.toString() == 'ready' && stream.clips.length !== 0;

  // stream.status !== EStreamStatus.FAILED &&
  // stream.status !== EStreamStatus.LOCKED &&
  // stream.status !== EStreamStatus.EMPTY_CHAT &&
  // stream.clips.length !== 0;

  const mappedArray = new Array(Math.round(stream.clips.length / 2));

  mappedArray.forEach((item, i) => (item = i));

  const makeClipsOnClick = async () => {
    if (usedStreams && totalStreams && usedStreams >= totalStreams) {
      navigate('/subscriptions', {
        state: { message: "Uh-oh! You've hit the maximum stream limit." },
      });
    } else {
      const id = stream.id;
      setLoadingMakeStreams(true);
      const user = await makeStreamClips(id);

      if (!user) {
        setLoadingMakeStreams(false);
        messageApi.warning(
          'Stream is not ready yet! Please try again in a couple of minutes',
          5,
        );
      }
      // if (user.message === 'Stream not ready') {
      //   messageApi.warning('Stream is not ready yet! Please try again in a couple of minutes', 5);
      // }
      if (user != null) {
        userContext?.setUser(user);
      }
      //setLoadingMakeStreams(false);
    }
  };

  let disabledMakeClipsButton = false;

  stream.clips.forEach((clip: any) => {
    if (
      clip.clip_type === EGeneratedClipType.MANUAL_COMBINED ||
      clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD ||
      clip.clip_type === EGeneratedClipType.SPIKE
    ) {
      disabledMakeClipsButton = true;
    }
  });

  const handleCarouselChange = (currentSlide: number) => {
    setIsFirstSlide(currentSlide === 0);
    if (isMobile) {
      setIsLastSlide(currentSlide === Math.round(stream.clips.length) - 1);
    } else {
      setIsLastSlide(currentSlide === Math.round(stream.clips.length / 2) - 1);
    }
  };

  const handleShowTutorialInProgress = () => {
    messageApi.info(
      'If you ever want a refresher, the tutorial is waiting for you in the dropdown. 😊',
      5,
    );
  };

  const toggleCollapse = () => {
    setIsOpenCollapse((isOpen) => !isOpen);
  };

  const renderLoadingText = (percent: number) => {
    if (percent > 80) {
      return 'Adding some stylish touches';
    } else if (percent > 60) {
      return 'Polishing things up';
    } else if (percent > 40) {
      return 'you will receive an email when the highlights are ready';
    } else if (percent > 20) {
      return 'Transcribing';
    } else return 'Getting your video ready';
  };

  // if (stream.status === EStreamStatus.FAILED || stream.status === EStreamStatus.EMPTY_CHAT || stream.status === EStreamStatus.PLATFORM_UPLOAD_FAILED) return (
  //   <div>ERROR</div>
  // );
  if (
    stream.status === EStreamStatus.FAILED ||
    stream.status === EStreamStatus.EMPTY_CHAT
  )
    return null;

  const handleDeleteClip = async (clipId: string) => {
    const response = await axios
      .put('/streamer/update-favorite-clip', {
        clip: clipId,
        is_removed_from_favorite: true,
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });

    if (response?.status === 200) {
      refetch && refetch();
      messageApi.success('Clip removed!', 3);
    }
  };

  const checkStatus = () => {
    setShowCheckStatusModal(true);
  };

  const handleConfirmRefund = async () => {
    const response = await axios
      .put('/streamer/refund-upload', {
        id: stream.id,
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          messageApi.error('Error refunding credits. Please, try again.', 5);
        }
      });

    if (response?.data) {
      message.success({
        content:
          'We refunded the minutes for this video. You can always try to process it again.',
        style: {
          color: COLORS.BLACK,
        },
      });

      setTimeout(() => {
        userContext?.setUser(response?.data);
        refetch && refetch();
      }, 1000);
    } else {
      messageApi.error('Error refunding credits. Please, try again.', 5);
    }

    setShowCheckStatusModal(false);
  };

  // const getEstimatedClipsBlock = () =>
  //   <>
  //     <div className="small-vertical-divider" />
  //     <Typography style={styles.estimatedClips}>
  //       <span style={styles.bold}>{stream.clips.length}</span> <span style={{ color: '#D0D3D7' }}>/</span> <span style={styles.estimatedRight}>{estimatedClipsRange[0]}-{estimatedClipsRange[1]}&nbsp;<span style={styles.estimatedText}>{!isMobile && 'Estimated amount of '}Clips</span></span>
  //     </Typography>
  //   </>;

  const renderThumbnail = () => {
    if (isTwitchCard) {
      const width = '434';
      const height = '325';

      const formattedTwitchThumbnail = stream?.thumbnail_url
        .replace('%{width}', width)
        .replace('%{height}', height);
      return formattedTwitchThumbnail;
    } else {
      return stream?.thumbnail_url || THUMBNAIL_PLACEHOLDER;
    }
  };

  const renderUploadTitle = () => {
    return stream?.title;
  };

  const renderChannel = () => {
    return stream?.channel_name;
  };

  const renderDuration = () => {
    return secondsToTime(stream.duration);
  };

  const renderViews = () => {
    return stream?.total_views;
  };

  const checkUploadCardIsOpen = () => {
    return selectedUploadCard === stream.id;
  };

  const handleOpenProject = () => {
    if (loadingCard) return null;
    let source = '';
    if (isTwitchProject) {
      source = 'twitch';
    } else if (isUploadProject) {
      source = 'upload';
    } else if (isTranscribeProject) {
      source = 'transcribe';
    } else source = 'upload';

    navigate(`/spikes/project?source=${source}&id=${stream.id}`);
  };

  const getIsEdited = () => {
    let isProjectEdited = false;
    stream.clips &&
      stream.clips.forEach((clip) => {
        if (clip?.is_manual_edit) {
          isProjectEdited = true;
        }
      });
    return isProjectEdited;
  };

  const getIsDemoUpload = () => {
    return stream?.is_demo;
  };

  const handleDeleteUpload = async () => {
    setShowDeleteModal(false);

    const payload = {
      id: stream.id,
      is_active: false,
    };

    try {
      const response = await axios.put('/streamer/delete-upload', payload);
      if (response.status === 200) {
        messageApi.success('Upload removed!', 3);
        refetch && refetch();
      }
    } catch (error) {
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: 'Error - upload delete',
        user_id: userContext?.user?.id,
        upload_id: stream.id,
      });
    }
  };

  const handleOpenYTErrorModal = () => {
    setShowErroModal(true);
  };

  const handleCloseYTErrorModal = () => {
    setShowErroModal(false);
  };

  const handleOpenPricingModal = () => {
    if (!hasSubscription) {
      setIsPricingModalOpened(true);
    }
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const renderErrorMessageDependingOnStatus = (status: string) => {
    if (status === EStreamStatus.PLATFORM_UPLOAD_FAILED) {
      return (
        <div className="upload-card__error-message-primary">
          <div className={`error-yellow ${!showGoProButton(userContext?.user) && 'small'}`}>{showGoProButton(userContext?.user) ?
            'Your video failed to process.'
            :
            "We've identified an issue. Your video is still processing and will be ready soon. Apologies for the delay, and thank you for your patience."
          }</div>
          {showGoProButton(userContext?.user) && <span onClick={handleOpenYTErrorModal} className="error-learn-more">
            Learn more
          </span>
          }
        </div>
      );
    } else if (status === EStreamStatus.PLATFORM_UPLOAD_FAILED_UNAVAILABLE) {
      return (
        <div className="upload-card__error-message">
          <IconError />
          <span>
            Hi! We're working on your video, but it's facing processing issues
            due to location or rights. You can try and upload it directly
            through your computer.
          </span>
        </div>
      );
    } else if (
      status === EStreamStatus.FAILED ||
      status === EStreamStatus.LOCKED ||
      status === EStreamStatus.EMPTY_CHAT
    ) {
      return (
        <div className="upload-card__error-message">
          <IconError />
          <span>Error occured, please try again later.</span>
        </div>
      );
    } else if (status === EStreamStatus.UPLOAD_LANGUAGE_BLOCKED) {
      return (
        <div className="upload-card__error-message">
          <IconLanguage className="language-icon" />
          <span>
            This language is not supported by Spikes.Studio, sorry for the
            inconvinience
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      ref={cardRef}
      className={`upload-card ${loadingCard && 'loading-card'}`}
    >
      {!loadingCard && (
        <div
          className="upload-card__delete-icon"
          onClick={() => setShowDeleteModal(true)}
        >
          X
        </div>
      )}
      {contextHolder}
      <PricingModal
        isOpen={isPricingModalOpened}
        handleClosePricingModal={handleClosePricingModal}
      />
      <DeleteUploadModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onDeleteUpload={handleDeleteUpload}
      />
      <CheckStatusModal
        open={showCheckStatusModal}
        onClose={() => setShowCheckStatusModal(false)}
        onConfim={handleConfirmRefund}
      />
      <ErrorInfoModal
        open={showErrorModal}
        onClose={handleCloseYTErrorModal}
        platform={stream?.platform as PlatformType || 'youtube'}
      />
      <div style={{ position: 'relative' }}>
        {loadingCard && progress === 99 && !uploadFailedWithReason && showGoProButton(userContext?.user) && (
          <div className="upload-card__refund-button" onClick={checkStatus}>
            Refund Credits
          </div>
        )}
        <Image
          className="upload-card__thumbnail"
          onClick={handleOpenProject}
          style={{ borderRadius: '12px' }}
          height="auto"
          width="100%"
          src={renderThumbnail()}
          preview={false}
        />
      </div>
      <div onClick={handleOpenProject} className="upload-card__content">
        {loadingCard && renderErrorMessageDependingOnStatus(stream?.status)}
        {!loadingCard && (
          <div className="upload-card__pills">
            {!loadingCard && (
              <>
                {getIsEdited() ? (
                  <div className="edited-video-pill">Edited</div>
                ) : (
                  <div className="new-video-pill">New</div>
                )}
              </>
            )}
            {!loadingCard && getIsDemoUpload() && (
              <div className="edited-video-pill demo">Demo</div>
            )}
            {expirationDate && (
              <Tooltip
                overlayClassName="tooltip"
                title={
                  hasSubscription
                    ? null
                    : 'Upgrading to Pro extends your storage to 90 days.'
                }
              >
                {isProjectExpired ? (
                  <div
                    onClick={handleOpenPricingModal}
                    className={`edited-video-pill red ${!hasSubscription && 'pro'
                      }`}
                  >
                    Expired
                  </div>
                ) : (
                  <div
                    onClick={handleOpenPricingModal}
                    className={`edited-video-pill ${!hasSubscription && 'pro'
                      } ${getTimeUntilExpiration(expirationDate)?.lessThanOneDay &&
                      'light-red'
                      }`}
                  >
                    Expires in: {getTimeUntilExpiration(expirationDate)?.value}
                  </div>
                )}
              </Tooltip>
            )}
          </div>
        )}
        <div className="upload-card__content-title">{renderUploadTitle()}</div>
        {renderChannel() && (
          <div className="upload-card__content-channel">
            <IconVideoSource />
            <span>{renderChannel()}</span>
          </div>
        )}
        {loadingCard && !uploadFailedWithReason && (
          <div>
            <Progress percent={progress} />
          </div>
        )}
        {uploadFailedWithReason && showGoProButton(userContext?.user) && (
          <div
            className="upload-card__refund-button static"
            onClick={checkStatus}
          >
            Refund Credits
          </div>
        )}
      </div>
    </div>
  );
});

UploadCard.displayName = 'UploadCard';

export default UploadCard;
