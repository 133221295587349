import { Col, Image, Row, Spin, Tooltip, Typography, message } from 'antd';
import useAxios from 'axios-hooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Header } from '../../components/header';
import { MainLayout } from '../../layouts';
import { Footer as AppFooter } from '../../components/footer';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';
import './SocialAnalytics.less';
import { IClip, IClipEdit, ISocialPost, IStream, IVideo } from '../../data/intefaces/stream.interface';
import { EStreamStatus } from '../../data/enums/stream-status.enum';
import { LoaderFacts } from '../../components/loader-facts';
import { ClipCard } from '../../components/upload-card/ClipCard';
import { ReactComponent as IconArrow } from '../../assets/breadcrumb_arrow.svg';
import { ReactComponent as IconVideoSource } from '../../assets/video_source.svg';
import GraphImage from '../../assets/graph1.png';
import Graph2Image from '../../assets/graph2.png';
import Graph3Image from '../../assets/graph3.png';
import IconLightning from '../../assets/lightning-icon.svg';
import { secondsToTime } from '../../utils/generic';
import { formatViews, isISOAfterCurrentDate } from '../../utils';
import { LoadingOutlined } from '@ant-design/icons';
import { makeStreamClips } from '../../services/streamer.service';
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import _ from 'lodash';
import { ShareModal } from '../../components/share-modal';

import { ReactComponent as IconLinkedin } from '../../assets/linkedin.svg';
import { ReactComponent as IconFacebook } from '../../assets/social_facebook.svg';
import { ReactComponent as IconInstagram } from '../../assets/instagram.svg';
import { ReactComponent as IconTiktok } from '../../assets/tiktok_no_color.svg';
import { ReactComponent as IconYoutubeShorts } from '../../assets/youtube-shorts.svg';
import { ReactComponent as IconPinterest } from '../../assets/pinterest.svg';
import { ReactComponent as IconReddit } from '../../assets/reddit.svg';
import axios from 'axios';

export interface ISocialAnalyticsPageProps {
  defaultTabIndex?: number
};

const POLLING_INTERVAL = 3000000;

const SocialAnalyticsPage = (props: ISocialAnalyticsPageProps) => {
  const [postsResponse, postsRefetch] = useAxios('/streamer/social/clips');

  const isTwitchClipsProject = false; // TODO: REMOVE
  const isTranscribeProject = false; // TODO: REMOVE

  const postsList: IClip[] = postsResponse?.data;

  const [connectedSocialPlatformsList, setConnectedSocialPlatformsList] = useState<string[]>([]);
  const [loadingSocialExternalUrl, setLoadingSocialExternalUrl] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const allowDirectShare = userContext?.user?.permissions?.direct_share;

  const usedStreams = userContext?.user?.used_streams;
  const totalStreams = userContext?.user?.total_streams;
  const navigate = useNavigate();


  useEffect(() => {
    postsRefetch();
  }, []);

  useEffect(() => {
    const timer = setInterval(postsRefetch, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, []);


  useEffect(() => {
    const getConnectedPlatformsList = async () => {
      const response = await axios.get('/streamer/social/platforms');
      if (response?.data && response?.data?.length > 0) {
        setConnectedSocialPlatformsList(response?.data);
      } else {
        setConnectedSocialPlatformsList([]);
      }
    }

    getConnectedPlatformsList().catch(() => {
      messageApi.error('Failed to get your connected socials', 5);
    })
  }, []);

  const redirectToSocialExternalUrl = async () => {
    if (!allowDirectShare) {
      navigate('/subscriptions');
      return null;
    }
    if (loadingSocialExternalUrl) return null;
    setLoadingSocialExternalUrl(true);
    try {
      const response = await axios.post('/streamer/social/url', {
        redirect: `${window.location.href}`
      });
      if (response?.data) {
        window.open(response?.data, '_blank');
      } else {
        messageApi.error('Failed to start a connection procedure. Please try again later', 5);
      }
      setLoadingSocialExternalUrl(false);
    } catch (error) {
      console.log('Failed to start a connection procedure', error);
      messageApi.error('Failed to start a connection procedure. Please try again later', 5);
      setLoadingSocialExternalUrl(false);
    }
  }

  const handleHomeRedirect = () => {
    if (isTwitchClipsProject) {
      navigate('/spikes/videos');
    }
  }

  const checkIfPlatformConnected = (platform: string) => {
    return connectedSocialPlatformsList.includes(platform);
  }

  const handleSuccessReschedule = (isCancelPost?: boolean) => {
    postsRefetch();

    if (isCancelPost) {
      messageApi.success('Your scheduled post has been canceled.', 5);
    } else {
      messageApi.success('Your post has been successfully rescheduled.', 5);
    }
  }

  const getClipOrientation = (clip: IClip, post: ISocialPost) => {
    if (clip.mobile_download_url) {
      const found = post.post?.mediaUrls.find(url => url === clip.mobile_download_url);
      if (found) {
        return 'mobile';
      }
    } else {
      return 'desktop';
    }
  }

  return (
    <>
      <MainLayout
        header={isBrowser ? <Header title={''} /> : null}
        footer={<AppFooter shadow={false} />}
        marginTop='26px'
      >
        {contextHolder}
        <div className='project__wrapper'>
          <div className='project__hero'>
            <div className="project__hero-description">
              <div className="project__hero-breadcrumb">
                <span onClick={handleHomeRedirect}>Home</span>
                <IconArrow />
                <span>Social Analytics</span>
              </div>
              <h2 className='project__hero-title'>Social Analytics Page</h2>
              {/* <div className="project__hero-metadata">
                  <div className="project__hero-metadata-item">
                    <div className="project__hero-metadata-title">24</div>
                    <div className="project__hero-metadata-subtitle">clips</div>
                  </div>
                  <div className="project__hero-metadata-divider" />
                  <div className="project__hero-metadata-item">
                    <div className="project__hero-metadata-title">16</div>
                    <div className="project__hero-metadata-subtitle">scheduled</div>
                  </div>
                  <div className="project__hero-metadata-divider" />
                  <div className="project__hero-metadata-item">
                    <div className="project__hero-metadata-title">8</div>
                    <div className="project__hero-metadata-subtitle">posted</div>
                  </div>
                </div> */}

              <div className="project__connected-platforms">
                <span>Platforms:</span>
                <div className="project__connected-platforms-list">
                  <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('instagram') ? 'Disconnect' : 'Connect'}><IconInstagram onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('instagram') ? '' : 'gray'} /></Tooltip>
                  <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('tiktok') ? 'Disconnect' : 'Connect'}><IconTiktok style={{ fill: '#81c7ce' }} onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('tiktok') ? '' : 'gray'} /></Tooltip>
                  <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('youtube') ? 'Disconnect' : 'Connect'}><IconYoutubeShorts onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('youtube') ? '' : 'gray'} /></Tooltip>
                  <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('facebook') ? 'Disconnect' : 'Connect'}><IconFacebook onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('facebook') ? '' : 'gray'} /></Tooltip>
                  <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('linkedin') ? 'Disconnect' : 'Connect'}><IconLinkedin onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('linkedin') ? '' : 'gray'} /></Tooltip>
                  <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('pinterest') ? 'Disconnect' : 'Connect'}><IconPinterest onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('pinterest') ? '' : 'gray'} /></Tooltip>
                  {/* <Tooltip overlayClassName='tooltip' title={checkIfPlatformConnected('reddit') ? 'Disconnect' : 'Connect'}><IconReddit onClick={redirectToSocialExternalUrl} className={checkIfPlatformConnected('linkedin') ? '' : 'gray'} /></Tooltip> */}
                </div>
                {loadingSocialExternalUrl && <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#fff' }} spin />} />}
              </div>
            </div>
            <div className="project__hero-image">
              <div className="project__image-overlay" />
              {/* <Image height='100%' width='100%' wrapperStyle={{ display: 'flex', alignItems: 'center', maxHeight: 600 }} style={{ objectFit: 'contain', height: '82%' }} src={GraphImage} preview={false} /> */}
              <Image height='100%' width='100%' wrapperStyle={{ display: 'flex', alignItems: 'center', maxHeight: 600 }} style={{ objectFit: 'contain' }} src={Graph2Image} preview={false} />
            </div>
          </div>
          <div className='project__clips-container'>
            {
              postsList && postsList?.length > 0 ?
                (
                  <Row gutter={[12, 24]}>
                    {postsList
                      .sort((a, b) => b.clip_order - a.clip_order)
                      .map((clip, i) => clip.social_posts?.map((post) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Col md={24} lg={12} xxl={8} key={post.post_id}> <ClipCard socialPost={post} postsRefetch={postsRefetch} analyticsMode handleSuccessReschedule={handleSuccessReschedule} handleDirectShareClick={() => null} key={clip.id} clipNumber={i + 1} streamTitle={''} defaultOrientation={() => getClipOrientation(clip, post)} clip={clip} platformStreamId={''} handleDeleteClip={() => null} isTranscribePage={isTranscribeProject} isTwitchPage={isTwitchClipsProject} /></Col>
                      )))
                    }
                  </Row>
                )
                :
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 50 }}><Spin indicator={<LoadingOutlined style={{ fontSize: 56, color: '#fff' }} spin />} /> </div>
            }
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default SocialAnalyticsPage;
