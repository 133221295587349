import { useCallback, useMemo, useState } from 'react'
import { COLORS } from '../../../../themes/colors';
import { Image, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { fontSizeOptions } from '../subtitles-word/SubtitlesWord';
import { SubtitleSentence, SubtitleWord } from '../../../../data/intefaces/stream.interface';
import { TwitterPicker } from 'react-color';

import YellowLetterIcon from '../../../../assets/color-letter.svg';
import GreenLetterIcon from '../../../../assets/green-letter.svg';
import ColorPickerIcon from '../../../../assets/color-picker.svg';
import { ReactComponent as IconClose } from '../../../../assets/icon-close-no-color.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { editorSubtitleColors } from '../../../../constants/content-constants';
import { formatTime } from '../../../../utils';

interface ISelectedWordsPopup {
  onChange?: (value: string | number, param: keyof SubtitleWord, sentenceIndex: number, wordIndex: number) => void;
  hideSelectedWords: () => void;
  handleClosePopup: () => void;
  makeClipFromSelectedWords: () => void;
  selectedWordsStartTimes: number[];
  sentences: SubtitleSentence[];
}

export const SelectedWordsPopup = ({ onChange, hideSelectedWords, handleClosePopup, makeClipFromSelectedWords, selectedWordsStartTimes, sentences }: ISelectedWordsPopup) => {
  const [selectedFontSize, setSelectedFontSize] = useState('');
  const colorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenColorPicker(false) });
  const [chosenColor, setChosenColor] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);

  // Iterate through all matching words and change their styles
  const handleStyleChange = (type: keyof SubtitleWord, value: string) => {
    if (onChange) {
      sentences.forEach((sentence, sentenceIndex) => {
        sentence.words.forEach((word, wordIndex) => {
          if (selectedWordsStartTimes.includes(word.start)) {
            setTimeout(() => {
              onChange(value, type, sentenceIndex, wordIndex);
            }, 10);
          }
        })
      })
    }
    if (type === 'fontSize') {
      setSelectedFontSize(value);
    }
  };

  const handleDeleteWord = () => {
    handleStyleChange('word', '');
  };

  const handleOpenColorPicker = useCallback(() => {
    setOpenColorPicker(val => !val);
  }, []);

  const handleChooseColor = (color: any) => {
    setChosenColor(color);
    handleStyleChange('color', color.hex);
  };

  const onRemoveFromClip = () => {
    hideSelectedWords();
    handleClosePopup();
  };

  const onMakeClipFromSelectedWords = () => {
    makeClipFromSelectedWords();
    handleClosePopup();
  };

  const getFirstWordObject = () => {
    const firstSentenceObject = sentences.find(sentence => sentence.words.find(word => word.start === selectedWordsStartTimes[0]));
    const firstWord = firstSentenceObject?.words.find(word => word.start === selectedWordsStartTimes[0]);
    return firstWord;
  };

  const getLastWordObject = () => {
    const lastSentenceObject = sentences.find(sentence => sentence.words.find(word => word.start === selectedWordsStartTimes[selectedWordsStartTimes.length - 1]));
    const lastWord = lastSentenceObject?.words.find(word => word.start === selectedWordsStartTimes[selectedWordsStartTimes.length - 1]);
    return lastWord;
  };

  const firstWordObject = getFirstWordObject();
  const lastWordObject = getLastWordObject();

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        position: 'absolute',
        zIndex: 9,
        top: '240px',
        left: '300px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderRadius: 8,
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
        border: '1px solid rgba(255, 255, 255, 0.1)'
      },
      inputContainer: {
        display: 'flex',
        width: '100%',
        padding: '10px 15px',
        background: 'rgba(38, 40, 52, 0.69)',
        alignItems: 'center',
        gap: 10,
        borderRadius: '8px 8px 0 0',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      },
      filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '0 0 8px 8px'
      },
      fontSizeItemsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
      },
      filterWrapper: {
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(38, 40, 52, 0.69)',
        borderRight: '1px solid rgba(255, 255, 255, 0.1)',
        borderRadius: '0 0 0 8px'
      },
      colorsItem: {
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(38, 40, 52, 0.69)',
        width: '100%',
        borderRadius: '0 0 8px 0'
      },
      input: {
        background: COLORS.GRAY_CONTROLS,
      },
      clearIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '7px',
        marginRight: '5px',
        cursor: 'pointer',
      },
      timeRange: {
        height: 40,
        padding: 5,
        backgroundColor: 'rgba(23, 25, 33, 0.69)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        userSelect: 'none',
        fontSize: '12px'
      },
      controlTopButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: 9,
        position: 'relative',
        marginLeft: 'auto'
      },
      setButton: {
        height: '34px !important',
        maxHeight: '34px !important',
        minHeight: '34px !important',
        fontSize: 12,
        fontWeight: 400
      },
      downloadQualityBox: {
        height: '34px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY,
        cursor: 'pointer',
        position: 'relative',
        padding: '4px 6px'
      },
      downloadQualityText: {
        fontSize: '12px',
        color: COLORS.BACKGROUND_DARK_GRAY,
        fontWeigth: 400,
        whiteSpace: 'nowrap'
      },
      downloadQualityBoxDisabled: {
        backgroundColor: COLORS.GRAY_BACKGROUND,
      },
      disabledClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
      downloadQualityTextDisabled: {
        color: COLORS.GRAY_DISABLED,
      },
    } as const;
  }, []);

  return (
    <div className='scale-up-center selected-words-popup' style={styles.container}>
      <div style={styles.inputContainer}>
        {firstWordObject && lastWordObject &&
          <div style={styles.timeRange}>
            {formatTime(firstWordObject.start)} - {formatTime(lastWordObject.end)}
          </div>
        }

        <div style={styles.controlTopButtons}>
          <div
            onClick={onRemoveFromClip}
            className={'primary-hover'}
            style={{
              ...styles.downloadQualityBox,
            }}
          >
            <Typography
              style={{
                ...styles.downloadQualityText,
              }}
            >
              Remove from clip
            </Typography>
          </div>
          <div
            onClick={onMakeClipFromSelectedWords}
            className='primary-hover'
            style={{
              ...styles.downloadQualityBox,
            }}
          >
            <Typography
              style={{
                ...styles.downloadQualityText,
              }}
            >
              Create Clip
            </Typography>
          </div>

          <div onClick={handleClosePopup} className='close-btn'>
            <IconClose />
          </div>
        </div>
      </div>
      <div style={styles.filtersContainer}>
        <div style={styles.filterWrapper}>
          <div style={{ ...styles.fontSizeItemsContainer, padding: '4px 5px', background: 'rgba(23, 25, 33, 0.69)' }}>
            {fontSizeOptions.map((item) => (
              <div
                onClick={() => handleStyleChange('fontSize', item.value)}
                key={item.value}
                className={`font-size-item ${item.value === selectedFontSize ? 'font-size-item-selected' : ''}`}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div style={styles.colorsItem}>
          <div className="color-items" style={styles.fontSizeItemsContainer}>
            <div
              onClick={() => handleStyleChange('color', '#FFFD03')}
              className='color-item blue'
            >
              <Image
                src={YellowLetterIcon}
                preview={false}
                className="cursor-pointer"
              />
            </div>
            <div
              onClick={() => handleStyleChange('color', '#04F827')}
              className='color-item blue'
            >
              <Image
                src={GreenLetterIcon}
                preview={false}
                className="cursor-pointer"
              />
            </div>
            <div ref={colorPickerRef}>
              <div
                onClick={handleOpenColorPicker}
                className='color-item blue'
              >
                <Image
                  src={ColorPickerIcon}
                  preview={false}
                  className="cursor-pointer"
                />
              </div>
              {openColorPicker && <TwitterPicker color={chosenColor} onChangeComplete={handleChooseColor} colors={editorSubtitleColors} />}
            </div>
            <div
              onClick={handleDeleteWord}
              className='color-item blue'
              style={{ marginRight: 0 }}
            >
              <DeleteOutlined style={{ fontSize: 26 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}