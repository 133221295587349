import React, { useMemo } from 'react';
import { Typography, Row, Col, Layout, Image } from 'antd';
import './About.less';
import TitleAsset from './../../assets/about-title-asset.svg';
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';
import { COLORS } from '../../themes/colors';
import { STRUCTURED_DATA_ABOUT_PAGE } from '../../structured-data';

import { MainLayout } from '../../layouts';
import { isBrowser } from 'react-device-detect';
import { Header } from '../../components/header';
import { Footer as AppFooter } from '../../components/footer';

const AboutPage: React.FC = () => {

  const styles = useMemo(() => {
    return {
      heading: {
        fontSize: 36,
        fontWeight: 300,
        color: COLORS.GRAY_LIGHT_TEXT,
        marginBottom: 50
      },
      title: {
        color: COLORS.PRIMARY,
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 15
      },
      content: {
        fontSize: 24,
        lineHeight: '33px',
        fontWeight: 300,
        marginTop: 45
      },
      enjoy: {
        fontSize: 57,
        fontWeight: 600,
        marginTop: 53
      }
    } as const;
  }, []);

  const transactionCompleted = () => {
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Spikes Studio | About Us</title>
        <link rel="canonical" href="https://spikes.studio/about" />
        <script type="application/ld+json">
          {JSON.stringify(STRUCTURED_DATA_ABOUT_PAGE)}
        </script>
      </Helmet>
      <MainLayout
        header={isBrowser ? <Header title={''} /> : null}
        footer={<AppFooter shadow={false} />}
        marginTop='26px'
      >
        <Content className="about-page">
          <Title style={styles.heading}>About Us</Title>

          <div className="about-section">
            <Typography style={styles.title}>Our Story</Typography>
            <Image src={TitleAsset} preview={false} />
            <Paragraph style={styles.content}>
              Welcome to Spikes.Studio – where artificial intelligence meets content creation. We provide content creators with an innovative solution that combines advanced AI technology and intuitive editing tools to capture the best moments from their videos.
            </Paragraph>
            <Paragraph style={styles.content}>
              Whether it&apos;s live-streaming sessions or YouTube videos, our AI is designed to help you save time and energy by identifying and retrieving the most memorable and engaging parts of your videos. You can even upload your own video content, and we&apos;ll help transform them into exciting highlights that will engage your audience like never before.

            </Paragraph>
          </div>

          <div className="about-section">
            <Typography style={styles.title}>How to Monetize</Typography>
            <Image src={TitleAsset} preview={false} />
            <Paragraph style={styles.content}>
              Our mission at Spikes.studio is to simplify and enhance the life of content creators. We understand the immense time and effort that goes into creating content, and we are committed to providing a tool that reduces the burden and maximizes your impact on social media.

            </Paragraph>

            <Paragraph style={styles.content}>
              At the heart of Spikes.studio is our powerful built-in editor, with automatic captioning capabilities and flexible formatting options. With a user-friendly interface and a comprehensive suite of features, you can take control of your content and customize it to fit your unique style and brand image.
            </Paragraph>

            <Paragraph style={styles.content}>
              Your journey to success is our journey too. So why wait?
              <br />
              Join us at Spikes.Studio and start creating, sharing, and succeeding today.
            </Paragraph>
          </div>

          <Typography style={styles.enjoy}>Enjoy!</Typography>

        </Content>
        <FooterResponsive />
      </MainLayout>
    </>
  );
};

export default AboutPage;
