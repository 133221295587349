import React, { memo, useEffect, useRef } from 'react';
import { Input, Spin } from 'antd';
import { useFocus } from '../Editor.page';
const { TextArea } = Input;

export const AIGeneratorTextArea = ({ handleAiInputChange, placeholder }: { handleAiInputChange: (value: any) => void, placeholder?: string }) => {
  const [inputRef, setInputFocus] = useFocus();

  const handleOnClick = (e: any) => {
    e.preventDefault();
    inputRef.current.blur();
    setInputFocus();
  }
  return (
    <TextArea
      onClick={handleOnClick}
      ref={inputRef}
      key={'b-roll-input'}
      style={{ resize: 'none' }}
      className='ai-generator-textarea'
      showCount
      maxLength={250}
      onChange={(e) => handleAiInputChange(e.target.value)}
      placeholder={placeholder || "Insert a prompt to generate an image from AI"}
    />
  )
}

export const ImageSearchInput = memo(({ value, onChange }: { value: any, onChange: (val: any) => void }) => {
  const [inputRef, setInputFocus] = useFocus();

  const handleOnClick = (e: any) => {
    e.preventDefault();
    inputRef.current.blur();
    setInputFocus();
  }

  return (
    <input
      ref={inputRef}
      key='b-roll-image-input'
      type="text"
      autoFocus
      onClick={handleOnClick}
      onMouseDown={(e) => e.preventDefault()}
      placeholder="Search"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="search-input"
    />
  )
})

ImageSearchInput.displayName = 'ImageSearchInput';
