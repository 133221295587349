import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Tooltip, Select, Input, Image, Space, Button, Typography } from 'antd';
import './SubtitlesWord.less';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TwitterPicker } from 'react-color';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SubtitleWord } from '../../../../data/intefaces/stream.interface';
import { COLORS } from '../../../../themes/colors';
import CloseIcon from '../../../../assets/white-close.svg';
import YellowLetterIcon from '../../../../assets/color-letter.svg';
import GreenLetterIcon from '../../../../assets/green-letter.svg';
import ColorPickerIcon from '../../../../assets/color-picker.svg';
import { NO_SUBTITLES_MESSAGE, editorSubtitleColors, emojiCategories } from '../../../../constants/content-constants';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import EmojiPicker, { EmojiStyle, Theme } from 'emoji-picker-react';
import { useTraceUpdate } from '../../../../utils';
import { ReactComponent as IconEdit } from '../../../../assets/edit_icon.svg';
import { useFocus } from '../../Editor.page';

const { Option } = Select;

// Available font size options
export const fontSizeOptions = [
  { value: 'Small', label: 'S' },
  { value: 'Medium', label: 'M' },
  { value: 'Big', label: 'L' },
  { value: 'Huge', label: 'XL' },
  // { value: 'Huge', label: 'Huge' },
];

interface SubtitlesWordProps {
  isHiddenWord?: boolean;
  contentEditableMode?: boolean;
  isSelectedWord?: boolean;
}

// SubtitlesWord component
const SubtitlesWord: React.FC<SubtitleWord & SubtitlesWordProps> = React.memo(({
  fontSize,
  start,
  end,
  word,
  color,
  background,
  onChange,
  fontFamily,
  textShadow,
  fontWeight,
  textTransform,
  alignItems,
  isHighlighted,
  lineHeight,
  isPreview = false,
  isInTimeRange = false,
  timeRangeComponent,
  addMarginComponent,
  trimValues,
  setTrimValues,
  emoji,
  emojiEnabled,
  wordIndex = 0,
  sentenceIndex = 0,
  animation = '',
  isHiddenWord,
  // contentEditableMode,
  isSelectedWord
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [selectedFontSize, setSelectedFontSize] = useState('Big');
  const [chosenColor, setChosenColor] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [showStylePicker, setShowStylePicker] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);

  const colorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenColorPicker(false) });

  // Handler for word change events
  const handleWordChange = useCallback((wrd: any) => {
    if (spanRef.current && onChange) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      // const newText = spanRef.current.innerText;
      // onChange(newText, 'word');
      if (word !== NO_SUBTITLES_MESSAGE) {
        if (wrd?.target.textContent !== word) {
          onChange(wrd?.target.textContent, 'word', sentenceIndex, wordIndex);
        }
      }
    }
  }, []);

  const handleDeleteWord = useCallback(() => {
    onChange && onChange('', 'word', sentenceIndex, wordIndex);
  }, [start, end]);

  // Handler for style change events
  const handleStyleChange = useCallback((type: keyof SubtitleWord, value: string) => {
    if (onChange) {
      onChange(value, type, sentenceIndex, wordIndex);
    }
    if (type === 'fontSize') {
      setSelectedFontSize(value);
    }
  }, [start, end]);

  const handleOpenColorPicker = useCallback(() => {
    setOpenColorPicker(val => !val);
  }, []);

  const handleChooseColor = useCallback((color: any) => {
    setChosenColor(color);
    handleStyleChange('color', color.hex);
  }, [start, end]);

  const handleShowEmojiPicker = useCallback(() => {
    setOpenEmojiPicker(val => !val);
  }, []);

  const handleChooseEmoji = useCallback((emoji: any) => {
    handleStyleChange('emoji', emoji.emoji);
    setOpenEmojiPicker(false);
  }, []);

  const handleToggleEditInput = useCallback(() => {
    setShowEditInput(prev => !prev);
  }, []);

  // Style object for the subtitle word
  const style = {
    fontSize: fontSize,
    lineHeight: lineHeight,
    fontFamily: fontFamily,
    textShadow: textShadow,
    '--text-shadow-color': background,
    textTransform: textTransform,
    color: color,
    fontWeight: fontWeight,
    inset: alignItems,
    padding: isPreview ? '5px' : 0,
    marginTop: isPreview ? 0 : 0,
    opacity: ((!isPreview && !isInTimeRange) || (!isPreview && isHiddenWord)) ? '0.3' : 1
  };

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      inputContainer: {
        display: 'flex',
        width: '100%',
        padding: '10px 15px',
        background: COLORS.GRAY_CONTROLS,
        alignItems: 'center',
        gap: 10
      },
      filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
      },
      fontSizeItemsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
      },
      filterWrapper: {
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.FILTERS_BACKGROUND_GRAY,
        borderRight: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`
      },
      colorsItem: {
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.FILTERS_BACKGROUND_GRAY,
        width: '100%'
      },
      input: {
        background: COLORS.GRAY_CONTROLS,
      },
      clearIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '7px',
        marginRight: '5px',
        cursor: 'pointer',
      },
      timeRange: {
        height: 40,
        padding: 5,
        backgroundColor: COLORS.CONTROL_BUTTON_BACKGROUND,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px'
      },
      controlTopButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: 9,
        position: 'relative',
        marginLeft: 'auto'
      },
      setButton: {
        height: '34px !important',
        maxHeight: '34px !important',
        minHeight: '34px !important',
        fontSize: 12,
        fontWeight: 400
      },
      downloadQualityBox: {
        height: '34px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY,
        cursor: 'pointer',
        position: 'relative',
        padding: '4px 6px'
      },
      downloadQualityText: {
        fontSize: '12px',
        color: COLORS.BACKGROUND_DARK_GRAY,
        fontWeigth: 400,
        whiteSpace: 'nowrap'
      },
      downloadQualityBoxDisabled: {
        backgroundColor: COLORS.GRAY_BACKGROUND,
      },
      disabledClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
      downloadQualityTextDisabled: {
        color: COLORS.GRAY_DISABLED,
      },
      timeRangeAndEdit: {
        display: 'flex',
        alignItems: 'center',
        gap: '7px'
      }
    } as const;
  }, []);

  const disabledSetStart = trimValues && trimValues[1] <= start / 1000;
  const disabledSetEnd = trimValues && end / 1000 <= trimValues[0];

  const handleSetStart = useCallback((trimValues) => {
    const wordStart = Number((start / 1000).toFixed(2));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTrimValues && setTrimValues([wordStart, trimValues[1]]);
  }, []);

  const handleSetEnd = useCallback((trimValues) => {
    const wordEnd = Number((end / 1000).toFixed(2));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTrimValues && setTrimValues([trimValues[0], wordEnd]);
  }, []);

  const handleWordKeyDown = (e: any) => {
    if (e?.key === 'Enter') {
      e.preventDefault();
      handleWordChange(e);
      spanRef?.current?.blur();
    }
  };

  const [inputRef, setInputFocus] = useFocus();

  const handleOnClick = (e: any) => {
    e.preventDefault();
    inputRef.current.blur();
    setInputFocus();
  }

  // const showMarginButton = (trimValues && trimValues[0] === Number((start / 1000).toFixed(2))) || (trimValues && trimValues[1] === Number((end / 1000).toFixed(2)));

  const stylePicker = (
    <div style={styles.container}>
      {showEditInput &&
        <div className='style-picker__edit-input'>
          <input ref={inputRef} onClick={handleOnClick} value={word} onChange={e => onChange && onChange(e?.target.value, 'word', sentenceIndex, wordIndex)} />
        </div>
      }
      <div style={styles.inputContainer}>
        <div style={styles.timeRangeAndEdit}>
          {timeRangeComponent &&
            <div style={styles.timeRange}>
              {timeRangeComponent}
            </div>
          }
          <Tooltip overlayClassName='tooltip' title='Edit Word'>
            <div
              onClick={handleToggleEditInput}
              className='color-item'
              style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY, marginRight: 0, fontSize: 24 }}
            >
              <IconEdit />
            </div>
          </Tooltip>
        </div>

        <div style={styles.controlTopButtons}>
          <div
            onClick={disabledSetStart ? () => null : () => handleSetStart(trimValues)}
            className={disabledSetStart ? 'secondary-hover' : 'primary-hover'}
            style={{
              ...styles.downloadQualityBox,
              ...(disabledSetStart ?
                styles.downloadQualityBoxDisabled : {}),
              ...(disabledSetStart ?
                styles.disabledClick : {}),
            }}
          >
            <Typography
              style={{
                ...styles.downloadQualityText,
                ...(disabledSetStart ? styles.downloadQualityTextDisabled : {}),
              }}
            >
              Set Start
            </Typography>
          </div>
          <div
            onClick={disabledSetEnd ? () => null : () => handleSetEnd(trimValues)}
            className={disabledSetEnd ? 'secondary-hover' : 'primary-hover'}
            style={{
              ...styles.downloadQualityBox,
              ...(disabledSetEnd ?
                styles.downloadQualityBoxDisabled : {}),
              ...(disabledSetEnd ?
                styles.disabledClick : {}),
            }}
          >
            <Typography
              style={{
                ...styles.downloadQualityText,
                ...(disabledSetEnd ? styles.downloadQualityTextDisabled : {}),
              }}
            >
              Set End
            </Typography>
          </div>
          {emojiEnabled &&
            <>
              <div
                onClick={handleShowEmojiPicker}
                className='color-item'
                style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY, marginRight: 0, fontSize: 24 }}
              >
                {emoji === 'none' ? <PlusOutlined /> : emoji}
              </div>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {openEmojiPicker && <EmojiPicker categories={emojiCategories} onEmojiClick={handleChooseEmoji} theme={Theme.DARK} emojiStyle={EmojiStyle.TWITTER} />}
            </>
          }

          {/* {showMarginButton &&
            <>
              {addMarginComponent && addMarginComponent()}
              <Tooltip title="This number represents the amount of time (in milliseconds) that will be added to or deducted from the word's timestamp when you set new start or end clip values. You have the flexibility to change this value or manually control the trimming bar as needed.">
                <div>
                  <InfoCircleOutlined size={12} style={{ cursor: 'pointer' }} />
                </div>
              </Tooltip>
            </>
          } */}

        </div>
      </div>
      <div style={styles.filtersContainer}>
        <div style={styles.filterWrapper}>
          <div style={{ ...styles.fontSizeItemsContainer, backgroundColor: COLORS.SELECTOR_GRAY, padding: '4px 5px' }}>
            {fontSizeOptions.map((item) => (
              <div
                onClick={() => handleStyleChange('fontSize', item.value)}
                key={item.value}
                className={`font-size-item ${item.value === selectedFontSize ? 'font-size-item-selected' : ''}`}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div style={styles.colorsItem}>
          <div className="color-items" style={styles.fontSizeItemsContainer}>
            <div
              onClick={() => handleStyleChange('color', '#FFFD03')}
              className='color-item'
            >
              <Image
                src={YellowLetterIcon}
                preview={false}
                className="cursor-pointer"
              />
            </div>
            <div
              onClick={() => handleStyleChange('color', '#04F827')}
              className='color-item'
            >
              <Image
                src={GreenLetterIcon}
                preview={false}
                className="cursor-pointer"
              />
            </div>
            <div ref={colorPickerRef}>
              <div
                onClick={handleOpenColorPicker}
                className='color-item'
                style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY }}
              >
                <Image
                  src={ColorPickerIcon}
                  preview={false}
                  className="cursor-pointer"
                />
              </div>
              {openColorPicker && <TwitterPicker color={chosenColor} onChangeComplete={handleChooseColor} colors={editorSubtitleColors} />}
            </div>
            <div
              onClick={handleDeleteWord}
              className='color-item'
              style={{ backgroundColor: COLORS.BACKGROUND_DARK_GRAY, marginRight: 0 }}
            >
              <DeleteOutlined style={{ fontSize: 26 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Tooltip
        title={stylePicker}
        trigger="click"
        placement="top"
        mouseEnterDelay={0.3}
        mouseLeaveDelay={0.1}
        overlayClassName="popup-container word-style-picker"
      >
        <span
          style={style}
          className={((isHighlighted && !isPreview) ? 'highlighted subtitle ' : 'subtitle ' + animation) + ` ${isHiddenWord && 'hidden-word'} ${isSelectedWord && 'selected-word'}`}
          // contentEditable={contentEditableMode}
          data-word-id={start}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // onFocus={handleWordChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // onClick={handleWordChange}
          // onClick={() => setShowStylePicker(true)}
          onBlur={handleWordChange}
          // onKeyUp={handleWordChange}
          onKeyDown={handleWordKeyDown}
          ref={spanRef}
          suppressContentEditableWarning
        >
          {word}
        </span>
      </Tooltip>
    </>
  );
});

SubtitlesWord.displayName = 'SubtitlesWord';

export default SubtitlesWord;
