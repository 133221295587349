import { Col, Modal, Row, Space, Checkbox, Button, Typography, Image } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import YTGif from '../../assets/yt-error-tutorial.gif';
import IconClose from '../../assets/modal-close.svg';

type Mode = 'personal' | 'youtube' | 'twitch';

export interface IYoutubeLimitModalProps {
  open: boolean;
  onClose: () => void;
}

export const YoutubeLimitModal = ({ open, onClose }: IYoutubeLimitModalProps) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  return (
    <Modal
      open={open}
      className='error-info-modal'
      footer={null}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={773}
      centered
    >
      <div className="error-info-modal__container">
        <div className="error-info-modal__header">
          <div style={{ fontSize: 18 }} className='error-info-modal__title'>Hi! It looks like your video exceeds our 60-minute limit for YouTube uploads.</div>
          <div className='cursor-pointer' onClick={onClose}>
            <Image src={IconClose} preview={false} width={24} />
          </div>
        </div>

        <div className="error-info-modal__content">
          <div className='error-info-modal__content-top'>
            <div className="error-info-modal__content-description">Download the content directly from YouTube and upload it to Spike.</div>
          </div>

          <div className="error-info-modal__guide">
            <div className="error-info-modal__guide-text">
              <div>
                <div className="error-info-modal__guide-title">1. Log in to YouTube Studio</div>
                <div className="error-info-modal__guide-description">- Go to YouTube Studio.</div>
                <div className="error-info-modal__guide-description">- Log in with your YouTube account.</div>
              </div>
              <div>
                <div className="error-info-modal__guide-title">2. Navigate to Videos/Shorts</div>
                <div className="error-info-modal__guide-description">- In the left sidebar, click on &quot;Content&quot; to see your uploaded videos.</div>
              </div>
              <div>
                <div className="error-info-modal__guide-title">3. Download the Video</div>
                <div className="error-info-modal__guide-description">- Find the video you want to download.</div>
                <div className="error-info-modal__guide-description">- Click on the options (three dots) next to the video.</div>
                <div className="error-info-modal__guide-description">- Select &quot;Download&quot; to save the video to your computer.</div>
              </div>
            </div>
            <div className="error-info-modal__gif">
              <Image src={YTGif} alt='GIF' />
            </div>
          </div>

          <div className="error-info-modal__content-footer">*Please note that you should only use content that is either your own or has been explicitly authorized for use.</div>
        </div>
      </div>
    </Modal>
  );
};

export default YoutubeLimitModal;