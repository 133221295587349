import { useMemo, useState } from 'react';
import Space from 'antd/lib/space';
import { Card, Typography, Image, Button } from 'antd';
import { useNavigate } from 'react-router';
import { COLORS } from '../../../themes/colors';
import { InviteModal } from '../../invite-modal';

import LockedIcon from '../../../assets/icon-locked-spike.svg';
import LockedTwitchIcon from '../../../assets/icon-locked-twitch-clip.svg';

interface ILockedSpikeProps {
  isTwitchCard?: boolean;
}

export const LockedSpike = (props: ILockedSpikeProps) => {
  const { isTwitchCard } = props;

  const navigate = useNavigate();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

  const styles = useMemo(() => {
    return {
      spikeCardContainer: {
        backgroundColor: 'transparent',
        marginTop: 0,
      },
      stepCardBody: {
        borderRadius: 20,
        border: `1px solid ${isTwitchCard ? '#9147FF' : COLORS.PRIMARY}`,
        backgroundColor: isTwitchCard ? 'transparent' : COLORS.HIGHLIGHT_CARD_BACKGROUND,
        padding: 20,
        height: isTwitchCard ? 324 : 315,
      },
      invite: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      },
      inviteText: {
        color: COLORS.WHITE,
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 600,
        width: 240,
        textAlign: 'center',
        lineHeight: '22px',
        marginBottom: -5,
      },
      inviteCountText: {
        fontSize: 22,
        fontWeight: 600,
        color: isTwitchCard ? '#9147FF' : '#67FFCC',
      },
      inviteButton: {
        background: isTwitchCard ? '#9147FF' : COLORS.PRIMARY,
        color: isTwitchCard ? COLORS.WHITE : COLORS.BLACK,
        borderColor: isTwitchCard ? '#9147FF' : COLORS.PRIMARY,
      },
    } as const;
  }, [isTwitchCard]);

  const inviteClicked = () => {
    setShowInviteModal(true);
  };
  
  return (
    <Card bordered={false} bodyStyle={styles.stepCardBody} style={styles.spikeCardContainer}>
      <Space size='middle' direction='vertical' className='w-100' style={styles.invite}>
        <Image src={isTwitchCard ? LockedTwitchIcon : LockedIcon} preview={false} width={180} />
        <Typography style={styles.inviteText}>
          INVITE <span style={styles.inviteCountText}>1</span> FRIEND TO UNLOCK!
        </Typography>
        <Button type='primary' size='middle' onClick={inviteClicked} style={styles.inviteButton}>
          invite
        </Button>
      </Space>

      {showInviteModal &&
        <InviteModal onClose={() => setShowInviteModal(false)} />
      }
    </Card>
  );
};

export default LockedSpike;