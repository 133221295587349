import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { clamp, debounce } from 'lodash';
import { Image, Slider } from 'antd';
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;
import { default as _ReactPlayerClass } from 'react-player/lazy';
import { COLORS } from '../../themes/colors';
import { formatSeconds } from '../../utils/generic';

import IconPlay from '../../assets/icon-player-play.svg';
import IconPause from '../../assets/icon-player-pause.svg';
import IconVolume from '../../assets/icon-volume.svg';
import IconVolumeMuted from '../../assets/icon-volume-muted.svg';
import IconVolumeHigh from '../../assets/icon-volume-high.svg';
import './Player.less';
import { IFeedScene } from '../../data/intefaces/stream.interface';

export const MAX_TRIMMING_RANGE = 300;

export interface IPlayerEditorProps {
  url: string;
  thumbnailUrl?: string;
  light?: boolean;
  startTime?: number;
  endTime?: number;
  height?: string | number;
  width?: string | number;
  showCrop?: boolean;
  disableTrim?: boolean;
  isVisible?: boolean;
  playingVideoScope?: [number, number];
  onPlay?: () => void;
  onPause?: () => void;
  onReady?: () => void;
  onProgress?: (progress: number) => void;
  trimValuesProp?: [number, number];
  enableTimeLine?: boolean;
  setPlayerProgress?: (progress: number) => void;
  setPlayerLoadedSeconds: (loaded: number) => void;
  playerProps?: any;
  seekTo: (value: number) => void;
  playerRef: any;
  allFeedScenes: IFeedScene[];
}
export const PlayerEditor = (props: IPlayerEditorProps, ref: any) => {
  const {
    url,
    thumbnailUrl,
    light = true,
    startTime = 0,
    endTime = 0,
    height = 280,
    showCrop = false,
    disableTrim = false,
    isVisible = true,
    playingVideoScope,
    onPlay,
    onPause,
    onReady,
    onProgress,
    trimValuesProp,
    enableTimeLine = false,
    setPlayerProgress,
    width = 'auto',
    playerProps,
    playerRef,
    seekTo,
    setPlayerLoadedSeconds,
    allFeedScenes
  } = props;

  const {
    ready,
    setIsReady,
    volume,
    setVolume,
    muted,
    setMuted,
    playing,
    setPlaying,
    trimValues,
    setTrimValues,
    duration,
    setDuration,
    progress,
    setProgress,
    loadedSeconds,
    setLoadedSeconds,
    styles,
  } = playerProps;

  useEffect(() => {
    setPlayerProgress && setPlayerProgress(progress);
  }, [progress]);


  const [playedInTrimmingScope, setPlayedInTrimmingScope] = useState<boolean>(false);

  const isNumber = (value: any) => {
    return typeof value === 'number' && !Number.isNaN(value);
  };

  const startPlayingByTrimmingValue = () => {
    if (playingVideoScope) {
      if (isNumber(playingVideoScope[0])) {
        seekTo(playingVideoScope[0]);
      }
    }
  };

  const finishPlayingByTrimmingValue = (playedSeconds: number) => {
    if (playingVideoScope && !playedInTrimmingScope) {
      if (isNumber(playingVideoScope[1])) {
        if (playedSeconds >= playingVideoScope[1]) {
          onPause && onPause();
          setPlaying(false);
          setPlayedInTrimmingScope(true);
        }
      }
    }
  };

  const onDuration = (duration: number) => {
    seekTo(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
    setDuration(Math.floor(duration));
    !showCrop && setTrimValues([0, duration]);
  };

  const getCurrentScene = (playedSeconds: number) => {
    const currentScene = allFeedScenes?.find(feed => feed.start_time_in_event <= playedSeconds && playedSeconds < feed.end_time_in_event);
    return currentScene;
  };

  const onProgressNative = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    blockPlayingBeforeTrimmingValue(state.playedSeconds);
    finishPlayingByTrimmingValue(state.playedSeconds);
    blockPlayingHiddenScenes(state.playedSeconds);

    if (showCrop && state.playedSeconds === 0) {
      trimValuesProp && setProgress(trimValuesProp[0]);
      trimValuesProp && onProgress && onProgress(trimValuesProp[0]);
    } else {
      setProgress(
        parseFloat((Math.round(state.playedSeconds * 4) / 4).toFixed(2)),
      );
      onProgress && onProgress(Math.ceil(state.playedSeconds));
    }

    setLoadedSeconds(Math.ceil(state.loadedSeconds));
    setPlayerLoadedSeconds(Math.ceil(state.loadedSeconds));
    const stopTime = (showCrop ? (trimValuesProp && trimValuesProp[1] || trimValues[1]) : endTime || duration) - 0.4; // Minus 1 second to stop in the right time
    if (stopTime && state.playedSeconds >= stopTime) {
      setPlaying(false);
      onPause && onPause();
      setProgress(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
      seekTo(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
      onProgress && onProgress(0);
    }
  };

  const blockPlayingBeforeTrimmingValue = (playedSeconds: number) => {
    if (!trimValues) return;
    const trimStart = trimValuesProp ? trimValuesProp[0] : trimValues[0];

    if (playedSeconds < trimStart) {
      seekTo(trimStart);
    }
  };

  const blockPlayingHiddenScenes = (playedSeconds: number) => {
    // const currentScene = getCurrentScene(playedSeconds + 0.5); // + 0.5 to avoid hidden scene show up for a few milliseconds on the screen
    const currentScene = getCurrentScene(playedSeconds);
    if (currentScene?.hidden_scene) {
      const nextAvailableScene = allFeedScenes?.find(feed => feed.start_time_in_event > playedSeconds && !feed.hidden_scene);
      if (nextAvailableScene) {
        seekTo(nextAvailableScene.start_time_in_event);
      } else {
        setPlaying(false);
        onPause && onPause();
        setProgress(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
        seekTo(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
        onProgress && onProgress(0);
      }
    }
  };

  const debouncedOnSeek = useCallback(
    debounce((nextValue) => {
      onSeek(nextValue);
    }, 300),
    [trimValues, trimValuesProp],
  );

  const onPlayPause = (play: boolean) => {
    if (playerRef.current && !play && endTime && progress >= endTime) {
      (playerRef.current as _ReactPlayerClass).seekTo(startTime, 'seconds');
    }

    setPlaying(!play);

    if (play) {
      onPause && onPause();
    } else {
      onPlay && onPlay();
    }
  };

  const onSeek = (value: number) => {
    if (showCrop) {
      if (value <= (trimValuesProp && trimValuesProp[0] || trimValues[0])) {
        seekTo((trimValuesProp && trimValuesProp[0] || trimValues[0]));
        return;
      } else if (value >= (trimValuesProp && trimValuesProp[1] || trimValues[1])) {
        seekTo((trimValuesProp && trimValuesProp[1] || trimValues[1]));
        return;
      }
    }
    seekTo(value);
  };

  const handleTrimmingBarChange = (values: [number, number]) => {
    if (values[0] + MAX_TRIMMING_RANGE <= values[1]) {
      seekTo(values[0]);
      setTrimValues([values[0], values[0] + MAX_TRIMMING_RANGE]);
    } else {
      // If only END trimming point is changed, seek to the end trimm of the video
      if (trimValues[1] !== values[1] && trimValues[0] === values[0]) {
        seekTo(values[1]);
      } else {
        seekTo(values[0]);
      }
      setTrimValues(values);
    }
  };

  return (
    <div className="player-wrapper cursor-pointer">
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        light={light && thumbnailUrl}
        playing={isVisible && playing}
        volume={muted ? 0 : volume}
        height={height}
        width={width}
        style={{ display: 'flex', justifyContent: 'center' }}
        pip={true}
        progressInterval={10}
        onStart={startPlayingByTrimmingValue}
        onDuration={onDuration}
        onProgress={onProgressNative}
        onReady={() => {
          if (!ready) {
            setPlaying(light);
            setIsReady(true);
            // onPlay && onPlay();
            onReady && onReady();
          }
        }}
      />
      <div style={styles.spikeVideoOverlay}></div>
      {ready && (
        <div style={styles.controls}>
          <div style={styles.controls}>
            <div
              className="cursor-pointer volume-icon"
              style={{ marginTop: 120 }}
            >
              <div onClick={() => setMuted(!muted || volume === 0)}>
                <div className="volume-container">
                  {volume >= 0.8 && !muted && (
                    <Image src={IconVolumeHigh} width={22} height={22} preview={false} />
                  )}
                  {volume < 0.8 && !muted && (
                    <Image src={IconVolume} width={22} height={22} preview={false} />
                  )}
                  {muted && (
                    <Image src={IconVolumeMuted} width={22} height={22} preview={false} />
                  )}
                </div>
              </div>
              <div
                style={{
                  height: 60,
                  position: 'absolute',
                  right: 3,
                  bottom: 48,
                }}
                className="volume-selector"
              >
                <Slider
                  vertical
                  defaultValue={volume}
                  min={0}
                  max={1}
                  step={0.01}
                  value={muted ? 0 : volume}
                  trackStyle={{ background: COLORS.PRIMARY }}
                  handleStyle={{ border: 0, width: 8, height: 8, marginLeft: -2 }}
                  tooltip={{ open: false }}
                  onChange={(value) => {
                    setVolume(value);
                    setMuted(value === 0);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(PlayerEditor);
