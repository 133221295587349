import './OnboardingPopup.less';
import { ReactComponent as IconRocket } from '../../assets/rocket.svg';
import { ReactComponent as IconClose } from '../../assets/icon_close.svg';
import { ReactComponent as IconCheck } from '../../assets/check_green.svg';
import { ReactComponent as IconCalendar } from '../../assets/calendar.svg';
import UploadGif from '../../assets/upload_gif.gif';
import GenerateClipsGif from '../../assets/generate_clips_gif.gif';
import ChooseTemplateGif from '../../assets/choose_template_gif.gif';
import CheckEditorGif from '../../assets/check_editor_gif.gif';
import ShareDownloadGif from '../../assets/share_download_gif.gif';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../data/userContext';
import { checkIfOnboardingStepCompleted, completeOnboardingStep } from '../../utils';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { OnboardingContext } from '../../data/onboardingContext';
import axios from 'axios';
import { useLocation } from 'react-router';
import { CalendarOutlined, FormatPainterOutlined, GiftOutlined, PicLeftOutlined, RocketOutlined, UploadOutlined } from '@ant-design/icons';
import { DISCORD_LINK } from '../../constants/content-constants';

interface IStep {
  id: string;
  label: string;
  icon?: any;
  gif?: any;
}

const GOOGLE_ONBOARDING_STEPS: IStep[] = [
  {
    id: 'earn_extra',
    label: 'Earn 10 extra minutes!',
    icon: <GiftOutlined />,
  },
  {
    id: 'upload_video',
    label: 'Upload a video',
    icon: <UploadOutlined />,
    gif: UploadGif
  },
  {
    id: 'choose_template',
    label: 'Choose a template',
    icon: <FormatPainterOutlined />,
    gif: ChooseTemplateGif
  },
  {
    id: 'generate_clips',
    label: 'Generate clips!',
    icon: <RocketOutlined />,
    gif: GenerateClipsGif
  },
  {
    id: 'check_editor',
    label: 'Check out the editor',
    icon: <PicLeftOutlined />,
    gif: CheckEditorGif
  },
  {
    id: 'share_download',
    label: 'Share/Download',
    icon: <CalendarOutlined />,
    gif: ShareDownloadGif
  },
];

const TWITCH_ONBOARDING_STEPS: IStep[] = [
  {
    id: 'earn_extra',
    label: 'Earn 10 Minutes and 1 extra Stream!',
    icon: <GiftOutlined />,
  },
  {
    id: 'upload_video',
    label: 'Create a stream or upload a video',
    icon: <UploadOutlined />,
  },
  {
    id: 'check_editor',
    label: 'Check out the editor',
    icon: <PicLeftOutlined />,
    gif: CheckEditorGif
  },
  {
    id: 'share_download',
    label: 'Share/Download',
    icon: <CalendarOutlined />,
    gif: ShareDownloadGif
  },
];


export const OnboardingPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [rerender, setRerender] = useState(1);
  const [showTutorialVideo, setShowTutorialVideo] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState<null | string>(null);

  const location = useLocation();

  const localOnboardingSteps = localStorage.getItem('onboarding_popup_steps');
  const onboardingFullyFinished = localStorage.getItem('onboarding_popup_finished');
  const popupClosedByUser = Boolean(localStorage.getItem('onboarding_popup_closed_by_user'));

  const onboardingStepsAchieved = localOnboardingSteps ? JSON.parse(localOnboardingSteps) : null;
  const userContext = useContext(UserContext);
  const onboardingContext = useContext(OnboardingContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const { width } = useWindowDimensions();
  const isMobile = width < 1120;
  const onboardingComplete = userContext?.user?.onboarding_complete;
  const hideDueToPathname = location.pathname === '/' || location.pathname === '/onboarding';

  const fullStepsList = isGoogleUser ? [...GOOGLE_ONBOARDING_STEPS] : [...TWITCH_ONBOARDING_STEPS];

  useEffect(() => {
    const updateUser = async () => {
      try {
        const response = await axios.get('streamer/profile');
        if (response?.status === 200) {
          userContext?.setUser(response.data);
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };

    if (location.pathname === '/spikes/videos')
      updateUser();
  }, []);


  const formatStepsForLocalStorage = (steps: IStep[]) => {
    const stepsObject: any = {};
    steps.forEach(step => {
      stepsObject[step.id] = false;
    })
    return stepsObject;
  }

  // useEffect(() => {
  //   if (onboardingContext?.rerender > 1) {
  //     setIsOpen(true);
  //   }
  // }, [onboardingContext?.rerender])

  useEffect(() => {
    if (popupClosedByUser) {
      setIsOpen(false);
    }
  }, []);



  useEffect(() => {
    const stepsLeftToBeDone = fullStepsList.length - getAmountOfCompletedSteps();

    if (stepsLeftToBeDone === 1) {
      finishOnboardingAPICall();
      completeOnboardingStep('earn_extra', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1)
    }
  }, [onboardingStepsAchieved, fullStepsList]);

  const finishOnboardingAPICall = async () => {
    if (onboardingComplete) return;

    const response = await axios.put('streamer/onboarding-complete');
    if (response?.data?.id) {
      userContext?.setUser(response?.data);
    }
  }


  useEffect(() => {
    if (!onboardingComplete && !onboardingStepsAchieved) {
      const formattedSteps = formatStepsForLocalStorage(isGoogleUser ? GOOGLE_ONBOARDING_STEPS : TWITCH_ONBOARDING_STEPS);
      localStorage.setItem('onboarding_popup_steps', JSON.stringify(formattedSteps));
    }
  }, []);

  const handleClosePopup = () => {
    setIsOpen(false);
    localStorage.setItem('onboarding_popup_closed_by_user', 'true');
  };

  const handleClickRocketBtn = () => {
    if (isOpen) {
      handleClosePopup();
    } else {
      setIsOpen(true);
    }
  }

  const getAmountOfCompletedSteps = () => {
    if (!localOnboardingSteps) return 0;
    return Object.values(onboardingStepsAchieved).filter((value, i) => value === true && Boolean(fullStepsList.find(el => el.id === Object.keys(onboardingStepsAchieved)[i]))).length;
  }

  let completePercentage = Math.round((getAmountOfCompletedSteps() * 100) / (fullStepsList.length - 1));
  if (completePercentage > 100) completePercentage = 100;

  const handleFullFinishOnboarding = () => {
    localStorage.setItem('onboarding_popup_finished', 'true');
    setRerender(prev => prev + 1);
  }

  const handleHoverItem = (id: string) => {
    // setTimeout(() => {
    setShowTutorialVideo(true);
    setHoveredItemId(id);
    // }, 300);
  }

  const handleMouseLeaveItem = (id: string) => {
    setShowTutorialVideo(false);
    setHoveredItemId(null);
  }

  const allStepsDone = fullStepsList?.length - getAmountOfCompletedSteps() === 0;

  const hoveredItem = fullStepsList.find(item => item.id === hoveredItemId);

  if (!onboardingStepsAchieved || isMobile || onboardingFullyFinished || hideDueToPathname || !userContext?.user || (onboardingComplete && !allStepsDone)) return null;

  return (
    <div className='onboarding-popup'>
      <div className="onboarding-popup__container">
        {showTutorialVideo && hoveredItemId && hoveredItem?.gif && isOpen &&
          <div className='onboarding-popup__video'>
            <img src={hoveredItem?.gif} alt="Tutorial GIF" />
          </div>
        }

        <div className={`onboarding-popup__card ${isOpen && 'open'}`}>
          <div className="onboarding-popup__card-header">
            <IconClose onClick={handleClosePopup} className='close-btn' />
            <span className='title'>Getting started</span>
            <span className='progress-label'>{completePercentage}% complete</span>
            <div className="progress-bar">
              <div style={{ width: `${completePercentage}%` }} className="progress-bar-complete" />
            </div>


            <div className={`progress-item ${checkIfOnboardingStepCompleted(fullStepsList[0].id) && 'complete'}`}>
              {checkIfOnboardingStepCompleted(fullStepsList[0].id) ? <IconCheck className='check' /> : fullStepsList[0]?.icon}
              <span>{fullStepsList[0].label}</span>
            </div>

          </div>
          {allStepsDone ?
            <div className='onboarding-popup__final'>
              <span className='title'>Awesome!</span>
              <div className='description'>
                <span>Have fun using the extra credits 😄</span>
                {/* <span>Join our <a
                  href={DISCORD_LINK}
                  target="_blank"
                  rel="noreferrer">Discord</a> community.</span> */}
              </div>

              <div className='finish-btn' onClick={handleFullFinishOnboarding}>Finish Onboarding</div>
            </div>
            :
            <div className="onboarding-popup__card-content">
              {fullStepsList.map(item => item.id !== 'earn_extra' && (
                <div onMouseEnter={() => handleHoverItem(item.id)} onMouseLeave={() => handleMouseLeaveItem(item.id)} key={item.id} className={`progress-item ${checkIfOnboardingStepCompleted(item.id) && 'complete'}`}>
                  {checkIfOnboardingStepCompleted(item.id) ? <IconCheck className='check' /> : item?.icon}
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          }

        </div>

        <div onClick={handleClickRocketBtn} className="onboarding-popup__button">
          <IconRocket />

          <span>{allStepsDone ? fullStepsList.length - 1 : getAmountOfCompletedSteps()}/{fullStepsList.length - 1}</span>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPopup;