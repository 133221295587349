import { Switch, Typography } from 'antd';
import { useMemo, useState } from 'react';

import { COLORS } from '../../../themes/colors';

export interface ISwitchButtonProps {
  label?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  small?: boolean;
  emoji?: boolean;
}

export const SwitchButton = ({
  label,
  value,
  disabled = false,
  small = false,
  onChange,
  emoji,
  ...props
}: ISwitchButtonProps) => {
  const [isSelected, setIsSelected] = useState<boolean>(value || false);
  if (value != isSelected) {
    setIsSelected(value || false);
  }

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        alignItems: 'center'
      },
      label: {
        marginLeft: '22px',
        fontSize: small ? '16px' : '20px',
        lineHeight: '29px',
        fontWeight: 400
      },
    } as const;
  }, [disabled, isSelected]);

  const onClick = () => {
    setIsSelected(!isSelected);
    onChange && onChange(!isSelected);
  };

  const getClassNames = () => {
    let classes = '';
    if (small) classes += 'small-switch';
    if (emoji) classes += ' emoji-switch';
    return classes;
  };

  return (
    <div className={getClassNames()} style={styles.container}>
      <Switch  onChange={onClick} checked={isSelected} {...props} />
      {label && <Typography style={styles.label}>{label}</Typography>}
    </div>
  );
};

export default SwitchButton;
